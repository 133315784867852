import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import {device} from "../../styles/responsive";
import axios from "axios";
import { token, version } from '../../global/config';

const TechnologieMarqueesData1 = [
    {
        img: require('../../assets/img/clientLogos1/img1.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img2.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img3.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img4.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img5.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img6.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img7.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img8.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img9.png'),
    },
    {
        img: require('../../assets/img/clientLogos1/img10.png'),
    },
]

function Technologies(props) {
    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/about?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <TechnologiesWrapper>
            {dataAxios?.length ? dataAxios?.[4].ClientLogoImagesAll.map((item, k) => {
                
                    return (
                        
                        <Marquee direction={k % 2  === 0 ? 'left' : 'right'} gradient={false} speed={100} key={k}>
                            {dataAxios?.length && item.Images.map((t, key) => {
                                    return (<MarqueeLogo key={key}>
                                        <img src={t.image} width={120} height={120} alt={key}/>
                                    </MarqueeLogo>
                                )
                                })
                            }
                        </Marquee>
                    )
                }) : 

                <Marquee gradient={false} speed={100}>
                    {TechnologieMarqueesData1?.map((t, key) => {
                        return (<MarqueeLogo key={key}>
                                <img src={t.img} width={120} height={120} alt={key}/>
                            </MarqueeLogo>
                        )
                    })} 
                </Marquee>
            }
            {/* <Marquee gradient={false} speed={100}>
                    {dataAxios?.length ? dataAxios?.[4].ClientsLogosImages1.map((t, key) => {
                            return (<MarqueeLogo key={key}>
                                <img src={t.image} width={120} height={120} alt={key}/>
                            </MarqueeLogo>
                        )
                        }) : 
                        TechnologieMarqueesData1?.map((t, key) => {
                            return (<MarqueeLogo key={key}>
                                    <img src={t.img} width={120} height={120} alt={key}/>
                                </MarqueeLogo>
                            )
                        })
                    }
            </Marquee>
            <Marquee direction={'right'} gradient={false}  speed={100}>

                    {dataAxios?.length ? dataAxios?.[4].ClientsLogosImages2.map((t, key) => {
                        return (<MarqueeLogo key={key}>
                            <img src={t.image} width={120} height={120} alt={key}/>
                        </MarqueeLogo>
                        )
                        }) : 
                        TechnologieMarqueesData2?.map((t, key) => {
                            return (<MarqueeLogo key={key}>
                                    <img src={t.img} width={120} height={120}  alt={key}/>
                                </MarqueeLogo>
                            )
                        })
                    }
            </Marquee> */}

        </TechnologiesWrapper>
    );
}


const TechnologiesWrapper = styled.div`

  >div{
    &:not(:last-child){
      margin-bottom: 80px;
      @media ${device.tabletL}{
        margin-bottom: 60px;
      }
      @media ${device.mobile} {
        margin-bottom: 20px;
      }
      }
  }
`;
const MarqueeLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 80px;
  img{
    width: 100%;
    height: 100%;
    max-width: 120px;
    object-fit: cover;
  }
  @media ${device.tabletL}{
    width: 80px;
    height: 80px;
    margin-right: 40px;
  }
  @media ${device.mobile}{
    width: 60px;
    height: 60px;
    margin-right: 30px;
  }

`;

export default Technologies;

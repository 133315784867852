import {createContext, useState} from "react";

const PageContext = createContext({});


export const PageContextProvider = ({children}) =>{

    const [test,setTest] = useState('');

    return(
        <PageContext.Provider value={{test,setTest}}>{children}</PageContext.Provider>
    )
}

export default PageContext

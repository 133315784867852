import styled from "styled-components";
import Typography from "../../styles/Typography";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const ClientsWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  //overflow: hidden;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    padding-bottom: 60px;
  }
`;

export const ClientsTitle = styled(Typography.H1)`
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  text-align: center;

  span, b {
    font-weight: normal;
    color: ${COLORS.YELLOW};
    ${sharedProps}
  }

  @media ${device.tabletL} {
    margin-bottom: 80px;
    font-size: 56px
  }
  @media ${device.tablet} {
    margin-bottom: 0;
    font-size: 40px
  }
`;

export const ClientsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.tablet} {
    display: none;
  }

`;


export const ClientsButtonMob = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  @media ${device.tablet} {
    display: flex;
  }

`;

export const ClientsTestimonials = styled.div`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  gap: 150px 0px;
  position: relative;
  z-index: 3;
  padding-bottom: 200px;
  pointer-events: none;
  @media ${device.tabletM} {
    grid-template-columns: repeat(8, 1fr);
    gap: 150px 44px;
  }
  @media ${device.tablet} {
    margin-top: 55px;
    grid-template-columns: 1fr;
    gap: 20px;
    padding-bottom: 0;
  }
`;


export const ClientsImage = styled.div`
  margin-bottom: 25px;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.tabletM} {
    width: 150px;
    height: 150px;
    margin-bottom: 16px;
  }
`;

export const ClientsName = styled(Typography.H6)`
  font-weight: 500;
  ${sharedProps};
  color: ${COLORS.SECONDARYBLACK};
  margin-bottom: 25px;
  @media ${device.tabletM} {
    font-size: 28px;
    margin-bottom: 16px;
  }
`;

export const ClientsText = styled(Typography.TextXL)`
  font-weight: 400;
  ${sharedProps};
  color: ${COLORS.SECONDARYBLACK};
  text-align: center;
  @media ${device.tabletM} {
    font-size: 16px;
  }
`;


export const ClientsItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  //padding: 40px 30px;
  ${sharedProps};
  border-radius: 16px;
  text-decoration: none;
  position: relative;
  pointer-events: initial;

  &:nth-child(7n-6) {
    grid-column: 1/5;
    top: 75%;
    background: ${COLORS.RED};

    ${ClientsName} {
      color: ${COLORS.WHITE};
    }

    ${ClientsText} {
      color: ${COLORS.WHITE};
    }

    @media ${device.tabletM} {
      grid-column: 1/5;
    }

  }

  &:nth-child(7n-5) {
    top: 50%;
    grid-column: 10/14;
    background: ${COLORS.GREEN};
    @media ${device.tabletM} {
      grid-column: 5/9;
    }

  }

  &:nth-child(7n-4) {
    top: 25%;
    grid-column: 2/6;
    background: ${COLORS.BLUE};
    @media ${device.tabletM} {
      grid-column: 1/5;
    }

  }

  &:nth-child(7n-3) {
    top: 75%;
    background: ${COLORS.YELLOW};
    grid-column: 9/13;
    @media ${device.tabletM} {
      grid-column: 5/9;
    }

  }

  &:nth-child(7n-2) {
    top: 50%;
    grid-column: 1/5;
    background: ${COLORS.ORANGE};
    @media ${device.tabletM} {
      grid-column: 1/5;
    }

  }

  &:nth-child(7n-1) {
    grid-column: 9/13;
    top: 100%;

    background: ${COLORS.PURPLE};

    ${ClientsName} {
      color: ${COLORS.WHITE};
    }

    ${ClientsText} {
      color: ${COLORS.WHITE};
    }

    @media ${device.tabletM} {
      grid-column: 5/9;
    }


  }

  &:nth-child(7n) {
    top: 75%;
    background: ${COLORS.YELLOW};
    grid-column: 3/7;
    @media ${device.tabletM} {
      grid-column: 1/5;
    }

  }

  &:nth-child(1) {
    top: unset;
    grid-column: 1/5;
    background: ${COLORS.RED};

    ${ClientsName} {
      color: ${COLORS.WHITE};
    }

    ${ClientsText} {
      color: ${COLORS.WHITE};
    }

    @media ${device.tabletM} {
      grid-column: 1/5;
    }
  }

  @media ${device.tablet} {
    top: unset !important;
    grid-column: unset !important;
  }
  @media ${device.mobile}{
    display: none;
    &:nth-child(-n+5){
      display: flex;
    }
  }

`;

export const ClientsItemLink = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 30px;
  ${sharedProps};
  text-decoration: none;
  position: relative;
  pointer-events: initial;

`


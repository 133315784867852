import styled from "styled-components";
import Typography from "../../styles/Typography";
import {device} from "../../styles/responsive";
import {COLORS} from "../../styles/Theme";

export const HelpWrapper = styled.div`
  padding-top: 200px;
  padding-bottom: 260px;
  @media ${device.laptopSmall} {
    padding-top: 90px;
    padding-bottom: 200px;
  }

  @media ${device.mobile} {
    padding-top: 60px;
    padding-bottom: 160px;
  }
`;

export const HelpImages = styled.div`
  width: 45%;
  display: flex;
  align-items: flex-start;
  @media ${device.laptopSmall} {
    order: 2;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }

  img{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%, 0 63%);
    height: 100%;
    object-fit: cover;
    position: relative;

    @media ${device.laptopSmall} {
      max-width: calc(100%/3 - 20px);
    }
    @media ${device.mobile} {
      max-width: calc(100%/3 - 32px/3);
    }
    &:not(:last-child){
      margin-right: 28px;
      @media ${device.mobile} {
        margin-right: 16px;
      }
    }
    &:nth-child(1){
      top: 60px;
    }
    &:nth-child(2){
      top: 30px;
    }

  }
`;

export const HelpRight = styled.div`
  width: 50%;

  @media ${device.laptopSmall} {
    width: 100%;
    order: 1;
  }
`;

export const HelpInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.laptopSmall} {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 60px;
  }
`;

export const HelpText = styled(Typography.H3)`
  margin-bottom: 60px;
  font-weight: 400;
  @media ${device.laptopSmall} {
    margin-bottom: 30px;
  }

  @media ${device.mobile} {
    font-size: 28px;
    line-height: 42px;
  }
  
`;

export const HelpSubText = styled(Typography.H6)`
  width: 70%;
  color: ${COLORS.BLACK2};

  @media ${device.mobile} {
    width: 100%;
  }
`;


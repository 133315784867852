import React from 'react';
import {BlogItemCategory, BlogItemImage, BlogItemName, BlogItemWrapper} from "./styled";

function BlogItem({category,image,link,name}) {
    return (
        <BlogItemWrapper to={link}>
            <BlogItemImage>
                <img src={image} alt={name}/>
            </BlogItemImage>
            <BlogItemName>{name}</BlogItemName>
            <BlogItemCategory>{category}</BlogItemCategory>
        </BlogItemWrapper>
    );
}

export default BlogItem;

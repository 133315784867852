import React, {useEffect, useRef} from 'react';
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import BlogItem from "../BlogItem/BlogItem";
import {BlogHomeList, BlogHomeOverflow, BlogHomeTitle, BlogHomeWrapper, BlogHomeHeader, BlogHomeHeaderButton} from "./styled";
import {css} from "styled-components";
import {device} from "../../styles/responsive";
import Button from "../../Components/Button/Button";

function BlogHome({BlogItemsData, BlogHeaderData}) {

    const slider = useRef();

    useEffect(() =>{

        let isDown = false;
        let startX;
        let scrollLeft;

        slider.current?.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - slider.current.offsetLeft;
            scrollLeft = slider.current.scrollLeft;
            cancelMomentumTracking();

        });


        slider.current?.addEventListener('mouseleave', () => {
            isDown = false;
        });


        slider.current?.addEventListener('mouseup', () => {
            isDown = false;
            beginMomentumTracking();
        });


        slider.current?.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.current.offsetLeft;
            const walk = (x - startX) * 0.5; //scroll-fast
            var prevScrollLeft = slider.current.scrollLeft;
            slider.current.scrollLeft = scrollLeft - walk;
            velX = slider.current.scrollLeft - prevScrollLeft;
        });

        slider.current?.addEventListener('wheel', (e) => {
            cancelMomentumTracking();
        });
        // Momentum

        var velX = 0;
        var momentumID;
        function beginMomentumTracking(){
            cancelMomentumTracking();
            momentumID = requestAnimationFrame(momentumLoop);
        }
        function cancelMomentumTracking(){
            cancelAnimationFrame(momentumID);
        }
        function momentumLoop(){
            if(slider.current){
                slider.current.scrollLeft += velX;
                velX *= 0.95;
                if (Math.abs(velX) > 0.5){
                    momentumID = requestAnimationFrame(momentumLoop);
                }
            }
        }

        return () => cancelMomentumTracking();

    },[])


    return (
        <BlogHomeWrapper>
            <Container cssUnique={BlogHomeContainer}>
                <FadeInAnimation>
                    <BlogHomeHeader>
                        <BlogHomeTitle>{BlogHeaderData.titleThin} <b> {BlogHeaderData.titleBold}</b> </BlogHomeTitle>
                        <BlogHomeHeaderButton>
                            <Button type={'link'} to={BlogHeaderData?.morePostsLink ? BlogHeaderData.morePostsLink : '/articles'}>
                                      {BlogHeaderData?.morePostsName ? BlogHeaderData.morePostsName : 'More Posts'}
                            </Button>
                        </BlogHomeHeaderButton>
                    </BlogHomeHeader>
                    
                </FadeInAnimation>
            </Container>
            <BlogHomeOverflow ref={slider} className={'drag-cursor'}>
                    <BlogHomeList >
                        {

                        BlogItemsData[0].content?.test ? BlogItemsData.slice(0,8).map((blog, i) => {
                            return (
                                <BlogItem key={i}{...blog.content.test[5]}/>
                            )
                        })
                        :
                        BlogItemsData?.map((blog, i) => {
                            return (
                                <BlogItem key={i}{...blog}/>
                            )
                        })
                    }
                    </BlogHomeList>
                </BlogHomeOverflow>
        </BlogHomeWrapper>

    );
}

const BlogHomeContainer = css`
  margin-left: auto;
  @media ${device.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export default BlogHome;

import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import Marquee from "react-fast-marquee";
import {device} from "../../styles/responsive";
import axios from "axios";
import { token, version } from '../../global/config';

const TechnologieMarqueesData1 = [
    {
        img: require('../../assets/img/marquee1/1.png'),
    },
    {
        img: require('../../assets/img/marquee1/2.png'),
    },
    {
        img: require('../../assets/img/marquee1/3.png'),
    },
    {
        img: require('../../assets/img/marquee1/4.png'),
    },
    {
        img: require('../../assets/img/marquee1/5.png'),
    },
    {
        img: require('../../assets/img/marquee1/6.png'),
    },
    {
        img: require('../../assets/img/marquee1/7.png'),
    },
    {
        img: require('../../assets/img/marquee1/8.png'),
    },
    {
        img: require('../../assets/img/marquee1/9.png'),
    },
    {
        img: require('../../assets/img/marquee1/10.png'),
    },
    {
        img: require('../../assets/img/marquee1/11.png'),
    },
    {
        img: require('../../assets/img/marquee1/12.png'),
    },

]
const TechnologieMarqueesData2 = [
    {
        img: require('../../assets/img/marquee2/1.png'),
    },
    {
        img: require('../../assets/img/marquee2/2.png'),
    },
    {
        img: require('../../assets/img/marquee2/3.png'),
    },
    {
        img: require('../../assets/img/marquee2/4.png'),
    },
    {
        img: require('../../assets/img/marquee2/5.png'),
    },
    {
        img: require('../../assets/img/marquee2/6.png'),
    },
    {
        img: require('../../assets/img/marquee2/7.png'),
    },
    {
        img: require('../../assets/img/marquee2/8.png'),
    },
    {
        img: require('../../assets/img/marquee2/9.png'),
    },
    {
        img: require('../../assets/img/marquee2/10.png'),
    },
    {
        img: require('../../assets/img/marquee2/11.png'),
    },
    {
        img: require('../../assets/img/marquee2/12.png'),
    },
]
const TechnologieMarqueesData3 = [
    {
        img: require('../../assets/img/marquee3/1.png'),
    },
    {
        img: require('../../assets/img/marquee3/2.png'),
    },
    {
        img: require('../../assets/img/marquee3/3.png'),
    },
    {
        img: require('../../assets/img/marquee3/4.png'),
    },
    {
        img: require('../../assets/img/marquee3/5.png'),
    },
    {
        img: require('../../assets/img/marquee3/6.png'),
    },
    {
        img: require('../../assets/img/marquee3/7.png'),
    },
    {
        img: require('../../assets/img/marquee3/8.png'),
    },
    {
        img: require('../../assets/img/marquee3/9.png'),
    },
    {
        img: require('../../assets/img/marquee3/10.png'),
    },
    {
        img: require('../../assets/img/marquee3/11.png'),
    },
    {
        img: require('../../assets/img/marquee3/12.png'),
    },


]

function Technologies(props) {

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/service?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <TechnologiesWrapper>
            <Marquee gradient={false} speed={100}>

                {dataAxios?.length ? dataAxios?.[4].Marquee1.map((t, key) => {
                    return (<MarqueeLogo key={key}>
                        <img src={t.image} width={120} height={120} alt={key}/>
                    </MarqueeLogo>
                    )
                }) : 
                    TechnologieMarqueesData1?.map((t, key) => {
                        return (<MarqueeLogo key={key}>
                                <img src={t.img} width={120} height={120} alt={key}/>
                            </MarqueeLogo>
                        )
                    })
                }
            </Marquee>
            <Marquee direction={'right'} gradient={false}  speed={100}>
                {dataAxios?.length ? dataAxios?.[4].Marquee2.map((t, key) => {
                    return (<MarqueeLogo key={key}>
                        <img src={t.image} width={120} height={120} alt={key}/>
                    </MarqueeLogo>
                    )
                }) : 
                    TechnologieMarqueesData2?.map((t, key) => {
                        return (<MarqueeLogo key={key}>
                                <img src={t.img} width={120} height={120} alt={key}/>
                            </MarqueeLogo>
                        )
                    })
                }
            </Marquee>
            <Marquee gradient={false} speed={100}>
                {dataAxios?.length ? dataAxios?.[4].Marquee3.map((t, key) => {
                    return (<MarqueeLogo key={key}>
                        <img src={t.image} width={120} height={120} alt={key}/>
                    </MarqueeLogo>
                    )
                }) : 
                    TechnologieMarqueesData3?.map((t, key) => {
                        return (<MarqueeLogo key={key}>
                                <img src={t.img} width={120} height={120} alt={key}/>
                            </MarqueeLogo>
                        )
                    })
                }
            </Marquee>

        </TechnologiesWrapper>
    );
}


const TechnologiesWrapper = styled.div`

  >div{
    &:not(:last-child){
      margin-bottom: 80px;
      @media ${device.tabletL}{
        margin-bottom: 60px;
      }
      @media ${device.mobile} {
        margin-bottom: 20px;
      }
      }
    //min-width: unset!important;
  }
`;
const MarqueeLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 120px;
  width: 100%;
  //height: 120px;
  margin-right: 80px;
  img{
    width: 100%;
    height: 100%;
    max-width: 120px;
    object-fit: cover;
  }
  @media ${device.tabletL}{
    width: 80px;
    height: 80px;
    margin-right: 40px;
  }
  @media ${device.mobile}{
    width: 60px;
    height: 60px;
    margin-right: 30px;
  }

`;

export default Technologies;

import gsap from 'gsap';
import Scrollbar from 'smooth-scrollbar';

class Cursor {
    constructor(options = {
        cursor: '.v-cursor',
        dot: '.v-dot',
        dot2: 'v-showreel',
        cursorClose: 'VideoPopup--cursor',
        video: 'v-view',
        links: 'c-link',
        buttons: 'c-btn',
        images: 'drag-cursor',
        imageView: 'image-view',
        red: 'c-red',
        disable: 'cursor-disable',
        menuLink: 'cursor-link'
    }) {
        if (!options.links && options.links !== false)
            options.links = 'c-link'
        if (!options.buttons)
            options.buttons = 'c-btn'
        if (!options.images)
            options.images = 'drag-cursor'
        if (!options.imageView)
            options.imageView = 'image-view'
        if (!options.red)
            options.red = 'c-red'
        if (!options.disable)
            options.disable = 'cursor-disable'
        if (!options.dot2)
            options.dot2 = 'v-showreel'
        if (!options.video)
            options.video = 'v-view'
        if (!options.menuLink)
            options.menuLink = 'cursor-link'
        if (!options.cursorClose)
            options.menuLink = 'VideoPopup--cursor'
        this.options = options
        this.cursor = document.querySelector(`${options.cursor}`)
        this.dot = document.querySelector(`${options.dot}`)
        this.dot2 = document.querySelector(`.${options.dot2}`)
        this.cursorClose = document.querySelector(`${options.cursorClose}`)
        this.cords = {
            x: 0,
            y: 0
        }
        window.addEventListener('mouseover', () => {
            this.dot.style.opacity = '1'

        });

        window.addEventListener('mousemove', (e) => {
            gsap.to(this.dot, {
                duration: .15,
                x: e.x,
                y: e.y,
                ease: 'sine.out',
                overwrite: true,

            })
            gsap.to(this.dot2, {
                duration: .15,
                x: e.x,
                y: e.y,
                ease: 'sine.out',
                overwrite: true,

            })

            if(this.cursorClose){
                gsap.to(this.cursorClose, {
                    duration: .15,
                    x: e.x,
                    y: e.y,
                    ease: 'sine.out',
                    overwrite: true,

                })
            }


            this.cords.x = e.x;
            this.cords.y = e.y;

        })

        if (Scrollbar.getAll()[0]) {
            Scrollbar.getAll()[0].addListener(({offset}) => {
                gsap.to(this.dot, {
                    duration: .15,
                    y: this.cords.y,
                    x: this.cords.x,
                    ease: 'sine.out',
                    overwrite: true,

                })
                gsap.to(this.dot2, {
                    duration: .15,
                    y: this.cords.y,
                    x: this.cords.x,
                    ease: 'sine.out',
                    overwrite: true,

                })

            })
        }


        window.addEventListener('mouseout', () => {
            this.cursor.style.opacity = '0'
            this.dot.style.opacity = '0'
        })

        if (options.links) {
            let arr = document.querySelectorAll(`.${options.links}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeLink()
                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })
        }

        if (options.buttons) {
            let arr = document.querySelectorAll(`.${options.buttons}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeBtn()
                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })
        }

        if (options.images) {
            let arr = document.querySelectorAll(`.${options.images}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeImg()

                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })

        }

        if (options.imageView) {
            let arr = document.querySelectorAll(`.${options.imageView}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeImgView()

                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })

        }

        if (options.red) {
            let arr = document.querySelectorAll(`.${options.red}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeRed()

                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })

        }

        if (options.disable) {
            let arr = document.querySelectorAll(`.${options.disable}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeDisable()
                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })

        }

        if (options.video) {
            let arr = document.querySelectorAll(`.${options.video}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeVideo()
                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })

        }

        if (options.menuLink) {
            let arr = document.querySelectorAll(`.${options.menuLink}`)

            arr.forEach((el) => {
                el.addEventListener('mouseover', () => {
                    this.makeLinks()
                })
                el.addEventListener('mouseout', () => {
                    this.reset()
                })
            })

        }

    }

    makeLink() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.cursor.classList.add('link')
        this.dot.classList.add('link')
    }
    makeLinks() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.cursor.style.opacity = '0'
        this.dot.classList.add('links')
    }

    makeBtn() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.cursor.classList.add('button')
        this.dot.classList.add('button')
    }
    makeVideo() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.dot2.style.opacity = 1
        this.dot.style.opacity = 0
    }

    makeImg() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.cursor.style.opacity = '1'
        this.cursor.classList.add('img')
        this.dot.classList.add('img')
    }

    makeImgView() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.cursor.style.opacity = '1'
        this.cursor.classList.add('view')
        this.dot.classList.add('view')
    }

    makeRed() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.cursor.style.opacity = '0'
        this.dot.classList.add('red')
    }


    makeDisable() {
        if (this.cords.x === 0 || this.cords.y === 0) return
        this.cursor.style.opacity = '0'
        this.dot.classList.add('disable')
    }

    reset() {
        this.cursor.style.opacity = '0'
        this.cursor.classList.remove('link')
        this.dot.classList.remove('link')
        this.cursor.classList.remove('button')
        this.dot.classList.remove('button')
        this.cursor.classList.remove('img')
        this.dot.classList.remove('img')
        this.cursor.classList.remove('view')
        this.dot.classList.remove('view')
        this.dot.classList.remove('red')
        this.dot.classList.remove('disable')
        this.dot2.style.opacity = 0
        this.dot.classList.remove('links')

    }

}


export default Cursor;

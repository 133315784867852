import styled from "styled-components";
import {device} from "../../styles/responsive";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";


export const IntroTitle = styled(Typography.H1)`
  text-align: left;
  white-space: pre-line;
  cursor: default;
  mix-blend-mode: difference;
  color: ${COLORS.WHITE};
  position: relative;
  z-index: 3;
  ${sharedProps};
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: 22px;

  @media ${device.tabletL} {
    white-space: normal;
    width: 100%;
    column-gap: 20px;
  }

  @media ${device.mobile} {
    column-gap: 14px;
  }

  ${props => !props.color}{
    color: ${COLORS.BLACK};
  }

  > p{
    position: relative;
    overflow: hidden;
    display: inline-block;

    span{
      transition: 0.9s;
      transform: translateY(100%);
      display: inline-block;

      &.show {
        animation: fadeInTop .9s ease forwards
      }
    }

    > p{
      display: inline;
    }

  }

  @keyframes fadeInTop{
    0%{
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }


`;

export const IntroTitleMouseMove = styled.div`
  background: ${COLORS.WHITE5};
  width: 100%;

  padding-top: 303px;

  @media ${device.tabletL} {
    padding-top: 290px;
  }

  @media ${device.mobile} {
    padding-top: 229px;
  }

  ${props => !props.isHome} {
    padding-top: 270px;
    padding-bottom: 270px;

    @media ${device.tabletL} {
      padding-top: 290px;
      padding-bottom: 200px;
    }

    @media ${device.mobile} {
      padding-top: 229px;
      padding-bottom: 140px;
    }
  }
`;

export const IntroInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 90vh;
  @media ${device.tabletL} {
    min-height: auto;
  }

  @media ${device.mobile} {
    min-height: ${props => props.isHome ? "600px" : ""};
  }

  ${props => !props.isHome} {
    ${IntroTitleMouseMove} {
      padding-bottom: 120px;
      @media ${device.tabletL} {
        padding-bottom: 100px;
      }
      @media ${device.mobile} {
        padding-bottom: 80px;
      }
    }
  }

  ${props => props.isHome} {
    min-height: auto;
    padding-bottom: 120px;
    @media ${device.mobile}{
      padding-bottom: 80px;
    }
  }

`;


export const IntroContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const IntroText = styled(Typography.TextXXL)`
  white-space: nowrap;
  @media ${device.tabletL} {
    display: none;
  }
`;

export const IntroSlides = styled.div`
  position: fixed;
  background: #D9D9D9;
  width: 300px;
  height: 300px;
  left: 0;
  top: 0;
  border-radius: 30px;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
  margin-left: -150px;
  margin-top: -150px;
  z-index: 2;
  @media ${device.laptopSmall} {
    display: none;
  }
`;

export const IntroSlidesList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .swiper {
    width: 100%;
    height: 100%;
  }
`;

export const IntroSlidesItem = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;


export const COLORS = {
    WHITE: "#fff",
    OFFWHITE: "#FEF3E9",
    WHITE5: "#FFFDFB",
    BLACK: "#000",
    SECONDARYBLACK: "#130C03",
    PURPLE: "#4A2B93",
    PURPLE2: "#6E55A9",
    BLUE: "#4AAAD2",
    BLACK2: "#423D35",
    BLACK3: "#716D68",
    BLACK4: "#A19E9A",
    BLACK5: "#D0CECD",
    ORANGE: "#F9974D",
    YELLOW: "#F7B818",
    GREEN: "#A3CA34",
    GREEN3: "#C8DF85",
    RED: "#F94D4D"
};

export const TRANSITIONS = {
    QUICK: ".1s cubic-bezier(.67,0,.285,1)",
    FAST: ".35s cubic-bezier(.67,0,.285,1)",
    NORMAL: ".5s cubic-bezier(.67,0,.285,1)",
    LONG: "1s cubic-bezier(.67,0,.285,1)",
};


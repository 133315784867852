import {useEffect, useState} from "react";
import styled from "styled-components";
import Container from "../../Components/Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import Typography from "../../styles/Typography";
import {device} from "../../styles/responsive";
import ErrorImg from '../../assets/img/error/error_404.png'
import axios from "axios";
import { token, version } from "../../global/config";
import Button from "../../Components/Button/Button";

const Error = ({props}) => {

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/error?cv=1672904287&token=${token}&version=${version}`).then(res =>{
            const data = res.data.story.content.HomePage;
           
            setDataAxios(data)
        })
    },[])

    return (
        <>
            <ErrorWrapp>
                <Container>
                    <FadeInAnimation>
                        <ErrorPic>
                            <img src={dataAxios?.length ? dataAxios[0].image : ErrorImg} alt={'IMG'}/>
                        </ErrorPic>
                        <ErrorTitle>
                            {dataAxios?.length ? dataAxios[0].title : 'Where is this page you wondering?'}
                        </ErrorTitle>
                        <ErrorText>
                            {dataAxios?.length ? dataAxios[0].text : 'The internet can trick you sometimes. We don’t deal with witches and warlocks though. Just navigate back to your rightful place.'}
                        </ErrorText>
                        <ErrorButton>
                            <Button type={'link'} to={'/'}>
                                {dataAxios?.length ? dataAxios[0].button : 'Back'}
                            </Button>
                        </ErrorButton>
                        
                    </FadeInAnimation>
                </Container>
            </ErrorWrapp>
        </>
    );
}

const ErrorWrapp = styled.div`
    padding: 300px 0 245px 0;
    @media ${device.tabletL} {
        padding: 290px 0 340px 0;
    }

    @media ${device.mobile} {
        padding: 195px 0 65px 0;
    }
`;

const ErrorPic = styled.div`
    width: 100%;
    height: 400px;
    margin-bottom: 80px;
    @media ${device.tabletL} {
        height: 340px;
    }

    @media ${device.mobile} {
        height: 160px;
        margin-bottom: 50px;
    }

    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

const ErrorTitle = styled(Typography.H1)`
    text-align: center;
    margin-bottom: 20px;
    @media ${device.tabletL} {
        font-size: 56px;
        line-height: 70px;
    }

    @media ${device.mobile} {
        font-size: 34px;
        line-height: 50px;
    }
`;

const ErrorText = styled(Typography.TextXXL)`
    max-width: 630px;
    text-align: center;
    margin: 0 auto 40px;
    @media ${device.tabletL} {
        font-size: 20px;
        line-height: 32px;
    }

    @media ${device.mobile} {
        font-size: 18px;
        line-height: 32px;
    }
`;

const ErrorButton = styled.div`
    > a{
        margin: 0 auto;
        @media ${device.mobile} {
            padding: 13px 26px 11px 26px;
            font-size: 16px;
            line-height: 30px;
        }
    }
`;



export default Error


import styled from "styled-components";
import {COLORS, TRANSITIONS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import {NavLink} from "react-router-dom";
import {device} from "../../styles/responsive";

export const HeaderWrapper = styled.header`
  padding: 34px 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  background: ${COLORS.WHITE5};
  ${sharedProps};
  @media ${device.tabletL} {
    padding-right: 60px;
    padding-left: 60px;
  }
  @media ${device.tablet} {
    padding: 32px 40px

  }
  @media ${device.mobile} {
    padding: 32px 20px
  }

`;

export const HeaderInner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLogo = styled(NavLink)`
  max-width: 120px;
  img{
    width: 100%;
  }
  @media ${device.mobile} {
    max-width: 82px;
  }
`;

export const HeaderRight = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${sharedProps};
  >div:first-child{
    opacity: 1;
    transition: ${TRANSITIONS.FAST};
    max-width: 999px;
    @media ${device.tabletL} {
      font-size: 28px;
    }
    @media ${device.mobile} {
      display: none;
    }
  }
  
  ${props => !props.active}{
    >div:first-child{
      pointer-events: none;
      opacity: 0;
      max-width: 0;
      transform: scale(0);
    }
  }
`;

export const HeaderLine = styled.div`
  position: relative;
  height: 1px;
  width: 14px;
  background-color: ${COLORS.BLACK};
  transition: ${TRANSITIONS.FAST};
  ${sharedProps};
  &:not(:last-child) {
    margin-bottom: 6px;
  }
`;

export const HeaderBurger = styled.div`
  position: relative;
  margin-left: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 20px;
  height: 20px;
  @media ${device.mobile} {
    margin-left: 0;
  }
  ${props => !props.active} {
    right: 3px;
    top: 1.5px;
    ${HeaderLine}{
      width: 25px;
      background-color: #130C03;
      &:first-child{
        transform: rotate(45deg);
        top: 1.5px;
        left: 3px;
      }
      &:nth-child(2){
        top: -5px;
        left: 3px;
        transform: rotate(-45deg);
      }

    }  
  }
  `
export const MenuNav = styled.nav`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 1380px){
    padding-left: 80px;
  }
  @media ${device.tabletL} {
    padding-left: 0;
  }

`;

export const MenuNavLink = styled.div`
  font-size: 80px;
  line-height: 1.12;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-decoration: none;
  ${sharedProps};
  color: ${COLORS.BLACK};
  transition: ${TRANSITIONS.NORMAL};
  opacity: 0;
  display: inline-block;
  overflow: hidden;
  position: relative;
  &:not(:last-child){
    margin-bottom: 30px;
    }
  &:hover {
    span {
      &:after, &:before {
        // color: ${COLORS.GREEN};
      }

      &:before {
        top: -105%;
      }

      &:after {
        top: 0;
      }
    }
  }

  span {
    display: block;
    color: transparent;

    &:after, &:before {
      ${sharedProps};
      color: ${COLORS.SECONDARYBLACK};
      display: block;
      position: absolute;
      content: attr(data-text);
      transition: ${TRANSITIONS.FAST};
    }

    &:before {
      top: 0%;
    }

    &:after {
      top: 105%;
    }
  }
  @media ${device.laptopL}{
    font-size: 68px;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    
    }
  
  @media (max-width: 1380px){
      font-size: 60px;
    
  }

  @media ${device.tabletL} {
    font-size: 80px;
    &:not(:last-child){
        margin-bottom: 30px;
    }
  }
  @media ${device.tablet} {
    font-size: 60px;
    &:not(:last-child){
      margin-bottom: 14px;
    }
  }

  @media ${device.mobile} {
    font-size: 40px;
    &:not(:last-child){
      margin-bottom: 14px;
    }
  }

`;

export const MenuWrapper = styled.div`
  padding-top: 170px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  z-index: 5;
  background: ${COLORS.WHITE5};
  transform: translateY(-100%);
  transform-origin: top;
  transition: 1s;
  ${props => !props.active}{
    transform: translateY(0);
  }
  @media ${device.laptopL} {
    padding-top: 140px;
  }
  @media ${device.tabletL} {
    padding-top: 145px;
  }
  @media ${device.tablet}{
    display: flex;
    flex-direction: column;
  }

`;

export const MenuInner = styled.div`
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 80px;
  padding-right: 80px;
  @media ${device.laptopL}{
    padding-left: 140px;
    padding-right: 140px;
    max-width: 1150px
  }
  @media ${device.tabletL} {
    padding-left: 60px;
    padding-right: 60px;
    height: auto;
  }
  @media ${device.tablet} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${device.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const MenuTop = styled.div``;

export const MenuClose = styled.div``;

export const MenuRightWrapper = styled.div``;
export const MenuRight = styled.div`
  display: flex;
  height: 100%;
  width: 46%;
  right: 0px;
  top: 0;
  overflow: hidden;
  @media ${device.laptopL} {
    width: 35%;
    height: 55%;
  }
  @media ${device.tabletL} {
    display: none;
  }

`;

export const MenuRightItem = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  transform: translateY(100%) scale(0);
  transform-origin: bottom;
  ${sharedProps};
  transition: ${TRANSITIONS.LONG};
  ${props => !props.active}{
    transform: translateY(0) scale(1);
  }
  &.slider{
    >img{
      display: none;
    }
  }
  .swiper{
    height: 100%;
  }
`;


export const MenuSocial = styled.div`
  position: absolute;
  left: 80px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${device.tabletL}{
    margin-top: 90px;
    padding-left: 60px;
    position: static;
    left: unset;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media ${device.tablet} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${device.mobile} {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
`;

export const MenuSocialItem = styled(NavLink)`
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -0.02em;
  font-weight: 400;
  text-decoration: none;
  ${sharedProps};
  color: ${COLORS.BLACK};
  &:not(:last-child) {
    margin-bottom: 4px;
    @media ${device.tabletL} {
      margin-bottom: 0;
      margin-right: 30px;
    }
    @media ${device.mobile} {
      margin-right: 0;
      
    }
  
  }
  opacity: 0;
  @media (max-width: 1380px) {
    font-size: 20px;
  }
  @media ${device.tabletL} {
    font-weight: 500;
    font-size: 18px;
  }
`;



export const MenuNavLinkCounter = styled.span``;

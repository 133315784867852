import {
    FooterBottom,
    FooterCenter,
    FooterCol, FooterColorText,
    FooterH6,
    FooterInner, FooterLast,
    FooterLink, FooterLinkA, FooterLinks, FooterLittleText, FooterLogo,
    FooterSocial, FooterTop, FooterTopText,
    FooterWrapper
} from "./styles";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import FooterLogoImg from '../../assets/img/foote.png'
import Button from "../Button/Button";
import {useEffect, useRef, useState} from "react";
import gsap from 'gsap'
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {delayForGsap} from "../../functions/constants";
import {useLocation} from "react-router-dom";
import axios from "axios"
import { token, version } from "../../global/config";

const FooterLinksArr = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Projects',
        link: '/works'
    },
    {
        name: 'About',
        link: '/about'
    },
    {
        name: 'Testimonials',
        link: '/testimonials'
    },
    {
        name: 'Our culture',
        link: '/culture'
    },
    {
        name: 'Services',
        link: '/services'
    },
    {
        name: 'Join us',
        link: '/contacts'
    },
    {
        name: 'Blog',
        link: '/blog'
    },

]

const FooterSocialLinks = [
    {
        name: 'Instagram',
        link: 'https://www.instagram.com/',
        img: require('../../assets/img/social/inst.svg').default
    },
    {
        name: 'Facebook',
        link: 'https://www.facebook.com/',
        img: require('../../assets/img/social/fb.svg').default
    },
    {
        name: 'Dribbble',
        link: 'https://dribbble.com/',
        img: require('../../assets/img/social/dr.svg').default
    },
    {
        name: 'Linkedin',
        link: 'https://www.linkedin.com/',
        img: require('../../assets/img/social/ln.svg').default

    },
    {
        name: 'Behance',
        link: 'https://www.behance.net/',
        img: require('../../assets/img/social/be.svg').default
    },

]


const Footer = ({scrollerContainer, footerData}) => {
    const footerContainer = useRef();
    const footerRef = useRef();
    const {pathname} = useLocation();

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/footer?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[]);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
         footerAnimInit()
        //eslint-disable-next-line
        ScrollTrigger.refresh()
        // eslint-disable-next-line
    }, [pathname])

    const footerAnimInit = () => {
        setTimeout(() => {
            gsap.set(footerContainer.current, {yPercent: -50})

            const uncover = gsap.timeline({paused: true})

            uncover
                .to(footerContainer.current, {yPercent: 0, ease: 'none'})
            ;

            ScrollTrigger.create({
                trigger: scrollerContainer.current,
                start: 'bottom bottom',
                end: '+=35%',
                animation: uncover,
                scrub: true,
            })

        }, delayForGsap)
    }

    

    return (
        <FooterWrapper ref={footerRef}>
            <Container>
                <FadeInAnimation>
                    <FooterInner ref={footerContainer}>
                        <FooterCol>
                            <FooterLogo src={FooterLogoImg} alt="footer-logo"/>
                            <FooterColorText className={'c-red'}>
                                {dataAxios?.length ? dataAxios?.[0].textColorLeftFirst : 'Grow your'}
                                <br/> 
                                {dataAxios?.length ? dataAxios?.[0].textColorLeftSecond : 'ideas!'}
                            </FooterColorText>
                        </FooterCol>
                        <FooterCenter>
                            <FooterLinks>
                                {dataAxios?.length ? dataAxios?.[0].links.content.map((link, i) => {
                                    return <FooterLink className={'cursor-disable'} to={link.attrs.body[0].link} key={i}><span
                                        data-text={link.attrs.body[0].name}>{link.attrs.body[0].name}</span></FooterLink>
                                }) : 
                                FooterLinksArr?.map((link, i) => {
                                    return <FooterLink className={'cursor-disable'} to={link.link} key={i}><span
                                        data-text={link.name}>{link.name}</span></FooterLink>
                                })
                                }
                            </FooterLinks>
                            <FooterSocial className="footer__social">
                                {dataAxios?.length ? dataAxios?.[0].social.content.map((link, i) => {
                                        return <FooterLink className={'cursor-disable'} to={link.attrs.body[0].link} key={i}><span
                                            data-text={link.attrs.body[0].name}>{link.attrs.body[0].name}</span></FooterLink>
                                    }) : 
                                    FooterSocialLinks?.map((social, idx) => {
                                        return <FooterLink className={'cursor-disable'} to={social.link} key={idx}>
                                            <span data-text={social.name}>{social.name}</span>
                                        </FooterLink>
                                    })
                                }
                            </FooterSocial>
                        </FooterCenter>
                        <FooterLast>
                            <FooterTop>
                                <FooterTopText>
                                    
                                    {dataAxios?.length ? dataAxios?.[0].textRightTop : 'Let us know your needs'}
                                </FooterTopText>
                                <Button color={'white'} type={'link'} to={'/contacts'}>         
                                    {dataAxios?.length ? dataAxios?.[0].button : 'Contact'}
                                </Button>
                            </FooterTop>
                            <FooterBottom>
                                <FooterLittleText>
                                    {dataAxios?.length ? dataAxios?.[0].textLittleRight : 'Get in touch at'}
                                </FooterLittleText>
                                <FooterLinkA className="footer__mail" href={dataAxios?.length ? dataAxios?.[0].linkMail : 'support@ugna.design'}>
                                    <FooterH6>
                                        {dataAxios?.length ? dataAxios?.[0].linkMail : 'support@ugna.design'}
                                    </FooterH6>
                                </FooterLinkA>
                            </FooterBottom>
                        </FooterLast>
                    </FooterInner>
                </FadeInAnimation>
            </Container>
        </FooterWrapper>

    )
}

export default Footer

import {useEffect, useRef, useState} from "react";
import Intro from "../../Components/Intro/Intro";
import Form from "../../Components/Form/Form";
import styled from "styled-components";
import Container from "../../Components/Container/Container";
import Contacts1 from '../../assets/img/contacts/1.png'
import Contacts2 from '../../assets/img/contacts/2.png'
import Contacts3 from '../../assets/img/contacts/3.png'
import {device} from "../../styles/responsive";
import axios from "axios";
import { token, version } from "../../global/config";

function Contacts(props) {
    const scrollerContainer = useRef();
    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/contacts?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <>
            <Intro title={dataAxios?.length ? dataAxios?.[0].title : 'Tell us more about your project.'} text={dataAxios?.length ? dataAxios?.[0].label : 'Contact us'} animText={false}/>
            <ContactsWrapper>
                <Container>
                    <ContactsInner>
                        <ContactsLeft>
                            <Form scrollerContainer={scrollerContainer}/>
                        </ContactsLeft>
                        <ContactsRight>
                            <img src={dataAxios?.length ? dataAxios?.[1].image1 : Contacts1} alt="1"/>
                            <img src={dataAxios?.length ? dataAxios?.[1].image2 : Contacts2} alt="2"/>
                            <img src={dataAxios?.length ? dataAxios?.[1].image3 : Contacts3} alt="3"/>
                        </ContactsRight>
                    </ContactsInner>
                </Container>
            </ContactsWrapper>
        </>
    );
}

export const ContactsWrapper = styled.div`
  padding-bottom: 170px;
  @media ${device.laptopSmall} {
    padding-bottom: 150px;
  }
  @media ${device.mobile}{
    margin-top: -20px;
    padding-bottom: 100px;
  }
`;

export const ContactsInner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ContactsLeft = styled.div`
  width: 48%;
  @media ${device.laptopSmall} {
    width: 100%;
  }
`;

export const ContactsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  img{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%, 0 63%);
    position: relative;
    &:not(:last-child){
      margin-right: 28px;
    }
    &:first-child{
      top: 160px;
    }
    &:nth-child(2){
      top: 80px;
    }
  }
  @media ${device.laptopSmall} {
      display: none;    
  }
`;


export default Contacts;

import React, {useRef} from 'react';
import Buttons from "../../styles/Buttons";
import {gsap} from "gsap";

function Button({
                    type, children, to, value, speed = 0.6, tollerance = 1, scale = 4, color , className = 'cursor-disable'
                }) {
    const $root = useRef()
    const $item = useRef()
    const $hover = useRef()
    const rootBound = useRef()
    const itemBound = useRef()
    const diffBound = useRef({x: 0, y: 0})

    const handleMouseEnter = () => {

        gsap.killTweensOf($root.current)
        gsap.set($hover.current, {
            scale: scale,
        });

        rootBound.current = $root.current.getBoundingClientRect()
        itemBound.current = $item.current.getBoundingClientRect()
        diffBound.current.x = (rootBound.current.width * scale - rootBound.current.width) / 2
        diffBound.current.y = (rootBound.current.height * scale - rootBound.current.height) / 2
    }

    const handleMouseLeave = () => {
        gsap.killTweensOf($root.current)
        gsap.to($root.current, {
            x: 0, y: 0, ease: 'elastic.out(1.1, .4)', duration: 1.2
        })
        gsap.to($item.current, {
            x: 0, y: 0, ease: 'elastic.out(1.1, .4)', duration: 1.5
        })

        gsap.set($hover.current, {
            scale: 1
        })
    }

    const handleMouseMove = (e) => {
        const x = e.clientX || e.touches[0].clientX
        const y = e.clientY || e.touches[0].clientY

        const maxX = (rootBound.current.width - itemBound.current.width) / 2 * tollerance
        const maxY = (rootBound.current.height - itemBound.current.height) / 2 * tollerance

        const newX = gsap.utils.mapRange(0, rootBound.current.width * scale, -maxX, maxX, x - rootBound.current.x + diffBound.current.x)

        const newY = gsap.utils.mapRange(0, rootBound.current.height * scale, -maxY, maxY, y - rootBound.current.y + diffBound.current.y)

        gsap.killTweensOf($root.current)
        gsap.to($root.current, {
            x: newX, y: newY, ease: 'power3.out', duration: speed
        })
        gsap.to($item.current, {
            x: newX / 2, y: newY / 2, ease: 'power3.out', duration: speed
        })
    }

    if (type === 'link') {
        return (<Buttons.Medium
            className={className}
            color={color}
            ref={$root}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
            onTouchStart={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchEnd={handleMouseLeave}
            to={to}>
            <span ref={$item}>{children}</span>
            <span ref={$hover} className="magnetic-button--hover"/>
            <span className={'ripple'}>
                <span className="ripple-inner"></span>
            </span>
        </Buttons.Medium>)
    }
    if (type === 'nolink') {
        return (<Buttons.MediumNL
            ref={$root}
            className={className}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
            onTouchStart={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchEnd={handleMouseLeave}
            to={to}>
            <span ref={$item}>{children}</span>
            <span ref={$hover} className="magnetic-button--hover"/>
            <span className={'ripple'}>
                <span className="ripple-inner"></span>
            </span>

        </Buttons.MediumNL>)
    }

    if (type === 'submit') {
        return (<Buttons.MediumButtonSubmit
            className={className}
            type={type} value={value}
            ref={$root} onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove} onTouchMove={handleMouseMove}
            onTouchStart={handleMouseEnter} onMouseLeave={handleMouseLeave}
            onTouchEnd={handleMouseLeave} to={to}>
            <span ref={$item}>{children}</span>
            <span ref={$hover} className="magnetic-button--hover"/>
            <span className={'ripple'}>
                <span className="ripple-inner"></span>
            </span>

        </Buttons.MediumButtonSubmit>)
    }

}

export default Button;

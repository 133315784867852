import styled from "styled-components";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import Typography from "../../styles/Typography";
import {device} from "../../styles/responsive";


export const PreloaderCenter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 15px;
  background: ${COLORS.WHITE5};
  ${sharedProps};
  transition: .5s;
  height: 592px;
  @media ${device.laptopNormal} {
    display: none;
  }
  &:before , &::after{
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: ${COLORS.WHITE5};
    border-radius: 15px;
  }
  
  &:before{
    opacity: .6;
    width: 103%;
    height: 85%;
    z-index: -1;
  }
  
  &:after{
    opacity: .3;
    width: 106%;
    height: 75%;
    z-index: -1;
  }
`;

export const PreloaderLogo = styled.div`
  margin-bottom: 95px;
  max-width: 120px;
  width: 100%;
  margin-top: 78px;
  margin-left: 80px;
  transition: .5s;
  svg {
    width: 100%;
  }
`;

export const PreloaderTitle = styled(Typography.H1)`
  ${sharedProps};
  max-width: 1560px;
  width: 100%;
  word-break: break-word;
  margin-bottom: 80px;
  color: ${COLORS.BLACK};
  transition: .5s;
  line-height: 0;

`;

export const PreloaderBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: .15s;
`;

export const PreloaderPercentText = styled(Typography.TextXL)`
  margin-right: 10px;
  ${sharedProps};
  color: ${COLORS.BLACK3};
  letter-spacing: -0.02em;
  position: relative;
  top: 5px;

`;

export const PreloaderPercent = styled(Typography.H3)`
  font-weight: 500;
  ${sharedProps};
  color: ${COLORS.BLACK};
`;

export const PreloaderWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: ${COLORS.GREEN};
  ${sharedProps};
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 1s , visibility 1s , padding .5s;
  opacity: 1;
  visibility: visible;
  padding-left: 80px;
  padding-right: 80px;
  ${props => !props.closed}{
    padding-left: 0px;
    padding-right:0px;
    ${PreloaderCenter}{
      max-width: 100%;
      height: 100%;
      padding-bottom: 10vh;
    }
    ${PreloaderTitle}{
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      padding: 114px 80px 270px 80px;
      padding-left: 0;
      padding-right: 0;
    }
    ${PreloaderBottom}{
      opacity: 0;
    }
    ${PreloaderLogo}{
      margin-top: 36px;
    }
  }
  ${props => !props.closePreloaderAnim}{
    opacity: 0;
    visibility: hidden;
  }

  @media ${device.laptopNormal}{
    padding-right: 0;
    padding-left: 0;
  }
`;

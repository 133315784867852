import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import {
    IntroContent,
    IntroInner,
    IntroSlides,
    IntroSlidesItem,
    IntroSlidesList,
    IntroText,
    IntroTitle,
    IntroTitleMouseMove
} from "./styles";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import gsap, {Expo} from "gsap";
import {Autoplay, EffectFade} from "swiper";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import Scrollbar from "smooth-scrollbar";

function Intro({title, slider, text, isHome, animText = true, closePreloaderAnim}) {


    useEffect(()=>{
        document.querySelectorAll('.fade-below').forEach(function(elem, index){

            elem.querySelectorAll('span').forEach(function(e, index){
                e.classList.add('show');
                e.style.animationDelay = 0.5 + index*0.15 + 's';
            })
        })
    },[closePreloaderAnim])


    const introSlidesData = [
        {
            image: require('../../assets/img/blog/1.png')
        },
        {
            image: require('../../assets/img/blog/2.png')
        },
        {
            image: require('../../assets/img/blog/3.png')
        },
        {
            image: require('../../assets/img/blog/4.png')
        },
    ]

    const jellyRef = useRef(null);
    const titleMouseMove = useRef(null);

    function useTicker(callback, paused) {
        useLayoutEffect(() => {
            if (!paused && callback) {
                gsap.ticker.add(callback);
            }
            return () => {
                gsap.ticker.remove(callback);
            };
        }, [callback, paused]);
    }

    const EMPTY = {};

    function useInstance(value = {}) {
        const ref = useRef(EMPTY);
        if (ref.current === EMPTY) {
            ref.current = typeof value === "function" ? value() : value;
        }
        return ref.current;
    }

// Function for Mouse Move Scale Change
    function getScale(diffX, diffY) {
        const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
        return Math.min(distance / 735, 0.35);
    }

    const pos = useInstance(() => ({x: 0, y: 0}));
    const vel = useInstance(() => ({x: 0, y: 0}));
    const set = useInstance();

    // Set GSAP quick setter Values on useLayoutEffect Update
    useLayoutEffect(() => {
        set.x = gsap.quickSetter(jellyRef.current, "x", "px");
        set.y = gsap.quickSetter(jellyRef.current, "y", "px");
        set.sx = gsap.quickSetter(jellyRef.current, "scaleX");
        set.sy = gsap.quickSetter(jellyRef.current, "scaleY");

        //eslint-disable-next-line
    }, []);

    // Start Animation loop
    const loop = useCallback(() => {
        // Calculate angle and scale based on velocity
        var scale = getScale(vel.x, vel.y); // Blob Squeeze Amount

        set.x(pos.x);
        set.y(pos.y);
        set.sx(1 + scale);
        set.sy(1 - scale);

        //eslint-disable-next-line
    }, []);

    // Run on Mouse Move
    useLayoutEffect(() => {
        // Caluclate Everything Function
        const setFromEvent = (e) => {
            // Mouse X and Y

            let yScroll = Scrollbar.getAll()[0] ? Scrollbar.getAll()[0].offset.y : 0 ;

            const x = e.clientX;
            const y = e.clientY + yScroll



            // Animate Position and calculate Velocity with GSAP
            gsap.to(pos, {
                x: x,
                y: y,
                duration: 1.25,
                ease: Expo.easeOut,
                onUpdate: () => {
                    vel.x = x - pos.x;
                    vel.y = y - pos.y;
                }
            });

            if(jellyRef.current){
                gsap.to(jellyRef.current, {
                    opacity: 1,
                    // filter: 'invert(1)'
                });

            }

            loop();
        };

        const exitFromEvent = (e) => {
            if(jellyRef.current) {
                gsap.to(jellyRef.current, {
                    opacity: 0,
                });
            }

        };


        titleMouseMove.current.addEventListener("pointermove", setFromEvent);
        titleMouseMove.current.addEventListener("mousemove", setFromEvent);


        titleMouseMove.current.addEventListener("mouseleave", exitFromEvent);

        return () => {
            titleMouseMove.current.removeEventListener("pointermove", setFromEvent);
            titleMouseMove.current.removeEventListener("mousemove", setFromEvent);
            //eslint-disable-next-line
            titleMouseMove.current.removeEventListener("mouseleave", exitFromEvent);
        };

        //eslint-disable-next-line
    }, []);

    useTicker(loop);


    return (
        <IntroInner >
            <Container>
                <IntroContent>
                    <IntroTitleMouseMove isHome={isHome}>
                        <IntroTitle ref={titleMouseMove}  className={'cursor-disable fade-below'}>
                            {animText ? title.split(" ").map((item, index) => {
                                return <p key={index}><span>{item}</span> </p> ;
                            }) : title }
                        </IntroTitle>
                    </IntroTitleMouseMove>

                    {text && <FadeInAnimation delay={0.2}>
                        <IntroText>{text}</IntroText>
                    </FadeInAnimation>}
                </IntroContent>
            </Container>
            {isHome && <IntroSlides ref={jellyRef}>
                <IntroSlidesList>
                    <Swiper
                        slidesPerView={1}
                        loop={true}
                        speed={0}
                        autoplay={{
                            delay: 1000,
                        }}
                        modules={[Autoplay, EffectFade]}
                        effect={'fade'}

                    >

                            {slider ? slider.slider.content.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <IntroSlidesItem>
                                            <img src={item.attrs.body[0].image} alt={index}/>
                                        </IntroSlidesItem>
                                    </SwiperSlide>
                                )
                            }) : introSlidesData.map((data, key) => {
                                return (
                                    <SwiperSlide key={key}>
                                        <IntroSlidesItem>
                                            <img src={data.image} alt={key}/>
                                        </IntroSlidesItem>
                                    </SwiperSlide>
                                )
                            }) }
                        {/* {introSlidesData?.map((data, key) => {
                            return (
                                <SwiperSlide key={key}>
                                    <IntroSlidesItem>
                                        <img src={data.image} alt={key}/>
                                    </IntroSlidesItem>
                                </SwiperSlide>
                            )
                        })} */}
                    </Swiper>

                </IntroSlidesList>
            </IntroSlides>}
        </IntroInner>
    );
}

export default Intro;

import React, {useEffect, useState} from 'react';
import {
    WorkingBlockInner,
    WorkingBlockItem,
    WorkingBlockList,
    WorkingBlockLogo, WorkingBlockName,
    WorkingBlockTitle,
    WorkingBlockWrapper
} from "./styled";
import Container from "../Container/Container";
import axios from "axios";
import { token, version } from '../../global/config';

function WorkingBlock(props) {

    const WorkingData = [
        {
            logo: require('../../assets/img/working/1.png'),
            name: 'We are \n passionate.'
        },
        {
            logo: require('../../assets/img/working/2.png'),
            name: 'Quality \n above all.'
        },
        {
            logo: require('../../assets/img/working/3.png'),
            name: 'Never \n compromise.'
        },
        {
            logo: require('../../assets/img/working/4.png'),
            name: 'Always on \n time results.'
        },

    ]

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/culture?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <WorkingBlockWrapper>
            <Container>
                <WorkingBlockInner>
                    <WorkingBlockTitle>{dataAxios?.length ? dataAxios?.[2].title : 'Working with us is special!'}</WorkingBlockTitle>
                    <WorkingBlockList>

                        {dataAxios?.length ? dataAxios?.[2].items.map((work, idx) => {
                                return (
                                    <WorkingBlockItem key={idx}>
                                        {work.logo && <WorkingBlockLogo>
                                            <img src={work.logo} alt={work.name}/>
                                        </WorkingBlockLogo>}
                                        {work.name && <WorkingBlockName>{work.name}</WorkingBlockName>}
                                        {work.nameLine2 && <WorkingBlockName>{work.nameLine2}</WorkingBlockName>}
                                    </WorkingBlockItem>
                                )
                            }) : 
                            WorkingData?.map(((work, idx) => {
                                return (
                                    <WorkingBlockItem key={idx}>
                                        {work.logo && <WorkingBlockLogo>
                                            <img src={work.logo} alt={work.name}/>
                                        </WorkingBlockLogo>}
                                        {work.name && <WorkingBlockName>{work.name}</WorkingBlockName>}
                                    </WorkingBlockItem>
                                )
                            }))
                        }
                    </WorkingBlockList>
                </WorkingBlockInner>
            </Container>
        </WorkingBlockWrapper>
    );
}

export default WorkingBlock;

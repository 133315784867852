import styled from "styled-components";
import sharedProps from "./sharedProps";
import {COLORS} from "./Theme";
import {device} from "./responsive";

const StyledH1 = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 94px;
  line-height: 1.1;
  letter-spacing: -0.02em;
  @media ${device.tabletL} {
    font-size: 80px;
    line-height: 1.15;
  }
  @media ${device.mobile} {
    font-size: 40.3px;
    line-height: 1.15;
  }

`;
const StyledH2 = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 1.15;
  letter-spacing: -0.025em;
  color: ${COLORS.SECONDARYBLACK};
  ${sharedProps};
  @media ${device.tabletL} {
    font-size: 56px;
    line-height: 1.25;
  }
  @media ${device.mobile} {
    font-size: 40px
  }
`;
const StyledH3 = styled.h3`
  font-style: normal;
  font-weight: 300;
  font-size: 56px;
  line-height: 1.25;
  letter-spacing: -0.02em;
  ${sharedProps};
  @media ${device.mobile} {
    font-size: 40px;
    line-height: 1.25;
  }
`;
const StyledH4 = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 1.3;
  letter-spacing: -0.02em;
`;
const StyledH5 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 1.47;
  letter-spacing: -0.02em;
`;
const StyledH6 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.5;
  color: ${COLORS.SECONDARYBLACK};
  ${sharedProps};
  @media ${device.tabletL} {
    font-size: 24px;
    line-height: 1.58;
  }
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 1.6;
  }

`;

//= =====LINKS======

const StyledL = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.78;
  color: ${COLORS.BLACK3};
  ${sharedProps};
`;
const StyledXXL = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 1.58;
  color: ${COLORS.SECONDARYBLACK};
  ${sharedProps};
`;
const StyledXL = styled.div`
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 1.6;
  color: ${COLORS.SECONDARYBLACK};
  ${sharedProps};
`;


const StyledTextA = styled.a`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.3;
  
  &:hover{
     text-decoration-line: underline;
  }
`;

// ======TEXTS======

const StyledTextM = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.3;
`;

const StyledTextS500 = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
`;


const StyledTextS = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.3;
`;

const StyledTextR = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.88;
  color: ${COLORS.BLACK2};
  ${sharedProps};
`;

// eslint-disable-next-line
export default {
    H1: StyledH1,
    H2: StyledH2,
    H3: StyledH3,
    H4: StyledH4,
    H5: StyledH5,
    H6: StyledH6,
    TextL: StyledL,
    TextXXL: StyledXXL,
    TextM: StyledTextM,
    TextA: StyledTextA,
    TextS: StyledTextS,
    TextR: StyledTextR,
    TextS500: StyledTextS500,
    TextXL: StyledXL,
};

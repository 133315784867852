import Intro from "../../Components/Intro/Intro";
import VideoBanner from "../../Components/videoBanner/videoBanner";
import styled from "styled-components";
import Container from "../../Components/Container/Container";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import WorkItem from "../../Components/WorkItem/WorkItem";
import Services from "../../Components/Services/Services";
import Results from "../../Components/Results/Results";
import Clients from "../../Components/Clients/Clients";
import {device} from "../../styles/responsive";
import React, {useEffect, useRef, useState} from 'react'
import useWindowSize from "../../hooks/useWindowSize";
import {gsap} from "gsap";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import {Draggable} from "gsap/Draggable";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import VideoBannerImage from '../../assets/img/banner.jpg'
import Tell from "../../Components/Tell/Tell";
import BlogHome from "../../Components/BlogHome/BlogHome";
import Button from "../../Components/Button/Button";
import Scrollbar from "smooth-scrollbar";
import axios from "axios"
import { token, version } from "../../global/config";

const servicesList = [
    {
        name: 'Discovery',
        isNew: true,
    },
    {
        name: 'Userflows',
        isNew: false,
    },
    {
        name: 'Brand Research',
        isNew: false,
    },
    {
        name: 'Sitemaps',
        isNew: false,
    },
    {
        name: 'Website Deisgn',
        isNew: false,
    },
    {
        name: 'User Personas',
        isNew: false,
    },
    {
        name: 'UI/UX Design',
        isNew: false,
    },
    {
        name: 'Branding Book',
        isNew: false,
    },
    {
        name: 'Prototyping',
        isNew: false,
    },
    {
        name: 'Poster design',
        isNew: false,
    },
    {
        name: 'Wireframing',
        isNew: false,
    },
    {
        name: 'Logo design',
        isNew: true,
    },
    {
        name: 'App design',
        isNew: false,
    },
    {
        name: 'Style Guides',
        isNew: false,
    },

];


const Homepage = ({WorksItemsData, BlogItemsData, setVideo, setOpenPopup , closePreloaderAnim}) => {

    gsap.registerPlugin(Draggable, ScrollTrigger);

    // skew
    const data = {
        ease: 0.1,
        current: 0,
        previous: 0,
        rounded: 0
    };

    const size = useWindowSize();
    const requestAnimation = useRef();

    const skewScrolling = () => {
        //Set Current to the scroll position amount
        Scrollbar.getAll()[0] ? data.current = Scrollbar.getAll()[0].offset.y : data.current = window.scrollY
        // Set Previous to the scroll previous position
        data.previous += (data.current - data.previous) * data.ease;
        // Set rounded to
        data.rounded = Math.round(data.previous * 100) / 100;

        // Difference between
        const difference = data.current - data.rounded;
        const acceleration = difference / size.width;
        const velocity = +acceleration;
        const skew = velocity * 15;

        //Assign skew and smooth scrolling to the scroll container

        if (document.querySelectorAll('.skewed').length > 0) {
            document.querySelectorAll('.skewed').forEach((el) => {
                el.style.transform = `skewY(${skew}deg)`
            })
            requestAnimation.current = requestAnimationFrame(skewScrolling);
        }
    };

    useEffect(() => {
        if (size.width >= 1279) {
            skewScrolling()
        } else {
            cancelAnimationFrame(requestAnimation.current)
        }

        return () => {
            cancelAnimationFrame(requestAnimation.current);
        }
        // eslint-disable-next-line
    }, []);

    const [dataAxiosPosts , setdataAxiosPosts] = useState([]);
    
    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v1/cdn/stories/?version=${version}&token=${token}&starts_with=posts/`).then(res =>{
            const data = res.data.stories;
            setdataAxiosPosts(data)
        })

        //eslint-disable-next-line
    },[]);
    
    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/home?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[]);


    return (
        <>
            
              <Intro title={dataAxios?.length ? dataAxios?.[0].title : 'Where ideas are seeded, grown and shown.'} slider={dataAxios?.length && dataAxios?.[0]} isHome={true} animText={true} closePreloaderAnim={closePreloaderAnim} />

            <VideoBanner setOpenPopup={setOpenPopup} setVideo={setVideo} VideoBannerImage={dataAxios?.length ? dataAxios[1].ImageBanner : VideoBannerImage}
                         isVideo={true}/>
            <WonderingWrapper>
                <Container>
                    <WonderingInner>
                        <WonderingCol>
                            <FadeInAnimation>
                                <WonderingTitle>
                                    {dataAxios?.length ? dataAxios[3].titleLineFirst : 'Wondering'}
                                    <br/>
                                    {dataAxios?.length ? dataAxios[3].titleLineSecond : 'what is u'}
                                    <b>{dataAxios?.length ? dataAxios[3].titleLineSecondBold : 'gna'}</b> ?
                                    
                                </WonderingTitle>
                                <WonderingButton>
                                    <Button type={'link'} to={'/about'}>
                                        {dataAxios?.length ? dataAxios[3].button : 'About'}
                                    </Button>
                                </WonderingButton>
                            </FadeInAnimation>
                        </WonderingCol>
                        <WonderingCol>
                            <FadeInAnimation>
                                <WonderingText>
                                    {dataAxios?.length ? dataAxios[3].descriptionText : 'UGNA is a small group of experienced and passionate professionals with a focus on logo, website and app UI/UX designs. We aims to help small and medium sized businesses compete in the marketplace with modern designs, intuitive digital experiences for your customers.'}
                                </WonderingText>
                                <WonderingButtonMob>
                                    <Button type={'link'} to={'/about'}>
                                      {dataAxios?.length ? dataAxios[3].button : 'About'}
                                    </Button>
                                </WonderingButtonMob>
                            </FadeInAnimation>
                        </WonderingCol>
                    </WonderingInner>
                </Container>
            </WonderingWrapper>
            <WorksHomeWrapper className={'skewed'}>
                <Container>
                    <FadeInAnimation><WorksHomeTitle>
                        {dataAxios?.length ? dataAxios[4].titleStart : 'Our'}
                        <b>{dataAxios?.length ? dataAxios[4].titleBold : 'work'}</b>
                        {dataAxios?.length ? dataAxios[4].titleEnd : 'at a glance.'}
                    </WorksHomeTitle></FadeInAnimation>
                    <WorksHomeList>

                        {dataAxios?.length ? dataAxios[4].worksItems.content?.map((work, i) => {
                                const image = work.attrs.body[0].image;
                                const name = work.attrs.body[0].name;
                                const subname = work.attrs.body[0].subname;
                                const link = work.attrs.body[0].link;
                                return (
                                    <FadeInAnimation key={i}>
                                        <WorkItem image={image} name={name} subname={subname} link={link}/>
                                    </FadeInAnimation>
                                )
                            }) : 
                            WorksItemsData?.map((work, i) => {
                              const {image, name, subname, link} = work;
                              return (
                                  <FadeInAnimation key={i}>
                                      <WorkItem image={image} name={name} subname={subname} link={link}/>
                                  </FadeInAnimation>
                              )
                          })
                        }
                    </WorksHomeList>
                    <FadeInAnimation>
                        <WorksMoreButton>
                            <Button tollerance={1} type={'nolink'}> {dataAxios?.length ? dataAxios[4].button : 'More'}</Button>
                        </WorksMoreButton>
                    </FadeInAnimation>
                </Container>
            </WorksHomeWrapper>

            <Services servicesList={dataAxios?.length ? dataAxios[5] : servicesList}/>
            <Results resultsData={dataAxios?.length && dataAxios[6]}/>
            <Clients clientsData={dataAxios?.length && dataAxios[7]}/>
            <BlogHome BlogItemsData={dataAxiosPosts?.length ? dataAxiosPosts : BlogItemsData} BlogHeaderData={dataAxios?.length && dataAxios[8]}/>
            <Tell isHome={true} tellData={dataAxios?.length ? dataAxios[9] : 'Excited about your own project?'}/>
        </>
    )
}

const WonderingWrapper = styled.div`
  padding: 200px 0;
  @media ${device.tabletL} {
    padding-top: 120px;
    padding-bottom: 140px;
  }
  @media ${device.mobile} {
    padding-top: 140px;
    padding-bottom: 140px;

  }
`;

const WonderingInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.tabletL} {
    flex-direction: column;
  }
`;

const WonderingCol = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media ${device.tabletL} {
    width: 100%;
  }
`;

const WonderingTitle = styled(Typography.H3)`
  margin-bottom: 42px;

  b, span {
    color: ${COLORS.PURPLE};
    font-weight: normal;
  }

  @media ${device.tabletL} {
    margin-bottom: 30px;
  }
`;

const WonderingText = styled(Typography.H6)`
  @media ${device.tabletL} {
    margin-bottom: 40px;
  }
`;

const WonderingButton = styled.div`
  display: block;
  @media ${device.tabletL} {
    display: none;
  }
`;

const WonderingButtonMob = styled.div`
  display: none;
  @media ${device.tabletL} {
    display: block;
  }
`;

const WorksHomeTitle = styled(Typography.H3)`
  max-width: 447px;
  margin-bottom: 58px;

  b, span {
    color: ${COLORS.BLUE};
    font-weight: normal;
  }

  @media ${device.tabletL} {
    margin-bottom: 48px;
  }
`;
const WorksMoreButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  @media ${device.tabletL} {
    margin-top: 70px;
  }
`;

const WorksHomeWrapper = styled.div``;

const WorksHomeList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 115px 30px;
  @media ${device.tabletL} {
    column-gap: 40px;
    row-gap: 64px;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
    gap: 50px;
  }

`;


export default Homepage;

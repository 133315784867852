import {useRef, useEffect} from 'react';
import {
    BlogContentAuthor,
    BlogContentBottom, BlogContentImage,
    BlogContentInner, BlogContentItem,
    BlogContentLeft,
    BlogContentList,
    BlogContentRight,
    BlogContentSummary,
    BlogContentWrapper,BlogContentPart, BlogContentPartTitle, BlogContentPartParags, BlogContentPartImageFull, BlogContentPartImageTwo
} from "./styled";
import {ContainerMiddle} from "../ContainerMiddle/styles";

import authorImage from '../../assets/img/author.png';
import authorImage1 from '../../assets/img/b1.png';
import authorImage2 from '../../assets/img/b2.png';
import authorImage3 from '../../assets/img/b3.png';
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import Recent from "../Recent/Recent";
import Scrollbar from "smooth-scrollbar";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger)

const BlogContent = ({BlogItemsData, PostData, activeTab, setActiveTab, defaultTabName}) => {
        
        const stikyWrapp = useRef();
        const stikyNav = useRef(null);


        const handleClick = (tab, e) => {
            setActiveTab(tab);
            
            const getAttAnchor = e.target.getAttribute('data-anchor');
            
            if(getAttAnchor != null){
                
                if(document.getElementById(getAttAnchor) != null){
                    const detOffsetTopBlock = document.getElementById(getAttAnchor).offsetTop;
                    const detOffsetTopParent = document.querySelector('.Blog__content--wrapper').offsetTop;
                    
                    Scrollbar.getAll()[0].setPosition(1000, detOffsetTopBlock + detOffsetTopParent, 1000);
                }

            }
        }

        let scrollTimeout = gsap.delayedCall(0, () => { 
            let getOffSetScroll;
            Scrollbar.getAll()[0] ? getOffSetScroll = Scrollbar.getAll()[0].offset.y : getOffSetScroll = window.scrollY

            document.querySelectorAll('.blog__content--part').forEach((el, i) => {
                if(el.offsetTop + document.querySelector('.Blog__content--wrapper').offsetTop <= getOffSetScroll){
                    let getId = el.getAttribute('id');
                    let getNavLink = document.querySelector(`.stiky--nav--item[data-anchor=${getId}]`).innerText;
                    setActiveTab(getNavLink);
                    let ScrollIntroBlock = getOffSetScroll - (el.offsetTop + document.querySelector('.Blog__content--wrapper').offsetTop);
                    let heightElem = el.clientHeight;
                    let parcentByBlock = (ScrollIntroBlock*100)/heightElem;

                    if(parcentByBlock <= 100){
                        document.querySelector(`.stiky--nav--item[data-anchor=${getId}] span`).style.width = parcentByBlock + "%";
                    }else{
                        document.querySelector(`.stiky--nav--item[data-anchor=${getId}] span`).style.width = 0 + "%";
                    }   
                    
                }
                
            })
        }).pause();

        ScrollTrigger.addEventListener("scrollStart", () => scrollTimeout.pause());
        ScrollTrigger.addEventListener("scrollEnd", () => scrollTimeout.restart(true));

        useEffect(() => {

            initGsapAnimation()            

            //eslint-disable-next-line
        },[stikyWrapp.current])

        const initGsapAnimation = () => {
            ScrollTrigger.create({
                trigger: stikyNav.current,
                start: "top top+=80",
                endTrigger: stikyWrapp.current,
                end: "bottom bottom-=80",
                pin: true,
                pinSpacing: false
            });
        }


    return (
        <BlogContentWrapper className="Blog__content--wrapper">
            <ContainerMiddle>
                <BlogContentInner ref={stikyWrapp}>
                    <BlogContentLeft ref={stikyNav} className='stiky--nav'>
                        <BlogContentSummary>{PostData?.test[2].summary ? PostData?.test[3].summary : "Summary"} </BlogContentSummary>
                        <BlogContentList >
                            <BlogContentItem className='stiky--nav--item' data-anchor={PostData?.test[2]?.activeLinkIdText ? PostData?.test[2].activeLinkIdText : null} active={activeTab === defaultTabName} onClick={(e) => handleClick(PostData?.test[2].activeLink, e)}>
                                {PostData?.test[2].activeLink ? PostData?.test[2].activeLink : "How to get started."}
                                <span></span>
                            </BlogContentItem>

                            {PostData?.test[2].links && PostData?.test[2].links?.map((result, i) => {
                                return (
                                    <BlogContentItem className='stiky--nav--item' data-anchor={result.anchor} key={i} onClick={(e) => handleClick(result.item, e)} active={activeTab === result.item}>
                                       {result.item}
                                       <span></span>
                                    </BlogContentItem>
                                )
                            })}

                        </BlogContentList>
                        <BlogContentBottom>
                            <img src={PostData?.test[2].imageAuthor ? PostData?.test[2].imageAuthor : authorImage} alt="author"/>
                            <BlogContentAuthor>{PostData?.test[2].nameAuthor ? PostData?.test[2].nameAuthor : "Hike Van Zike"}</BlogContentAuthor>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 22.5C16.3807 22.5 17.5 21.3807 17.5 20C17.5 18.6193 16.3807 17.5 15 17.5C13.6193 17.5 12.5 18.6193 12.5 20C12.5 21.3807 13.6193 22.5 15 22.5Z" stroke="#423D35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M23.75 28.125C25.1307 28.125 26.25 27.0057 26.25 25.625C26.25 24.2443 25.1307 23.125 23.75 23.125C22.3693 23.125 21.25 24.2443 21.25 25.625C21.25 27.0057 22.3693 28.125 23.75 28.125Z" stroke="#423D35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M23.75 16.875C25.1307 16.875 26.25 15.7557 26.25 14.375C26.25 12.9943 25.1307 11.875 23.75 11.875C22.3693 11.875 21.25 12.9943 21.25 14.375C21.25 15.7557 22.3693 16.875 23.75 16.875Z" stroke="#423D35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.6484 15.7266L17.1016 18.6484" stroke="#423D35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M17.1016 21.3516L21.6484 24.2734" stroke="#423D35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#D0CECD"/>
                            </svg>
                        </BlogContentBottom>
                    </BlogContentLeft>
                    <BlogContentRight>


                        {PostData?.test[3].contentParts && PostData?.test[3].contentParts?.map((part, i) => {
                                return (
                                    <BlogContentPart id={part.idText} key={i} className="blog__content--part">
                                        
                                       {part.partInner.map((single, idx) => {
                                                
                                                if(single.component === "ContentPartTitlePost"){
                                                    return (
                                                        <FadeInAnimation key={idx}>
                                                            <BlogContentPartTitle>{single?.title ? single.title : 'Things to keep in mind'}</BlogContentPartTitle>
                                                        </FadeInAnimation>
                                                    )
                                                }else  if(single.component === "ContentPartParagsPost"){
                                                    return (
                                                        <FadeInAnimation key={idx}>
                                                            <BlogContentPartParags>
                                                                {single?.parags && single.parags.map((text, i) => {
                                                                    return (
                                                                        <p key={i}>
                                                                            {text.text}
                                                                        </p>
                                                                    )
                                                                })}
                                                            </BlogContentPartParags>
                                                        </FadeInAnimation>
                                                    )     
                                                }else if(single.component === "ContentPartImageFullPost"){
                                                    return (
                                                        <FadeInAnimation key={idx}>
                                                            <BlogContentPartImageFull>
                                                                <img src={single?.image ? single.image : authorImage1} alt=""/>
                                                            </BlogContentPartImageFull>
                                                        </FadeInAnimation>
                                                    ) 
                                                }else if(single.component === "ContentPartImageTwoPost"){
                                                    return (
                                                        <FadeInAnimation key={idx}>
                                                            <BlogContentPartImageTwo>
                                                                <BlogContentImage>
                                                                    <img src={PostData?.test[3].galleryImage ? PostData?.test[3].galleryImage[0].image : authorImage2} alt=""/>
                                                                </BlogContentImage>
                                                                <BlogContentImage>
                                                                    <img src={PostData?.test[3].galleryImage ? PostData?.test[3].galleryImage[1].image : authorImage3} alt=""/>
                                                                </BlogContentImage>
                                                            </BlogContentPartImageTwo>
                                                        </FadeInAnimation>
                                                    ) 
                                                }else{
                                                    return null
                                                }
                                                
                                        })}
                                    </BlogContentPart>
                                )
                        })}
                    </BlogContentRight>
                </BlogContentInner>
                
            </ContainerMiddle>
            <Recent BlogItemsData={PostData?.test[4] ? PostData?.test[4] : BlogItemsData}/>
        </BlogContentWrapper>
    );
}

export default BlogContent;

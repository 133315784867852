import styled from "styled-components";
import Typography from "../../styles/Typography";
import SharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";
import sharedProps from "../../styles/sharedProps";

export const PrinciplesWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 35px;
  overflow: hidden;
  @media ${device.tabletL} {
    padding-top: 40px;
  }
  @media ${device.mobile} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

export const PrinciplesInner = styled.div``;

export const PrinciplesTitle = styled(Typography.H3)`
  b {
    ${SharedProps};
    font-weight: normal;

    &.yellow {
      color: ${COLORS.YELLOW};
    }

    &.green {
      color: ${COLORS.GREEN};
    }

    &.orange {
      color: ${COLORS.ORANGE};
    }

    &.red {
      color: ${COLORS.RED};
    }

  }

  margin-bottom: 226px;

  @media ${device.tabletL} {
    font-size: 40px;
  }
  @media ${device.tablet} {
    margin-bottom: 140px;
  }
  @media ${device.mobile} {
    font-size: 34px;
  }
`;

export const PrinciplesSlider = styled.div`
  display: flex;
`;

export const PrinciplesViewport = styled.div`
  display: flex;
  //width: fit-content;
  width: 100%;
  flex-wrap: nowrap;
  //overflow: hidden;
  @media ${device.laptopSmall} {
    flex-direction: column;
  }
`;


export const PrinciplesLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 150px;
  max-width: 228px;
  width: 100%;

  img, svg {
    width: 100%;
  }

  @media ${device.laptopSmall} {
    margin-right: 130px;
  }
  @media ${device.tabletL} {
    margin-right: 0;
  }
  @media ${device.tablet} {
    margin-left: 40px;
    transform: rotate(-90deg);
  }
  @media ${device.mobile} {
    margin-left: 40px;
  }
`;

export const PrinciplesItem = styled.div`
  min-width: 100%;
  //flex-shrink: 0;
  display: flex;

  > div {
    max-width: 1560px;
    padding-left: 80px;
    padding-right: 80px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    @media ${device.tabletL} {
      padding-left: 60px;
      padding-right: 60px;
      justify-content: space-between;
    }
    @media ${device.tablet} {
      padding-left: 40px;
      padding-right: 40px;
      flex-direction: column;
    }
    @media ${device.mobile} {
      padding-left: 20px;
      padding-right: 20px;
    }

  }
  &:nth-child(2) {
    @media ${device.tablet} {
      ${PrinciplesLeft} {
        transform: rotate(90deg);
      }
    }
  }

  &:not(:last-child) {
    margin-right: 160px;
    @media ${device.laptopSmall} {
      margin-right: 0;
      margin-bottom: 160px;
    }
    @media ${device.tablet} {
      margin-bottom: 80px;
    }
  }

  &:not(:first-child) {
    margin-left: -35%;
  }

  @media (max-width: 1850px) {
    &:not(:first-child) {
      margin-left: -25%;
    }
  }

  @media (max-width: 1650px) {
    &:not(:first-child) {
      //margin-left: 0;
    }
  }

  @media ${device.laptopL} {
    &:not(:first-child) {
      margin-left: 0;
    }
  }
`;


export const PrinciplesRight = styled.div`
  @media ${device.tabletL} {
    width: 52%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const PrinciplesName = styled(Typography.H3)`
  margin-bottom: 60px;
  width: 70%;
  @media ${device.laptopSmall} {
    width: 100%;
  }
  @media ${device.laptopSmall} {
    font-size: 40px;
    margin-bottom: 40px;
  }
  @media ${device.mobile} {
    font-size: 34px;
    margin-bottom: 30px;
  }
`;

export const PrinciplesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 45px;
  @media ${device.laptopSmall} {
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.tabletL} {
    grid-template-columns: 1fr;
  }
`;

export const PrinciplesListItem = styled(Typography.TextXXL)`
  width: 80%;
  @media (max-width: 1850px) {
    width: 90%;
  }
  @media ${device.laptopSmall} {
    font-size: 20px;
    width: 100%;
  }
  @media ${device.tabletL} {
    padding-left: 40px;
    position: relative;
    ${sharedProps};
    &:before {
      position: absolute;
      left: 0;
      top: 10px;
      content: '';
      width: 8px;
      height: 8px;
      background: ${COLORS.GREEN};
      border-radius: 50%

    }
  }
  @media ${device.mobile} {
    padding-left: 30px;
  }
`;

export const PrinciplesVideo = styled.video`
  width: 100%;
  height: auto;
  object-fit: contain;
  outline: none;
  clip-path: inset(2px 2px);

`;




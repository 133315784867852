import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import Scrollbar from "smooth-scrollbar";

export default function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (Scrollbar.getAll()[0]) {
            Scrollbar.getAll()[0].setPosition(0, 0)
        }
    }, [pathname]);

    return null;
}

import styled from "styled-components";
import Typography from "../../styles/Typography";
import sharedProps from "../../styles/sharedProps";
import {COLORS, TRANSITIONS} from "../../styles/Theme";
import SharedProps from "../../styles/sharedProps";
import {device} from "../../styles/responsive";

export const FormTag = styled(Typography.H5)`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  ${sharedProps};
  color: ${COLORS.BLACK3}; 

  svg {
    //width: 30px;
    //margin-right: 16px;
  }

  &:hover{
    >div:after{
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  @media ${device.mobile} {
    font-size: 28px;
    margin-bottom: 21px;
  }

`

export const FormTitle = styled(Typography.H2)`
  margin-bottom: 80px;
  color: ${COLORS.BLACK}; 
  
  @media ${device.tabletM} {
    margin-bottom: 60px;
  }

  @media ${device.mobile} {
    margin-bottom: 40px;
  }
`

export const FormAttachmentIcon = styled.div`
  font-size: 0;
  line-height: 0;
  margin-right: 16px;
  position: relative;
  display: inline-block;

  &:after{
    content: " ";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    //transform: translateX(-50%);
    background-color: ${COLORS.BLACK4};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  svg {
    width: 30px;
    //margin-right: 16px;
  }

`

export const FormCheckboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
  
  @media ${device.mobile} {
    column-gap: 12px;
    row-gap: 12px;
  }
  .formCheckBox{
    //overflow: hidden;
    padding: 16px 30px;
    border: none;
    @media ${device.mobile} {
      padding: 12px 24px;
    }
    span{
      display: inline;
    }

    .ripple{
      display: none;
    }
  }
`;

export const FormCheckboxField = styled.span`
  display: block;
  //padding: 16px 30px;
  border-radius: 50px;
  ${SharedProps};
  color: ${COLORS.BLACK};
  //border: 1px solid ${COLORS.BLACK5};
  //margin-left: 20px;
  //margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.02em;
  transition: ${TRANSITIONS.FAST};
  display: block;

  &:after{
    content: " ";
    content: " ";
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    border-radius: 50px;
    border: 1px solid #D0CECD;
  }
  @media ${device.mobile} {
    //padding: 12px 24px;
    //margin-left: 10px;
  }
  @media ${device.mobile} {
    font-size: 16px
  }

  span{
    z-index: 2;
    position: relative;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: -0.02em;
    transition: ${TRANSITIONS.FAST};
    color: ${COLORS.BLACK};
    @media ${device.mobile} {
      font-size: 16px
    }
  }

`;

export const FormCheckbox = styled.label`
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + {
      ${FormCheckboxField} {
        ${SharedProps};
        background: ${COLORS.PURPLE};
        color: ${COLORS.WHITE};
        &:after{
         background: ${COLORS.PURPLE};
        }
        span{
          color: ${COLORS.WHITE};
        }
      }
    }
  }
`;

export const FormInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  margin-bottom: 30px;
  @media ${device.mobile} {
    margin-bottom: 40px;
  }

  &:nth-of-type(3){
    input{
      font-size: 20px;
      line-height: 1.6;
      @media ${device.mobile} {
        font-size: 16px;
      }

      
      &::placeholder{
        font-size: 34px;
        line-height: 1.5;
        @media ${device.mobile} {
          font-size: 24px;
        }
      }
    }
  }

`;

export const FormInputs = styled.div`
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
  @media ${device.mobile} {
    margin-bottom: 0;
  }   
`;

export const FormInput = styled.input`
  width: 100%;
  border: 0;
  ${sharedProps};
  color: ${COLORS.SECONDARYBLACK};
  font-size: 34px;
  letter-spacing: -0.02em;
  line-height: 1.5;
  outline: none;
  //border-bottom: 1px solid ${COLORS.BLACK4};
  transition: ${TRANSITIONS.FAST};
  padding-bottom: 10px;
  height: 63px;
  background: ${COLORS.WHITE5};
  position: relative;
  &:after{
    content: '';
  }
  @media ${device.mobile} {
    height: 48px;
  }

  &:focus {
    //border-bottom: 1px solid ${COLORS.BLACK2};
  }

  &:focus + div {
    
    span{
      background: ${COLORS.BLACK2};
    }
  }

  &::placeholder {
    color: ${COLORS.BLACK4};
  }

  &.entered--text{
    //border-bottom: 1px solid ${COLORS.PURPLE};
    & + div{
      span{
        background: ${COLORS.PURPLE};
      }
    }
  }

  ${props => props && !props.error} {
    //border-bottom: 1px solid ${COLORS.RED};

    & + div{  
      span{
        background: ${COLORS.RED};
      }
    }
    &:focus {
      //border-bottom: 1px solid ${COLORS.RED};
    }

    &:focus + div {
      span{
        background: ${COLORS.RED};
      }
      
    }
  } }
  
  @media ${device.mobile} {
  font-size: 24px;
}
`;

export const FormLine = styled.span`
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  //bottom: 20px;
  background: ${COLORS.BLACK4};
  transition: ${TRANSITIONS.FAST};
}
`;

export const FormAttachment = styled.label`
  cursor: pointer;

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const FormAttachmentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
`;

export const FormAttachmentWrapper = styled.div`
  margin-bottom: 40px;
  label > div{
    font-size: 28px;
  }
`;


export const FormAttachmentItem = styled.div`
  display: flex;
  align-items: center;
  padding: 17px 26px;
  ${sharedProps};
  border: 1px solid ${COLORS.BLACK4};
  border-radius: 50px;
  margin-left: 30px;
  margin-bottom: 30px;

  span {
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: -0.02em;
    margin-right: 10px;
    color: ${COLORS.BLACK};
    @media ${device.mobile} {
      font-size: 18px
    }

  }

  svg {
    cursor: pointer;
  }
`;
export const FormCheckboxesWrapper = styled.div`
  margin-left: -20px;
  margin-bottom: 47px;
  @media ${device.mobile} {
    margin-left: -10px;
  }
`;

export const ErrorMessageSpan = styled.span`
  position: absolute;
  left: 0;
  bottom: -5px;
  letter-spacing: -0.02em;
  font-size: 14px;
  display: block;
  ${sharedProps};
  color: ${COLORS.RED};
  transition: ${TRANSITIONS.FAST};
`;

export const FormCheckboxError = styled.div`
  width: 100%;
  color: ${COLORS.RED};
  letter-spacing: -0.02em;
  font-size: 14px;
  margin-left: 20px;
`

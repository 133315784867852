import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import styled from "styled-components";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import gsap, {Expo} from "gsap";
import Cursor from "../../styles/Cursor";
import {useLocation} from "react-router-dom";
import useWindowSize from "../../hooks/useWindowSize";

function VideoPopup({video, openPopup, setOpenPopup}) {

    function useTicker(callback, paused) {
        useLayoutEffect(() => {
            if (!paused && callback) {
                gsap.ticker.add(callback);
            }
            return () => {
                gsap.ticker.remove(callback);
            };
        }, [callback, paused]);
    }

    const EMPTY = {};

    const size = useWindowSize()
    function useInstance(value = {}) {
        const ref = useRef(EMPTY);
        if (ref.current === EMPTY) {
            ref.current = typeof value === "function" ? value() : value;
        }
        return ref.current;
    }

// Function for Mouse Move Scale Change
    function getScale(diffX, diffY) {
        const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
        return Math.min(distance / 735, 0.35);
    }

// Function For Mouse Movement Angle in Degrees

    function closePopupVideo() {
        videoWrappRef.current.classList.add('closeVideo-anim');

        setTimeout(() => {
            setOpenPopup(false);
            videoRef.current.pause()
            videoWrappRef.current.style.pointerEvents = 'none'
        }, 1350)
    }

    function MouseDownPopupVideo(event) {
        cursorRef.current.classList.add('closeVideo');
        var timeoutID = setTimeout(() => {
            closePopupVideo()
        }, 1350)

        event.target.closest('.VideoPopup--wrapp').addEventListener("mouseup", function () {
            cursorRef.current.classList.remove('closeVideo');
            clearTimeout(timeoutID);
        })
    }

    const closeVideo = () =>{
        console.log(size);
        if(size.width <= 1200){
            closePopupVideo()
        }
    }

    const videoRef = useRef(null);
    const cursorRef = useRef(null);
    const videoWrappRef = useRef(null);
    const location = useLocation();
    const ref = useRef();


    useEffect(() => {
        if (openPopup && video) {
            videoRef.current.play()
            videoWrappRef.current.style.pointerEvents = 'unset'

        }

        //eslint-disable-next-line
    }, [openPopup])

    const pos = useInstance(() => ({x: 0, y: 0}));
    const vel = useInstance(() => ({x: 0, y: 0}));
    const set = useInstance();

    // Set GSAP quick setter Values on useLayoutEffect Update
    useLayoutEffect(() => {
        // set.x = gsap.quickSetter(cursorRef.current, "x", "px");
        // set.y = gsap.quickSetter(cursorRef.current, "y", "px");
        set.sx = gsap.quickSetter(cursorRef.current, "scaleX");
        set.sy = gsap.quickSetter(cursorRef.current, "scaleY");

        //eslint-disable-next-line
    }, []);

    // Start Animation loop
    const loop = useCallback(() => {
        // Calculate angle and scale based on velocity
        var scale = getScale(vel.x, vel.y); // Blob Squeeze Amount

        // set.x(pos.x);
        // set.y(pos.y);
        set.sx(1 + scale);
        set.sy(1 - scale);

        //eslint-disable-next-line
    }, []);


    // Run on Mouse Move
    useLayoutEffect(() => {
        // Caluclate Everything Function
        const setFromEvent = (e) => {
            // Mouse X and Y
            const x = e.clientX;
            const y = e.clientY

            // Animate Position and calculate Velocity with GSAP
            gsap.to(pos, {
                x: x,
                y: y,
                duration: 1.25,
                ease: Expo.easeOut,
                onUpdate: () => {
                    vel.x = x - pos.x;
                    vel.y = y - pos.y;
                }
            });

            loop();
        };

        window.addEventListener("pointermove", setFromEvent);
        window.addEventListener("mousemove", setFromEvent);


        return () => {
            window.removeEventListener("pointermove", setFromEvent);
            window.removeEventListener("mousemove", setFromEvent);
        };

        //eslint-disable-next-line
    }, []);

    useTicker(loop);

    useLayoutEffect(() => {
        // document.documentElement.scrollTo(0, 0);

        ref.current = new Cursor({
            cursor: '.v-cursor',
            dot: '.v-dot',
            dot2: 'v-showreel',
            cursorClose: '.VideoPopup--cursor',
        })
        return () => ref.current = null;

    }, [location.pathname]);

    return (
        <VideoPopupWrapper ref={videoWrappRef} className="VideoPopup--wrapp" open={openPopup}
                           onMouseDown={MouseDownPopupVideo} onClick={closeVideo}>
            <VideoPopupVideoContainer>
                <video ref={videoRef} src={video} preload={'none'} controls={false} playsInline={true}></video>
            </VideoPopupVideoContainer>
            <VideoPopupCursore ref={cursorRef} className="VideoPopup--cursor">
                <div>
                    <svg width="100%" height="100%">
                        <rect x="30%" width="1%" rx="2%" fill="white" stroke="white">
                            <animate attributeName='height' values="6%; 10%; 6%" dur="1.4s" repeatCount="indefinite"/>
                            <animate attributeName='y' values="47%; 45%; 47%" dur="1.4s" repeatCount="indefinite"/>
                        </rect>
                        <rect x="38%" width="1%" rx="2%" fill="white" stroke="white">
                            <animate attributeName='height' values="6%; 20%; 6%" dur="1.2s" repeatCount="indefinite"/>
                            <animate attributeName='y' values="47%; 40%; 47%" dur="1.2s" repeatCount="indefinite"/>
                        </rect>
                        <rect x="46%" width="1%" rx="2%" fill="white" stroke="white">
                            <animate attributeName='height' values="6%; 30%; 6%" dur="1s" repeatCount="indefinite"/>
                            <animate attributeName='y' values="47%; 35%; 47%" dur="1s" repeatCount="indefinite"/>
                        </rect>
                        <rect x="54%" width="1%" rx="2%" fill="white" stroke="white">
                            <animate attributeName='height' values="6%; 40%; 6%" dur="0.8s" repeatCount="indefinite"/>
                            <animate attributeName='y' values="47%; 30%; 47%" dur="0.8s" repeatCount="indefinite"/>
                        </rect>
                        <rect x="62%" width="1%" rx="2%" fill="white" stroke="white">
                            <animate attributeName='height' values="6%; 34%; 6%" dur="1.1s" repeatCount="indefinite"/>
                            <animate attributeName='y' values="47%; 33%; 47%" dur="1.1s" repeatCount="indefinite"/>
                        </rect>
                        <rect x="70%" width="1%" rx="2%" fill="white" stroke="white">
                            <animate attributeName='height' values="6%; 14%; 6%" dur="1.3s" repeatCount="indefinite"/>
                            <animate attributeName='y' values="47%; 43%; 47%" dur="1.3s" repeatCount="indefinite"/>
                        </rect>
                    </svg>
                </div>
            </VideoPopupCursore>

        </VideoPopupWrapper>
    );
}

export default VideoPopup;

const VideoPopupCursore = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  transition: .05s;
  background-color: rgba(16, 16, 16, .3);
  backdrop-filter: blur(10px);
  border-radius: 50% 50%;
  width: 120px;
  height: 120px;
  pointer-events: none;

  &.closeVideo {
    width: 100px;
    height: 100px;
    transition: .7s;

    div {

      &:before {
        width: 100%;
        height: 100%;
      }
    }
  }

  div {
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
      content: " ";
      position: absolute;
      width: 0%;
      height: 0%;
      border-radius: 50% 50%;
      left: 50%;
      top: 50%;
      background: black;
      transform: translate(-50%, -50%);
      transition: 1.1s;
    }
  }

  svg {
    position: relative;
  }
  
  @media (max-width: 1200px){
    display: none;
  }

`;


const VideoPopupWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: ${COLORS.WHITE5};
  ${sharedProps};
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 1.7s;
  cursor: none;

  &:after, &:before {
    content: " ";
    position: absolute;
    width: 100%;
    left: 0;
    height: 50%;
    background: white;
    transition: 2s;
    z-index: 1;
  }

  &:after {
    bottom: 0;
  }

  &:before {
    top: 0;
  }

  &.closeVideo-anim {
    > div {
      video {
        animation: scaleVideo 1.35s ease forwards;
      }
    }

    &:after {
      animation: hiddenVideoAfter 1.75s ease forwards;
    }

    &:before {
      animation: hiddenVideoBefore 1.75s ease forwards;
    }
  }

  @keyframes scaleVideo {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.7);
    }
  }

  @keyframes hiddenVideoAfter {
    0% {
      transform: translateY(101%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  @keyframes hiddenVideoBefore {
    0% {
      transform: translateY(-101%);
    }

    100% {
      transform: translateY(0%);
    }
  }

  ${props => !props.open} {
    opacity: 1;
    visibility: visible;

    &:after {
      transform: translateY(101%);
    }

    &:before {
      transform: translateY(-101%);
    }

    > div {
      video {
        animation: showVideo 1.5s ease forwards;
        animation-delay: 1s;
      }
    }

    @keyframes showVideo {
      0% {
        transform: scale(0.7);
      }

      100% {
        transform: scale(1);
      }
    }
  }
`;


const VideoPopupVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transform: scale(0.7);
    transition: all 1.5s cubic-bezier(.665, .005, .165, 1);
  }
  @media (max-width: 1200px){
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
`;

import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS, TRANSITIONS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import {NavLink} from "react-router-dom";
import {device} from "../../styles/responsive";

export const FooterWrapper = styled.footer`
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 100px 0 60px;
  z-index: 1;
  background: ${COLORS.PURPLE};
  overflow: hidden;
  ${sharedProps};
  @media ${device.laptopSmall} {
    padding-top: 60px;
  }
  @media ${device.tablet} {
    padding-bottom: 30px;
  }
`;

export const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media ${device.laptopSmall} {
    flex-wrap: wrap;
  }

`;

export const FooterCol = styled.div`
`;

export const FooterCenter = styled.div`
  display: flex;
  align-items: flex-start;
`;


export const FooterLast = styled.div`
  @media ${device.laptopSmall} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%
  }
  @media ${device.tablet} {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid ${COLORS.PURPLE2};
    ${sharedProps};
    align-items: flex-start;
    flex-direction: column-reverse;
  }
`;


export const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 80px;
  @media ${device.laptopL} {
    gap: 20px 60px;
  }
  @media ${device.tabletL} {
    gap: 20px 40px;
  }
  @media ${device.tabletL} {
    gap: 16px 32px;
  }
`;


export const FooterSocial = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin-left: 80px;
  @media ${device.laptopL} {
    margin-left: 60px;
  }
  @media ${device.tabletL} {
    margin-left: 40px;
  }
  @media ${device.tabletL} {
    gap: 16px;
    margin-left: 32px;
  }

`;

export const FooterSocialTablet = styled.div`
  display: none;
  width: 100%;
  margin-top: 70px;
  justify-content: space-between;

  a {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  @media ${device.tablet} {
    display: none;
  }
`;


export const FooterLinkA = styled.a`
  text-decoration: none;
`;

export const FooterH6 = styled(Typography.H6)`
  color: ${COLORS.WHITE5};
  font-weight: 500;
  ${sharedProps};
  @media ${device.tabletL} {
    margin-top: 40px;
  }
  @media ${device.tablet} {
    margin-top: 0;
  }
`;

export const FooterH6500 = styled(Typography.H6)`
  font-weight: 500;
  color: ${COLORS.WHITE5};
  ${sharedProps};
`;

export const FooterText = styled(Typography.TextL)`
  color: ${COLORS.WHITE5};
  ${sharedProps};
`;


export const FooterColorText = styled(Typography.H3)`
  color: ${COLORS.GREEN};
  ${sharedProps};
  cursor: default;
  font-weight: 500;
  @media ${device.tabletL} {
    font-size: 40px
  }
  @media ${device.tablet} {
    font-size: 28px;
    br {
      display: none;
    }

    margin-bottom: 48px;
  }
`;

export const FooterLink = styled(NavLink)`
  font-size: 14px;
  line-height: 1.2;
  color: ${COLORS.WHITE5};
  text-decoration: none;
  ${sharedProps};
  transition: ${TRANSITIONS.NORMAL};
  display: inline-block;
  overflow: hidden;
  position: relative;
  @media ${device.laptopSmall} {
    font-weight: 500;
  }
  @media ${device.tablet} {
    font-size: 20px;
    line-height: 1.1;
  }
  @media ${device.mobile} {
    font-size: 14px;
    line-height: 1.1;
  }

  &:hover {
    span {
      &:after, &:before {
        color: ${COLORS.GREEN};
      }

      &:before {
        top: -105%;
      }

      &:after {
        top: 0;
      }
    }
  }

  span {
    display: block;
    color: transparent;

    &:after, &:before {
      ${sharedProps};
      color: ${COLORS.WHITE5};
      display: block;
      position: absolute;
      content: attr(data-text);
      transition: ${TRANSITIONS.FAST};
    }

    &:before {
      top: 0%;
    }

    &:after {
      top: 105%;
    }
  }
`;

export const FooterLogo = styled.img`
  max-width: 154px;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  @media ${device.tablet} {
    max-width: 115px;
    margin-bottom: 20px;
  }

`

export const FooterTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 54px;
  @media ${device.laptopSmall} {
    margin-bottom: 0;
  }
  @media ${device.tablet} {
    width: 100%;
    a {
      width: 60%;
      text-align: center;
    }
  }
`;

export const FooterLittleText = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.9;
  letter-spacing: -0.02em;
  color: ${COLORS.WHITE5};
  ${sharedProps};
`;

export const FooterTopText = styled(Typography.TextXL)`
  color: ${COLORS.OFFWHITE};
  ${sharedProps};
  max-width: 150px;
  font-weight: 500;
  @media ${device.laptopSmall} {
    color: ${COLORS.YELLOW};
    margin-right: 24px;
  }
`

export const FooterBottom = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
`;

import React, {useEffect, useRef} from 'react';
import {MarqueeWrapper} from "./styled";
import {MarqueeWord} from "../MarqueeImages/styled";
import Marquee from "react-fast-marquee";

function MarqueeImages({arrayMarque, speed , cssUnique}) {

    const MarqueeWordRef = useRef([]);


    useEffect(() => {

    }, [speed])

    return (
        <MarqueeWrapper cssUnique={cssUnique}>
                <Marquee speed={50} gradient={false}>
                    {arrayMarque.map((marquee, idx) => {
                        //eslint-disable-next-line
                        {
                            return (
                                <MarqueeWord ref={el => (MarqueeWordRef.current = [...MarqueeWordRef.current, el])}
                                             key={idx}>
                                    {marquee.img && <img src={marquee.img} alt=""/>}
                                    {marquee.text}</MarqueeWord>
                            )
                        }
                    })}
                </Marquee>
        </MarqueeWrapper>
    );
}

export default MarqueeImages;

import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import {device} from "../../styles/responsive";


export const ClientsLogosWrapper = styled.div`
  padding-top: 150px;
  padding-bottom: 150px;
  @media ${device.tabletL}{
    padding-top: 165px;
    padding-bottom: 80px;
  }
  @media ${device.mobile} {
    padding-top: 74px;
  }
`;

export const ClientsLogosInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ClientsLogosTitle = styled(Typography.H2)`
  max-width: 60%;
  margin-bottom: 80px;
  @media ${device.tabletL} {
    max-width: 80%;
    margin-bottom: 90px;
  }
  @media ${device.mobile} {
    max-width: 100%;
  }
    b {
    font-weight: normal;
    color: ${COLORS.RED};
    ${sharedProps};
  }
`;

export const ClientsLogosList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 90px 140px;
  @media ${device.tabletL} {
    gap: 60px 80px;
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${device.mobile} {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
`;

export const ClientsLogosItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  img {
    object-fit: contain;
  }
`;

export const ClientsLogosImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

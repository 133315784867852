import React, {useEffect, useState} from 'react'
import {ProduceInner, ProduceItem, ProduceList, ProduceText, ProduceTitle, ProduceWrapper} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import axios from "axios"
import { token, version } from '../../global/config';

const Produce = (props) =>{

    const produceImages = [
        {
            img: require('../../assets/img/produce/1.png')
        },
        {
            img: require('../../assets/img/produce/2.png')
        },
        {
            img: require('../../assets/img/produce/3.png')
        },

    ];

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/about?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <ProduceWrapper>
            <Container>
                <ProduceInner>
                    <FadeInAnimation>
                        <ProduceTitle>
                            {dataAxios?.length ? dataAxios?.[2].title : 'We are small yet effective and produce results.'}
                        </ProduceTitle>
                    </FadeInAnimation>
                    <FadeInAnimation>
                        <ProduceText>
                            {dataAxios?.length ? dataAxios?.[2].text : 'We are a small group of experienced and passionate professionals (human beings) with a focus on logo, website, app UI/UX designs and development.'}
                        </ProduceText>
                    </FadeInAnimation>
                        <ProduceList>

                        {dataAxios?.length ? dataAxios?.[2].listImages.content.map((produce, idx) => {
                                return(
                                    <FadeInAnimation key={idx}>
                                        <ProduceItem>
                                            <img src={produce.attrs.body[0].image} alt={idx}/>
                                        </ProduceItem>
                                    </FadeInAnimation>
                                )
                            }) : 
                            produceImages?.map((produce,idx) =>{
                                return(
                                    <FadeInAnimation key={idx}>
                                        <ProduceItem>
                                            <img src={produce.img} alt={idx}/>
                                        </ProduceItem>
                                    </FadeInAnimation>
                                )
                            })
                        }
                        </ProduceList>
                </ProduceInner>
            </Container>
        </ProduceWrapper>
    );
}

export default Produce;

import React from 'react';
import {
    ServicesBlockAll,
    ServicesBlockInner,
    ServicesBlockLatest,
    ServicesBlockLeft, ServicesBlockLi, ServicesBlockList, ServicesBlockMax,
    ServicesBlockProject,
    ServicesBlockProjectName,
    ServicesBlockProjects,
    ServicesBlockRight,
    ServicesBlockText,
    ServicesBlockTitle,
    ServicesBlockWrapper
} from "./styled";
import Marquee from "../Marquee/Marquee";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";

function ServicesBlock({serviceData}) {

    return (
        <ServicesBlockList>
            {
                serviceData?.map((service, idx) => {
                    const {marquee, list, title, text, projects, marqueeSpeed,latestText} = service;
                    return (
                        <ServicesBlockWrapper key={idx}>
                            {marquee && <Marquee speed={marqueeSpeed} word={marquee}/>}
                            <Container>
                                <ServicesBlockInner>
                                    <FadeInAnimation>
                                        {title && <ServicesBlockMax>
                                            <ServicesBlockTitle>{title}</ServicesBlockTitle>
                                        </ServicesBlockMax>}
                                    </FadeInAnimation>
                                    <ServicesBlockAll>
                                        {list &&
                                            <ServicesBlockLeft>
                                                {list?.map((el, idx) => {
                                                    return <FadeInAnimation key={idx}>
                                                        <ServicesBlockLi>{el.name}</ServicesBlockLi>
                                                    </FadeInAnimation>

                                                })}
                                            </ServicesBlockLeft>
                                        }
                                        <ServicesBlockRight>
                                            <FadeInAnimation>
                                                {text && <ServicesBlockText>{text}</ServicesBlockText>}
                                            </FadeInAnimation>
                                            {latestText &&
                                                <FadeInAnimation>
                                                    <ServicesBlockLatest>{latestText}</ServicesBlockLatest>
                                                </FadeInAnimation>
                                            }
                                            {projects && projects.length > 0 &&
                                                <ServicesBlockProjects>
                                                    {projects?.map((p, i) => {
                                                        return (
                                                            <FadeInAnimation key={i}>
                                                                <ServicesBlockProject>
                                                                    {p.image && <img src={p.image} alt={p.name}/>}
                                                                    {p.name &&
                                                                        <ServicesBlockProjectName>{p.name}</ServicesBlockProjectName>}
                                                                </ServicesBlockProject>
                                                            </FadeInAnimation>
                                                        )
                                                    })}
                                                </ServicesBlockProjects>}

                                        </ServicesBlockRight>
                                    </ServicesBlockAll>
                                </ServicesBlockInner>
                            </Container>
                        </ServicesBlockWrapper>
                    )
                })
            }
        </ServicesBlockList>
    )

}

export default ServicesBlock;

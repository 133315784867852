import React, {useEffect, useRef} from 'react';
import {MarqueeInner, MarqueeWord, MarqueeWrapper} from "./styled";
import gsap from "gsap";
function Marquee({word,speed}) {

    const duplicateElements = (array, times) => {
        return array.reduce((res, current) => {
            return res.concat(Array(times).fill(current));
        }, []);
    }

    const getArrayWords = () => {
        const words = word ? [word] : ['Marquee'];

        return duplicateElements(words, 10)
    }

    const MarqueeInnerRef = useRef();
    const MarqueeWordRef = useRef([]);


    useEffect(() =>{

        gsap.to(MarqueeWordRef.current, {xPercent: -100, repeat: -1, duration: speed, ease: "linear"})

        gsap.set(MarqueeInnerRef.current, {xPercent: -50});


    },[speed])

    return (
        <MarqueeWrapper>
            <MarqueeInner ref={MarqueeInnerRef}>
                {getArrayWords()?.map((marquee,idx) => {
                    return <MarqueeWord ref={el => (MarqueeWordRef.current = [...MarqueeWordRef.current, el])} key={idx}>{marquee}</MarqueeWord>
                })}
            </MarqueeInner>
        </MarqueeWrapper>
    );
}

export default Marquee;

import React from 'react';
import {WorkItemCategory, WorkItemImage, WorkItemName, WorkItemThumbnail, WorkItemWrapper} from "./styles";

function WorkItem({image, name, subname, link , fullImage}) {
    return (
        <WorkItemWrapper to={link ? link : ''}>
            {image ? <WorkItemImage fullImage={fullImage}>
                <WorkItemThumbnail>
                    <img src={image} alt={name}  />
                </WorkItemThumbnail>
            </WorkItemImage> : ''}
            {name ? <WorkItemName>{name}</WorkItemName> : ''}
            {subname ? <WorkItemCategory>{subname}</WorkItemCategory> : ''}

        </WorkItemWrapper>
    );
}


export default WorkItem;

import React from 'react'
import {ArticlesSearshWrapp, ArticlesSearshClose} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import ArticlesImgClose from '../../assets/img/articles/searsh_close.svg'
import axios from "axios"
import { token, version } from '../../global/config';

function ArticlesSearsh({SearshData, setdataAxiosPosts}) {


    const checkKeyDown = (e) => {
        if(e.target.value.length > 0){
            e.target.classList.add('entered--text');
            document.querySelector('.searsh__close').classList.add('active');
            document.querySelector('.searsh__filter').classList.add('hidden');
            
            axios.get(`https://api.storyblok.com/v2/cdn/stories?&starts_with=posts&token=${token}&search_term=${e.target.value}`).then(res =>{
                const data = res.data.stories;
                // setdataAxiosPosts(data)
                
                if(data.length > 0){
                    document.querySelector('.articles__items').classList.remove('hidden');
                    document.querySelector('.searsh__result--empty').classList.remove('active');
                    setdataAxiosPosts(data)
                    document.querySelector('.searsh__result--wrapp').classList.add('active');
                    document.querySelector('.searsh__result--wrapp .searsh__result--num >p span').innerHTML = data.length;
                    
                }else{
                    document.querySelector('.articles__items').classList.add('hidden');
                    document.querySelector('.searsh__result--wrapp .searsh__result--num >p span').innerHTML = data.length;
                    document.querySelector('.searsh__result--empty').classList.add('active'); 
                }
                
            })
        }else{
            e.target.classList.remove('entered--text');
            document.querySelector('.searsh__close').classList.remove('active');
            document.querySelector('.searsh__filter').classList.remove('hidden');
            document.querySelector('.searsh__result--wrapp').classList.remove('active');
        }
    }

    const resetSearch = (e) => {
        document.querySelector('.entered--text').value = '';
        document.querySelector('.entered--text').classList.remove('entered--text');
        e.target.closest('.searsh__close').classList.remove('active');
        document.querySelector('.searsh__filter').classList.remove('hidden');

        document.querySelector('.searsh__result--wrapp').classList.remove('active');
        document.querySelector('.articles__items').classList.remove('hidden');
        document.querySelector('.searsh__result--empty').classList.remove('active');
        axios.get(`https://api.storyblok.com/v1/cdn/stories/?version=${version}&token=${token}&starts_with=posts/`).then(res =>{
				const data = res.data.stories;
				setdataAxiosPosts(data)
                
		})
    }
    
    return (
        <>
            <Container>
                <FadeInAnimation>
                    <ArticlesSearshWrapp> 
                        <ArticlesSearshClose className='searsh__close' onClick={(e) => resetSearch(e)}> 
                            <img src={ArticlesImgClose} alt={'IMG'}/>
                        </ArticlesSearshClose>
                        <input placeholder={SearshData.placeholder} onKeyUp={(e) => checkKeyDown(e)}/>
                        <span></span>
                    </ArticlesSearshWrapp>
                </FadeInAnimation>
            </Container>
            
        </>
        
    );
}

export default ArticlesSearsh;

import styled from "styled-components";
import {COLORS} from "../../styles/Theme";

export const ScreenLoader = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: transform 1.4s;

    &.hidden{
        display: none;
    }

    &.opened{
        transform: translateY(-102%);
    }
`;

export const ScreenLoaderOpen = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: transform 1.4s, opacity 0.25s, -webkit-transform 1.4s;
    background: ${COLORS.GREEN};
    transform-origin: bottom;
    transform: translateY(102%);

    &.hidden{
        transform: translateY(0);
    }
`;
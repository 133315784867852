import React, {useRef, useEffect} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {delayForGsap} from "../../functions/constants";

const FadeInAnimation = ({
                             children,
                             wrapperElement = "div",
                             direction = null,
                             delay = 0,
                             ...props
                         }) => {
    let compRef = useRef();
    gsap.registerPlugin(ScrollTrigger);

    const {pathname} = useLocation();


    useEffect(() => {
        initGsapFades();

        //eslint-disable-next-line
    }, [compRef, delay, pathname]);

    const initGsapFades = () => {
        gsap.set(compRef.current, {
            y: 60,
            opacity: 0,
            scale: 0.95
        })
        setTimeout(() => {
            gsap.to(compRef.current, 0.5, {
                scrollTrigger: {
                    trigger: compRef.current,
                    // start: 'top 90%',
                },
                y: 0,
                scale: 1,
                opacity: 1,
                delay: delay,
                ease: 'none'
            });
        }, delayForGsap)

    }


    return (
        <ComponentFade ref={compRef} {...props}>
            {children}
        </ComponentFade>
    );


};
const ComponentFade = styled.div`
  will-change: opacity;
  backface-visibility: hidden;
  transition: none;
`;

export default FadeInAnimation;

import styled from "styled-components";
import Typography from "../../styles/Typography";
import {NavLink} from "react-router-dom";
import {device} from "../../styles/responsive";

export const BlogItemWrapper = styled(NavLink)`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 80px;

    @media ${device.tabletL} {
      margin-right: 30px;
    }

    @media ${device.mobile} {
      margin-right: 20px;
    }
  }
  &:last-child {
    padding-right: 80px;

    @media ${device.mobile} {
      padding-right: 0;
    }
    @media ${device.tabletL} {
      padding-right: 60px;
    }
    @media ${device.tablet} {
      padding-right: 40px;
    }
  }

  @media ${device.mobile} {
    width: 75vw;
  }

`;

export const BlogItemImage = styled.div`
  flex-shrink: 0;
  height: 392px;
  width: 448px;
  margin-bottom: 40px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.mobile} {
    width: 100%;
    height: 215px;
    margin-bottom: 20px;
  }
`;

export const BlogItemName = styled(Typography.H6)`
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 1.45;
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 1.6;
  }
`;

export const BlogItemCategory = styled(Typography.TextL)`
  @media ${device.mobile} {
    font-size: 14px;
  }
`;


import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import {device} from "../../styles/responsive";

export const ValuesSliderWrapper = styled.div`
  padding-top: 220px;
  padding-bottom: 220px;
  overflow: hidden;
  @media ${device.tabletL} {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  @media ${device.mobile} {
    padding-top: 90px;
    padding-bottom: 60px;
  }
`;

export const ValuesSliderInner = styled.div``;

export const ValuesSliderViewport = styled.div`
  display: flex;
`;

export const ValuesSliderItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 75%;
  &:not(:last-child){
    padding-right: 100px;
  }
  @media ${device.tabletL}{
    flex-direction: column;
    align-items: flex-start;
    width: 428px;
    min-width: 428px;
    &:not(:last-child){
      padding-right: 100px;
    }
  }
  @media ${device.mobile} {
    width: 246px;
    min-width: 336px;
    &:not(:last-child){
      padding-right: 90px;
    }

  }

`;

export const ValuesSliderLeft = styled.div`
  margin-right: 120px;
  @media (max-width: 1400px){
    margin-right: 80px;
  }
  @media ${device.tabletL} {
    margin-right: 0;
    //height: 500px;
    img{
      width: 100%;
    }
    img{
      width: 100%;
    }
  }
`;

export const ValuesSliderRight = styled.div`
  width: 45%;
  padding-right: 100px;
  @media ${device.tabletL} {
    width: 100%;
    padding-right: 100px;
  }
  @media ${device.mobile} {
      padding-right: 0px;
  }
`;

export const ValuesSliderName = styled(Typography.H3)`
  ${sharedProps};
  color: ${COLORS.BLACK};
  margin-bottom: 24px;
  word-break: break-word;
  @media ${device.tabletL} {
    font-size: 40px;
    line-height: 50px;
    margin-top: 44px;
  }
`;

export const ValuesSliderText = styled.p`
  ${sharedProps};
  color: ${COLORS.BLACK2};
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.02em;
`;

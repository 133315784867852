import styled from "styled-components";
import sharedProps from "../../styles/sharedProps";
import {COLORS, TRANSITIONS} from "../../styles/Theme";

export const ArticlesSearshWrapp = styled.div`
  margin-top: -60px;
  margin-bottom: 40px;
  position: relative;

  > span{
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0px;
    background: ${COLORS.BLACK4};
    transition: ${TRANSITIONS.FAST};
}

  input{
    width: 100%;
    border: 0;
    ${sharedProps};
    color: ${COLORS.SECONDARYBLACK};
    font-size: 34px;
    letter-spacing: -0.02em;
    line-height: 1.5;
    outline: none;
    transition: ${TRANSITIONS.FAST};
    padding-bottom: 10px;
    height: 63px;
    background: ${COLORS.WHITE5};
    position: relative;

    &:focus + span {
        background: ${COLORS.BLACK2};
        
    }
    
    &::placeholder {
    color: ${COLORS.BLACK4};
    }
    
    &.entered--text{
        & + span{
            background: ${COLORS.PURPLE}; 
        }
    }
  }
  }
`;

export const ArticlesSearshClose = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: 0.35s;
  opacity: 0;
  visibility: hidden;
  
  &.active{
    opacity: 1;
    visibility: visible;
  }
`;

import React, {useEffect, useState} from 'react'
import styled from "styled-components";
import Intro from "../../Components/Intro/Intro";
import VideoBanner from "../../Components/videoBanner/videoBanner";
import VideoBannerImage from '../../assets/img/bannerAbout.png'
import Tell from "../../Components/Tell/Tell";
import Who from "../../Components/Who/Who";
import ClientLogos from "../../Components/ClientsLogos/ClientLogos";
import Principles from "../../Components/Principles/Principles";
import Produce from "../../Components/Produce/Produce";
import axios from "axios";
import { token, version } from '../../global/config';

const About = (props) => {

    const whoData = [
        {
            image: require('../../assets/img/who/1.png'),
            name: 'Prashant koli',
            position: 'The Designer . Co-founder',
            text: 'Be pro-active when it suits you and your needs ... not.',
        },
        {
            image: require('../../assets/img/who/2.png'),
            name: 'Varun Singh',
            position: 'The Everything else . Co-founder',
            text: 'Why be serious when god has given you some teeth and a beautiful smile!',
        },

    ]

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/about?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])


    return (
        <>  <IntroAbout>
                <Intro title={dataAxios?.length ? dataAxios?.[0].title : 'Passionate \n driven journey'} text={dataAxios?.length ? dataAxios?.[0].label : 'About'}/>
            </IntroAbout>
            <VideoBanner VideoBannerImage={ dataAxios?.length ? dataAxios?.[1].image : VideoBannerImage} hiddenCursor={true}/>
            <Produce/>
            <Principles/>
            <ClientLogos/>
            <Who whoData={whoData}/>
            <Tell tellData={dataAxios?.length && dataAxios[6]}/>
        </>
    );
}

const IntroAbout = styled.div`
    h1{
        max-width: 680px;
        width: 100%
    }

`;

export default About;

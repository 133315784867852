import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import {device} from "../../styles/responsive";

export const ServicesWrapper = styled.div`
`;

export const ServicesInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 170px 0;
  border-bottom: 1px solid ${COLORS.BLACK5};
  @media ${device.tabletL}{
    padding-top: 180px;
    padding-bottom: 110px;
    flex-direction: column;
  }
  @media ${device.tablet} {
    padding-top: 140px;
    padding-bottom: 100px;
  }
`;

export const ServicesLeft =  styled.div`
  width: 50%;
  @media ${device.tabletL} {
    width: 100%
  }
`;

export const ServicesList = styled.div`
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 13px;
  @media ${device.tabletL} {
    width: 100%;
    gap: 15px 40px;
  }
  @media ${device.mobile} {
    padding-left: 33px;
    grid-template-columns: 1fr;
  }
`;

export const ServicesItem =  styled(Typography.TextXXL)`
  display: flex;
  align-items: center;
  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const ServicesTitle =  styled(Typography.H3)`
  margin-bottom: 42px;
  b,span{
    font-weight: normal;
    color: ${COLORS.ORANGE};
    ${sharedProps};
  }
  @media ${device.tabletL} {
    margin-bottom: 48px;
  }
  @media ${device.tablet} {
    margin-bottom: 60px;
  }
`;

export const ServicesNew = styled.span`
  display: block;
  margin-left: 30px;
  padding: 6px 14px;
  text-transform: lowercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  background: ${COLORS.PURPLE};
  color: ${COLORS.WHITE};
  border-radius: 50px;
  @media ${device.mobile} {
    margin-left: 20px;
  }
  animation: pulsing 3s infinite linear;
  
  @keyframes pulsing {
    0%{
      transform: scale(1);
    }
    50%{
      transform: scale(1.2);

    }
    100%{
      transform: scale(1);
    }
  }
`

export const ServicesButton =  styled.div`
  @media ${device.tabletL} {
    display: none;
  }
`;

export const ServicesButtonMob =  styled.div`
  display: none;
  margin-top: 88px;
  @media ${device.tabletL} {
    display: block;
  }
  @media ${device.mobile} {
    margin-top: 66px;
  }

`;



import styled from "styled-components";
import {device} from "../../styles/responsive";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";

export const BlogHomeWrapper = styled.div`
  position: relative;
  z-index: 4;
  overflow: hidden;
  padding-top: 300px;
  @media ${device.tabletL} {
    padding-top: 120px;
  }
  @media ${device.mobile} {
    padding-top: 100px;

  }
`;

export const BlogHomeTitle = styled(Typography.H3)`
  b {
    display: block;
    font-weight: normal;
    color: ${COLORS.GREEN};
    ${sharedProps};
  }

  @media ${device.mobile} {
    text-align: center;
  }
`;

export const BlogHomeHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  justify-content: space-between;
  row-gap: 30px;
  margin-bottom: 60px;
  @media ${device.mobile} {
    margin-bottom: 40px;
    flex-direction: column;
  }
`;

export const BlogHomeHeaderButton = styled.div`
  
`;


export const BlogHomeList = styled.div`
  display: flex;
  //width: fit-content;
  margin-left: auto;
  max-width: 1560px;
  margin-right: auto;
  padding-right: 80px;
  padding-left: 80px;
  @media ${device.tabletL} {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media ${device.tablet} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${device.mobile} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const BlogHomeOverflow = styled.div`
  display: flex;
  overflow: scroll;
  transition: .22s;

  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-thumb{
    height: 0;
    opacity: 0 !important;
    display: none;
  }

  @media ${device.mobile} {
    overflow: auto;
  }

`;


import {
    HeaderBurger,
    HeaderInner,
    HeaderLine,
    HeaderLogo,
    HeaderRight,
    HeaderWrapper,
    MenuInner, MenuNav, MenuNavLink, MenuRight, MenuRightItem, MenuSocial, MenuSocialItem,
    MenuWrapper
} from "./styles";
import Typography from "../../styles/Typography";
import LogoImg from '../../assets/img/logo.svg';
import {useEffect, useRef, useState} from "react";
import gsap from 'gsap'
import {useNavigate} from "react-router-dom";
import {Swiper} from "swiper/react";
import {SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import axios from "axios"
import { token, version } from "../../global/config";

const Header = ({headerRef, WorksItemsData}) => {

    const navigate = useNavigate();

    const [burgerClick, setBurgerClick] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [index, setIndex] = useState(null);
    const timeline = useRef(null);
    const navRef = useRef([]);
    const socialRef = useRef([]);
    const leftPartRef = useRef();
    const RightRef = useRef([]);
    const [height, setHeight] = useState(0);

    const menuNav = [
        {
            name: 'About',
            link: '/about',
            image: require('../../assets/img/cultures.png')
        },
        {
            name: 'Culture',
            link: '/culture',
            image: require('../../assets/img/cultures.png')

        },

        {
            name: 'Projects',
            link: '/works',
            image: require('../../assets/img/cultures.png'),
            slideShow: true,
        },
        {
            name: 'Services',
            link: '/services',
            image: require('../../assets/img/cultures.png')

        },
        {
            name: 'Testimonials',
            link: '/testimonials',
            image: require('../../assets/img/values1.png')
        },
        {
            name: 'Contact us',
            link: '/contacts',
            image: require('../../assets/img/cultures.png')

        },

    ]

    const menuSocial = [
        {
            name: 'Facebook',
            link: '/'
        },
        {
            name: 'Behance',
            link: '/'
        },
        {
            name: 'Instagram',
            link: '/'
        },
        {
            name: 'Dribbble',
            link: '/'
        },
        {
            name: 'Linkedin',
            link: '/'
        },

    ]

    useEffect(() => {
        timeline.current =
            new gsap.timeline({paused: true})
                .fromTo(navRef.current, 0.45, {
                    autoAlpha: 0,
                    y: 15,
                }, {
                    stagger: 0.2,
                    y: 0,
                    autoAlpha: 1

                })
                .fromTo(RightRef.current, 0.45, {
                    autoAlpha: 0,
                    y: 100,
                }, {
                    autoAlpha: 1,
                    y: 1,
                }, '-=0.2')
                .fromTo(socialRef.current, 0.3, {
                    autoAlpha: 0,
                    y: 10,

                }, {
                    stagger: 0.05,
                    autoAlpha: 1,
                    y: 0
                }, "+=0.1")

    }, []);


    const handleBurgerClick = () => {
        setBurgerClick(!burgerClick);
        if (!burgerClick) {
            setHeight(leftPartRef.current?.getBoundingClientRect().height)
            document.body.style.overflow = 'hidden';
            setOpenMenu(true)
            timeline.current.timeScale(1).play();
        } else {
            document.body.style.overflow = 'unset';

            timeline.current.timeScale(1.5).reverse();

            timeline.current.eventCallback('onReverseComplete', () => {
                setOpenMenu(false)

            })
        }


    }

    const handleOnMouseOver = (idx) => {
        setIndex(idx);
        setHeight(leftPartRef.current?.getBoundingClientRect().height)

    }

    const handleClosePopup = (link) => {
        setBurgerClick(!burgerClick);
        document.body.style.overflow = 'unset';
        timeline.current.timeScale(1.5).reverse();
        timeline.current.eventCallback('onReverseComplete', () => {
            setOpenMenu(false)
            
            document.querySelector('.screenLoad').classList.remove('hidden');
            setTimeout(() => {
                document.querySelector('.screenLoad--open').classList.add('hidden');
            }, "0");
            
            setTimeout(() => {
                navigate(link, {replace: true});
                document.querySelector('.screenLoad').classList.add('opened');
            }, "1400");

            setTimeout(() => {
                document.querySelector('.screenLoad').classList.add('hidden');
                document.querySelector('.screenLoad--open').classList.remove('hidden');
                document.querySelector('.screenLoad').classList.remove('opened');
            }, "3450")
            

        })

    }

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/header?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[]);


    return (
        <>
            <HeaderWrapper ref={headerRef}>
                <HeaderInner>
                    <HeaderLogo to={'/'}>
                        <img src={dataAxios?.length ? dataAxios?.[0].logoImage : LogoImg} alt=""/>
                    </HeaderLogo>
                    <HeaderRight onClick={() => handleBurgerClick()}>
                        <Typography.TextXL>
                            {!burgerClick ? 'Menu' : 'Close'}
                        </Typography.TextXL>
                        <HeaderBurger active={burgerClick}>
                            <HeaderLine></HeaderLine>
                            <HeaderLine></HeaderLine>
                        </HeaderBurger>
                    </HeaderRight>
                </HeaderInner>
            </HeaderWrapper>
            <MenuWrapper active={openMenu}>
                <MenuInner>
                    <MenuNav ref={leftPartRef}>
                            {dataAxios?.length ? dataAxios?.[0].menu.content.map((m, key) => {
                                    return (
                                        <MenuNavLink className={'cursor-link'} onClick={() => handleClosePopup(m.attrs.body[0].link)}
                                                     onMouseOver={() => handleOnMouseOver(key)}
                                                     ref={el => (navRef.current = [...navRef.current, el])} key={key}>
                                            <span data-text={m.attrs.body[0].name}> {m.attrs.body[0].name}</span>
                                        </MenuNavLink>
                                    )
                                }) : 
                                menuNav.map((m, key) => {
                                    return (
                                        <MenuNavLink className={'cursor-link'} onClick={() => handleClosePopup(m.link)}
                                                     onMouseOver={() => handleOnMouseOver(key)}
                                                     ref={el => (navRef.current = [...navRef.current, el])} key={key}>
                                            <span data-text={m.name}> {m.name}</span>
                                        </MenuNavLink>
                                    )
                                })
                            }
                    </MenuNav>
                    <MenuRight active={burgerClick} style={{height: height + 'px'}} ref={RightRef}>
                        {dataAxios?.length && dataAxios?.[0].menu.content?.map((m, key) => {
                            return (
                                <MenuRightItem key={key} active={index === key} className={m.attrs.body[0].slideShow && 'slider'}>
                                    <img src={m.attrs.body[0].image} alt={m.attrs.body[0].name}/>
                                    {m.attrs.body[0].slideShow &&
                                        <Swiper
                                        slidesPerView={1}
                                        loop={true}
                                        speed={0}
                                        autoplay={{
                                            delay: 1000,
                                        }}
                                        modules={[Autoplay]}
                                        >
                                            {m.attrs.body[0].slideShow && dataAxios?.[0].sliderImages.content.map((m,idx) =>{
                                                return (
                                                    <SwiperSlide key={idx}><img src={m.attrs.body[0].image} alt=""/></SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                    }
                                </MenuRightItem>
                            )
                        })}
                    </MenuRight>
                </MenuInner>
                <MenuSocial className="menu__social">

                        {dataAxios?.length ? dataAxios?.[0].social.content.map((m, idx) => {
                            return (
                                <MenuSocialItem ref={el => (socialRef.current = [...socialRef.current, el])} to={m.attrs.body[0].link}
                                                key={idx}>
                                    {m.attrs.body[0].name}
                                </MenuSocialItem>
                            )
                            }) : 
                            menuSocial?.map((m, idx) => {
                                return (
                                    <MenuSocialItem ref={el => (socialRef.current = [...socialRef.current, el])} to={m.link}
                                                    key={idx}>
                                        {m.name}
                                    </MenuSocialItem>
                                )
                            })
                        }
                </MenuSocial>
            </MenuWrapper>
        </>
    )
}

export default Header

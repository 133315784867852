import React, {useEffect, useState} from 'react'
import {WhoImage, WhoInner, WhoItem, WhoList, WhoName, WhoPosition, WhoText, WhoTitle, WhoWrapper} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import axios from "axios"
import { token, version } from '../../global/config';

function Who({whoData}) {

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/about?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <WhoWrapper>
            <Container>
                <WhoInner>
                    <FadeInAnimation>
                        <WhoTitle> {dataAxios?.length ? dataAxios?.[5].titleThinLineFirst : 'Who is'}
                        <b> {dataAxios?.length ? dataAxios?.[5].titleBoldLineFirst : 'Who isbehind'} </b> 
                        <br/> 
                        {dataAxios?.length ? dataAxios?.[5].titleThinLineSecond : 'Ugna?'}</WhoTitle>
                    </FadeInAnimation>
                    <WhoList>
                        {dataAxios?.length ? dataAxios?.[5].items.map((item, idx) => {
                                const {image, name, position, text} = item;
                                return (
                                    <FadeInAnimation key={idx}>
                                        <WhoItem>
                                            {image && <WhoImage>
                                                <img src={image} alt={name}/>
                                            </WhoImage>}
                                            {name && <WhoName>{name}</WhoName>}
                                            {position && <WhoPosition>{position}</WhoPosition>}
                                            {text && <WhoText>{text}</WhoText>}
                                        </WhoItem>
                                    </FadeInAnimation>
                                )
                            }) : 
                            whoData?.map((item, idx) => {
                                const {image, name, position, text} = item;
                                return (
                                    <FadeInAnimation key={idx}>
                                        <WhoItem>
                                            {image && <WhoImage>
                                                <img src={image} alt={name}/>
                                            </WhoImage>}
                                            {name && <WhoName>{name}</WhoName>}
                                            {position && <WhoPosition>{position}</WhoPosition>}
                                            {text && <WhoText>{text}</WhoText>}
                                        </WhoItem>
                                    </FadeInAnimation>
                                )
                            })
                        }
                    </WhoList>
                </WhoInner>
            </Container>
        </WhoWrapper>
    );
}

export default Who;

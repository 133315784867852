import {css} from "styled-components";

const sharedProps = ({
                         mt,
                         mb,
                         px,
                         color,
                         center,
                         fontStyle,
                         cssUnique,
                         backgroundColor,
                     }) => css`
  margin-top: ${mt};
  color: ${color};
  font-style: ${fontStyle};
  background-color: ${backgroundColor};
  ${cssUnique};
  ${px && `padding-left: ${px}; padding-right: ${px};`}
  ${mb && `margin-bottom: ${mb};`}
  ${center && "text-align: center;"}
`;

export default sharedProps;

import styled from "styled-components";
import Typography from "../../styles/Typography";
import {device} from "../../styles/responsive";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";

export const ServicesBlockList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ServicesBlockInner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 170px;
  padding-bottom: 100px;
  ${sharedProps};

  @media ${device.tabletL} {
    margin-top: 80px;
  }

`;
export const ServicesBlockWrapper = styled.div`
`;



export const ServicesBlockTitle = styled(Typography.H3)`
  max-width: 78%;
  ${sharedProps};
  font-weight: 400;
  color: ${COLORS.BLACK};

  @media ${device.mobile} {
    font-size: 34px;
    max-width: 100%;
  }
`;
export const ServicesBlockMax = styled.div`
  width: 60%;
  margin-left: auto;
  margin-bottom: 60px;
  @media ${device.tabletL} {
    width: 100%;
    margin-bottom: 50px;
  }

  @media ${device.mobile} {
    margin-bottom: 40px;
  }
`;

export const ServicesBlockAll = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.tabletL} {
    flex-direction: column;
    row-gap: 77px;
  }

  @media ${device.mobile} {
    row-gap: 40px;
  }
`;

export const ServicesBlockLeft = styled.div`
  width: 40%;
  @media ${device.tabletL} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
  >div{
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 24px;
      @media ${device.tabletL} {
        margin-bottom: 20px;
      }
      @media ${device.mobile} {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ServicesBlockLi = styled(Typography.H5)`

  ${sharedProps};
  color: ${COLORS.BLACK2};
  @media ${device.tabletL} {
    font-size: 28px;
    line-height: 42px;
  }
  @media ${device.mobile} {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const ServicesBlockText = styled(Typography.H6)`
  ${sharedProps};
  color: ${COLORS.BLACK2};
  margin-bottom: 110px;
  width: 78%;
  white-space: pre-line;
  @media ${device.tabletL} {
    width: 100%;
    margin-bottom: 80px;
  }

  @media ${device.mobile} {
    margin-bottom: 50px;
  }
`;

export const ServicesBlockProjects = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  @media ${device.mobile} {
    gap: 20px;
  }
`;

export const ServicesBlockProject = styled.div``;

export const ServicesBlockLatest = styled(Typography.TextXXL)`
  margin-bottom: 28px;
`;

export const ServicesBlockProjectName = styled(Typography.H6)`
  margin-top: 40px;
  text-align: center;
  ${sharedProps};
  color: ${COLORS.BLACK};
`;

export const ServicesBlockRight = styled.div`
  width: 60%;
  @media ${device.tabletL} {
    width: 100%;
  }
`;

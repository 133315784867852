import React, { useRef} from 'react';
import {RecentList, RecentOverflow, Recentmarquee, RecentWrapper} from "../Recent/styled";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import BlogItem from "../BlogItem/BlogItem";
import Container from "../../Components/Container/Container";
import {useDraggable} from "react-use-draggable-scroll";
import Marquee from "../Marquee/Marquee";

function Recent({BlogItemsData}) {
    const RecentRef = useRef();

    const { events } = useDraggable(RecentRef , {
        applyRubberBandEffect: true,
        decayRate: 0.96
    });

    return (
        <RecentWrapper>
            <FadeInAnimation>
                <Recentmarquee>
                    <Marquee speed={3} word={BlogItemsData?.marquee ? BlogItemsData.marquee : 'Reeeeelated'}/>
                </Recentmarquee>
            </FadeInAnimation>
            <RecentOverflow ref={RecentRef} {...events}>
                <Container>
                    <RecentList >
                        {BlogItemsData?.items ? BlogItemsData.items.slice(0, 2).map((blog, i) => {
                                return (
                                    <BlogItem key={i}{...blog}/>
                                )
                            })
                            
                            :
                            BlogItemsData?.map((blog, i) => {
                                return (
                                    <BlogItem key={i}{...blog}/>
                                )
                            })
                        }
                    </RecentList>
                </Container>
            </RecentOverflow>
        </RecentWrapper>

    );
}

export default Recent;

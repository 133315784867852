import Intro from "../../Components/Intro/Intro";
import WorkFilter from "../../Components/WorkFilter/WorkFilter";
import styled from "styled-components";
import WorkItem from "../../Components/WorkItem/WorkItem";
import Container from "../../Components/Container/Container";
import {useEffect, useRef, useState} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import {device} from "../../styles/responsive";
import axios from "axios";
import { token, version } from "../../global/config";

const Work = ({WorksItemsData}) => {

    const data = {
        ease: 0.1,
        current: 0,
        previous: 0,
        rounded: 0
    };

    const size = useWindowSize();

    const scrollContainer = useRef();

    let request;

    const skewScrolling = () => {
        //Set Current to the scroll position amount
        data.current = window.scrollY
        // Set Previous to the scroll previous position
        data.previous += (data.current - data.previous) * data.ease;
        // Set rounded to
        data.rounded = Math.round(data.previous * 100) / 100;

        // Difference between
        const difference = data.current - data.rounded;
        const acceleration = difference / size.width;
        const velocity = +acceleration;
        const skew = velocity * 15;

        //Assign skew and smooth scrolling to the scroll container

        if (scrollContainer.current) {
            scrollContainer.current.style.transform = `skewY(${skew}deg)`;
            request = requestAnimationFrame(() => skewScrolling());
        } else {
            cancelAnimationFrame(request);
        }

    };

    useEffect(() => {
        if (size.width >= 1279) {
            requestAnimationFrame(() => {
                skewScrolling();
            });

        } else {
            cancelAnimationFrame(request);
        }
        return () => cancelAnimationFrame(request);
        // eslint-disable-next-line
    }, []);

    const defaultTabName = 'All';

    const [activeTab, setActiveTab] = useState(defaultTabName);

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/works?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <>
            <Intro title={dataAxios?.length ? dataAxios?.[0].title : 'Projects!'} isHome={false}></Intro>
            <WorkFilter WorksItemsData={dataAxios?.length ? dataAxios?.[1].items : WorksItemsData} activeTab={activeTab} setActiveTab={setActiveTab} defaultTabName={defaultTabName}/>
            <WorkWrapper ref={scrollContainer}>
                <Container>
                    <WorkList>
                        {dataAxios?.length ? dataAxios?.[1].items.map((work, idx) => {
                                const {image} = work;
                                return (
                                    <FadeInAnimation key={idx}>
                                        <WorkItem image={image} fullImage></WorkItem>
                                    </FadeInAnimation>
                                )
                            }) : 
                            WorksItemsData?.map((work, idx) => {
                                const {image} = work;
                                return (
                                    <FadeInAnimation key={idx}>
                                        <WorkItem image={image} fullImage></WorkItem>
                                    </FadeInAnimation>
                                )
                            })
                        }
                    </WorkList>
                </Container>
            </WorkWrapper>
        </>
    )
}

const WorkWrapper = styled.div`
  margin-bottom: 380px;
  @media ${device.tabletL} {
    margin-bottom: 220px;
  }
  @media ${device.mobile} {
    margin-bottom: 80px;
  }
`;

const WorkList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  
  @media ${device.tabletL}{
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.tablet}{
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default Work

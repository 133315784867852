import styled from "styled-components";
import Typography from "../../styles/Typography";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const StrivingWrapper = styled.div`
  padding-top: 60px;
  padding-bottom: 242px;
  @media ${device.tabletL} {
    padding-bottom: 140px;
  }
  @media ${device.mobile} {
    padding-bottom: 90px;
  }

`;

export const StrivingInner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StrivingImage = styled.div`
  width: 100%;
  margin-bottom: 120px;
  @media ${device.mobile} {
    margin-bottom: 50px;
  }
`;

export const StrivingList = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${device.tabletL} {
    display: flex;
    flex-direction: column;
  }

`;

export const StrivingLeft = styled(Typography.H3)`
  width: 32%;
  ${sharedProps};
  color: ${COLORS.BLACK2};
  @media ${device.tabletL} {
    width: 100%;
    margin-bottom: 40px;
  }
  
  @media ${device.mobile} {
    margin-bottom: 30px;
  }
`;

export const StrivingRight = styled(Typography.H6)`
  width: 60%;
  ${sharedProps};
  color: ${COLORS.BLACK2};
  @media ${device.tabletL} {
    width: 100%;
  }

`;

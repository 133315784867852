import styled from "styled-components";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const MarqueeWrapper = styled.div`
  overflow: hidden;
  ${props => !props.cssUnique}{
    padding-bottom: 270px;
    @media ${device.tabletL} {
      padding-bottom: 210px;
    }
    @media ${device.mobile} {
      padding-bottom: 120px;
    }
    }
`;

export const MarqueeWord = styled.div`
  display: flex;
  align-items: center;
  font-size: 100px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: ${COLORS.BLACK};
  ${sharedProps};
  font-smooth: always;
  white-space: nowrap;
  img{
    width: 113px;
    height: 113px;
    margin-right: 60px;
    margin-left: 60px;
    @media ${device.mobile}{
      width: 60px;
      height: 60px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  @media ${device.tabletL}{
    font-size: 80px;
    line-height: 92px;
  }
  @media ${device.mobile}{
    font-size:40px;
    line-height: 50px;

  }
  @media ${device.mobile} {
    font-size: 56px;
    line-height: 70px;
  }

`;

import React from 'react';
import {ArticlesItemCategory, ArticlesItemImage, ArticlesItemName, ArticlesItemThumbnail, ArticlesItemWrapper} from "./styles";

function ArticlesItem({image, name, subname, link, category, fullImage}) {
    return (
        <ArticlesItemWrapper to={link ? link : ''} data-category={category}>
            {image ? <ArticlesItemImage fullImage={fullImage}>
                <ArticlesItemThumbnail>
                    <img src={image} alt={name}  />
                </ArticlesItemThumbnail>
            </ArticlesItemImage> : ''}
            {name ? <ArticlesItemName>{name}</ArticlesItemName> : ''}
            {subname ? <ArticlesItemCategory>{subname}</ArticlesItemCategory> : ''}

        </ArticlesItemWrapper>
    );
}


export default ArticlesItem;
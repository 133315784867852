import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const TestimonialsItemWrapper = styled.div`
  //padding-bottom: 200px;
`;

export const TestimonialsItemList = styled.div`
  display: flex;
  flex-direction: column;
  >div{
    &.testimonial--order1{
      order: 1;
      opacity: 1;
    }
    &.testimonial--order2{
      order: 2;
    }
    &:not(:first-child) {
      border-top: 1px solid ${COLORS.BLACK5};
    }
  }

`;

export const TestimonialsItemInner = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 60px;
  padding-top: 60px;


  @media ${device.tabletL}{
    flex-direction: column;
  }
  @media ${device.mobile} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .testimonials__left{
    position: relative;
  }

  .testimonials__left--pic{
    position: absolute;
    transition: .1s;
    transform: translate(-50%, -50%);

    img{
      max-width: initial;
    }
  }

  .testimonials__left--pic--hidden{
    opacity: 0;
    visibility: hidden;
  }
`;

export const TestimonialsItemLeft = styled.div`
  position: relative;
  z-index: 2;
  @media ${device.tabletL} {
    margin-bottom: 40px;
  }
  @media ${device.mobile} {
    margin-bottom: 30px;
  }
`;

export const TestimonialsItemName = styled(Typography.H6)`
  margin-bottom: 4px;
`;

export const TestimonialsItemYear = styled(Typography.TextR)``;

export const TestimonialsItemRight = styled.div`
  width: 60%;
  position: relative;
  @media ${device.tabletL}{
    width: 100%;
  }
`;

export const TestimonialsItemText = styled(Typography.H4)`
  @media ${device.tabletL} {
    font-size: 34px;
  }
  @media ${device.mobile} {
    font-size: 20px;
  }
`;


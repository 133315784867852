import styled from "styled-components";
import {device} from "../../styles/responsive";

export const ContainerMiddle = styled.div`
    max-width: 1820px;
    padding-left: 80px;
    //padding-right: 80px;
    margin-left: auto;
    @media ${device.tabletL}{
      padding-left: 60px;
      padding-right: 60px;
    }
    @media ${device.mobile}{
      padding-left: 20px;
      padding-right: 20px;
    }
  
`;

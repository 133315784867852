import styled from "styled-components";
import Typography from "../../styles/Typography";
import sharedProps from "../../styles/sharedProps";
import {COLORS, TRANSITIONS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const BlogContentWrapper = styled.div`
  margin-bottom: 140px;
  @media ${device.mobile} {
    margin-bottom: 100px;
  }
`;

export const BlogContentInner = styled.div`
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const BlogContentLeft = styled.div`
  position: sticky;
  width: 23%;
  @media ${device.tablet} {
    display: none;
  }
`;

export const BlogContentRight = styled.div`
  width: 62%;
  position: relative;
  overflow: hidden;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const BlogContentAll = styled.div``;

export const BlogContentSummary = styled(Typography.H5)`
  font-weight: 500;
  margin-bottom: 30px;
`;

export const BlogContentList = styled.div`
  counter-reset: section;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 42px;
  ${sharedProps};
  border-bottom: 1px solid ${COLORS.BLACK5};

`;

export const BlogContentItem = styled(Typography.TextL)`
  cursor: pointer;
  font-weight: 500;
  ${sharedProps};
  color: ${COLORS.BLACK4};
  position: relative;
  &:not(:last-child){
    margin-bottom: 16px;
  }
  &:before{
    counter-increment: section;
    content: counter(section) ".";
    margin-right: 14px;
  }
  &:after{
    //content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 1%;
    height: 1px;
    background: ${COLORS.BLACK};
    z-index: 2;
    opacity: 0;
  }
  > span{
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 1%;
    height: 1px;
    background: ${COLORS.BLACK};
    z-index: 2;
    opacity: 0;
    transition: .35s;
  }
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
  transition: ${TRANSITIONS.FAST};
  ${props => !props.active}{
    color: ${COLORS.BLACK};
    border-color: ${COLORS.BLACK5};
    > span{
      opacity: 1;
    }
  }

`;

export const BlogContentBottom = styled.div`
  padding-top: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 400;
  img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
export const BlogContentAuthor = styled.div`
  margin-right: auto;
  margin-left: 14px;
`;

export const BlogContentPart = styled.div`
  padding-bottom: 100px;
  @media ${device.tabletL} {
    padding-bottom: 50px;
  }

  > *:not(:last-child){
    margin-bottom: 100px;
    @media ${device.tablet} {
      margin-bottom: 50px;
    }
  }

  > h3:not(:last-child){
    margin-bottom: 30px;
    @media ${device.tablet} {
      margin-bottom: 20px;
    }
  }
`;

export const BlogContentPartTitle = styled(Typography.H3)`
  display: inline-block;
  font-weight: 500;
  width: 78%;
  @media ${device.tabletL} {
    width: 90%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const BlogContentPartParags = styled.div`
  width: 78%;
  display: inline-block;
  @media ${device.tablet} {
    width: 100%;
  }

  p{
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: -0.02em;
    color: ${COLORS.SECONDARYBLACK};

    @media ${device.tabletL} {
      font-size: 24px;
      line-height: 38px;
    }

    @media ${device.mobile} {
      font-size: 20px;
      line-height: 32px;
    }
  }

  p:not(:last-child){
    margin-bottom: 44px;
    @media ${device.tabletL} {
      margin-bottom: 36px;
    }

    @media ${device.mobile} {
      margin-bottom: 28px;
    }
  }
`;

export const BlogContentPartImageFull = styled.div`
  img{
    width: 100%;
  }
`;

export const BlogContentPartImageTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 25px;

  @media ${device.tabletL} {
    gap: 16px;
  }
`;

export const BlogContentImage = styled.div`
  img{
    width: 100%;
    height: 600px;
    object-fit: cover;

    @media ${device.tabletL} {
      height: 362px;
    }

    @media ${device.mobile} {
      height: 170px;
    }
  }
`;




import {useEffect, useState} from "react";
import {ArticleFilterCount, ArticleFilterItem, ArticleFilterList, ArticleFilterWrapper} from "./styled";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import Container from "../Container/Container";
import {token, version} from "../../global/config";
import axios from "axios";

const ArticlesFilter = ({activeTab, setActiveTab, defaultTabName, setdataAxiosPosts}) => {
	
	const [dataAxiosTags, setDataAxiosTags] = useState([]);

	useEffect(() => {
		axios.get(`https://api.storyblok.com/v1/cdn/tags/?version=${version}&token=${token}&starts_with=posts/`).then(res => {
			const data = res.data.tags;
			setDataAxiosTags(data);
            
		})

		//eslint-disable-next-line
	}, []);

	const handleClick = (tab) => {
		setActiveTab(tab);
		if(tab !== 'All'){
			axios.get(`https://api.storyblok.com/v2/cdn/stories?starts_with=posts&with_tag=${tab}&token=ANISbYszVogShbT3mIAXnwtt`).then(res => {
				const data = res.data.stories;
				setdataAxiosPosts(data)
			})
		}
		else{
			axios.get(`https://api.storyblok.com/v1/cdn/stories/?version=${version}&token=${token}&starts_with=posts/`).then(res =>{
				const data = res.data.stories;
				setdataAxiosPosts(data)
                
			})
            
		}

	}
	return (<ArticleFilterWrapper className='searsh__filter'>
		<Container mobileOff={true}>
			<FadeInAnimation>
				<ArticleFilterList>
					<ArticleFilterItem onClick={() => {
						handleClick(defaultTabName)
					}}
					                   active={activeTab === defaultTabName}>
						<span data-text={defaultTabName}>{defaultTabName}</span>

						<ArticleFilterCount>
							{dataAxiosTags && dataAxiosTags.reduce(function (previousValue, currentValue) {
								return previousValue + currentValue.taggings_count;
							}, 0)

							}</ArticleFilterCount>


					</ArticleFilterItem>
					{dataAxiosTags && dataAxiosTags.map((m, i) => {
						return <ArticleFilterItem active={activeTab === m.name} key={i}
						                          onClick={() => handleClick(m.name)}>
							<span data-text={m.name}>{m.name}</span>

							<ArticleFilterCount> <span data-text={m.taggings_count <= 10 ? '0' + m.taggings_count : m.taggings_count}>{m.taggings_count <= 10 ? '0' + m.taggings_count : m.taggings_count}</span></ArticleFilterCount>
						</ArticleFilterItem>
					})}
				</ArticleFilterList>
			</FadeInAnimation>
		</Container>
	</ArticleFilterWrapper>);
}

export default ArticlesFilter;

import React, {useEffect, useState} from "react";
import {
    CookiesWrapper,
    CookiesPanel,
    CookiesPanelLeft,
    CookiesPanelLeftImg,
    CookiesPanelLeftText,
    CookiesPanelRight,
    CookiesPanelRightText,
    CookiesPanelLeftTextTitle,
    CookiesPanelLeftTextParag,
    CookiesModal,
    CookiesModalHeader,
    CookiesModalHeaderTitle,
    CookiesModalHeaderClose,
    CookiesModalContent,
    CookiesModalContentDesc,
    CookiesModalContentTitle,
    CookiesModalContentItems,
    CookiesModalContentItem,
    CookiesModalContentItemLeft,
    CookiesModalContentItemLeftName,
    CookiesModalContentItemLeftText,
    CookiesModalContentItemRight,
    CookiesModalFooter,
    CookiesModalFooterLeft,
    CookiesModalFooterLeftLinks,
    CookiesModalFooterLeftLink,
    CookiesModalFooterRight,
    CookiesPanelRightButton,
    CookiesModalFooterRightConfirm,
    CookiesModalFooterRightReject,
    ContainerCoolies
} from "./styles";
import Container from "../Container/Container";
import ButtonLow from '../ButtonLow/ButtonLow';
import axios from "axios";
import { token, version } from "../../global/config";

function Cookies({CookiesPanelData}) {

    window.onload = function() {
        document.querySelector('.cookies__panel').classList.add('show');
    };
   
    const cookiesClosed = (e) => {
        e.preventDefault();
        localStorage.setItem('cookiesConfirmed', true);
        e.target.closest('.cookies--wrapp').classList.add('cookies--hidden');
    };

    const cookiesUse = (e) => {
        e.preventDefault();
        e.target.closest('.cookies--wrapp').classList.add('cookies--use');
    };

    const swiperReject = (e) => {
        e.preventDefault();
        
        document.querySelectorAll('.switchInput').forEach((element) => {
            if(!element.classList.contains('switch--activeAlwais')){
                element.checked = false
            } 
        })

        setTimeout(function() {
            e.target.closest('.cookies--wrapp').classList.add('cookies--hidden');
            localStorage.setItem('cookiesConfirmed', true);
        }, 1500);
    };

    const сookiesModalClosed = (e) => {
        e.preventDefault();
        e.target.closest('.cookies--wrapp').classList.remove('cookies--use');
    };

    const showDescription = (e) => {
        e.preventDefault();
        let getTargetParent = e.target.closest('.modal__item');

        if(getTargetParent.classList.contains('opened')){
            getTargetParent.querySelector('.content-accordion').style.height = 0 + 'px';
            getTargetParent.classList.remove('opened');
        }else{
            getTargetParent.querySelector('.content-accordion').style.height = getTargetParent.querySelector('.content-accordion p').offsetHeight + 'px';
            getTargetParent.classList.add('opened');
        }
    };

    
    const [dataAxios , setDataAxios] = useState([]);
    
    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/cookies?cv=1672305733&token=${token}&version=${version}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[]);


    return (
        <CookiesWrapper className='cookies--wrapp'>    

            <CookiesModal className='cookies__modal'>
                <CookiesModalHeader>
                    <ContainerCoolies>
                        <CookiesModalHeaderTitle>
                        {dataAxios?.length ? dataAxios[2].titleHeader : 'Use of Cookies'}
                        </CookiesModalHeaderTitle>
                        <CookiesModalHeaderClose onClick={(e) => сookiesModalClosed(e)}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1884_718)">
                                <path d="M23.4375 6.5625L6.5625 23.4375" stroke="#130C03" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                                <path d="M23.4375 23.4375L6.5625 6.5625" stroke="#130C03" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_1884_718">
                                <rect width="30" height="30" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </CookiesModalHeaderClose>
                    </ContainerCoolies>
                </CookiesModalHeader>

                <CookiesModalContent>
                    <ContainerCoolies>
                        <CookiesModalContentDesc>
                            {dataAxios?.length ? dataAxios[1].desc : CookiesPanelData[1].desc}
                        </CookiesModalContentDesc>
                        <CookiesModalContentTitle>
                            {dataAxios?.length ? dataAxios[1].title : CookiesPanelData[1].title}
                        </CookiesModalContentTitle>
                        <CookiesModalContentItems>

                            {dataAxios?.length && dataAxios[1].items?.map((item, i) => {
                              
                              return (
                                <CookiesModalContentItem key={i} className="modal__item">
                                    <CookiesModalContentItemLeft onClick={(e) => showDescription(e)}>
                                        <CookiesModalContentItemLeftName className="modal__item--name">
                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1951_2273)">
                                                <path d="M4.375 14H23.625" stroke="#130C03" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14 4.375V23.625" stroke="#130C03" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_1951_2273">
                                                <rect width="28" height="28" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                            {item.name}
                                        </CookiesModalContentItemLeftName>
                                        <CookiesModalContentItemLeftText className="content-accordion">
                                            <p>{item.text}</p>
                                        </CookiesModalContentItemLeftText>
                                    </CookiesModalContentItemLeft>
                                    <CookiesModalContentItemRight>
                                        {item.checked === true ? 
                                        <>
                                            <input type="checkbox" id="checkboxSwitch" name="checkBoxSwitch" defaultChecked className={item?.activeAlwais === true ? "switch--activeAlwais switchInput" : "switchInput"}/>
                                            <label htmlFor="checkBoxSwitch" data-onlabel="on" data-offlabel="off" className="switchSlider"></label>
                                        </>
                                            
                                        :
                                        <>
                                            <input type="checkbox" id="checkboxSwitch" name="checkBoxSwitch" className={item?.activeAlwais === true ? "switch--activeAlwais switchInput" : "switchInput"}/>
                                            <label htmlFor="checkBoxSwitch" data-onlabel="on" data-offlabel="off" className="switchSlider"></label>
                                        </>
                                            
                                        }
                                        
                                    </CookiesModalContentItemRight>
                                </CookiesModalContentItem>
                              )
                          })}

                            
                        </CookiesModalContentItems>
                    </ContainerCoolies>
                    
                </CookiesModalContent>

                <CookiesModalFooter>
                    <ContainerCoolies>
                        <CookiesModalFooterLeft>
                            {dataAxios?.length ? dataAxios[2].textFooter : 'For any further clarification please view our.'}
                            <CookiesModalFooterLeftLinks>
                                <CookiesModalFooterLeftLink href={dataAxios?.length ? dataAxios[2].linkFooterOneLink : '/cookie'}>
                                    {dataAxios?.length ? dataAxios[2].linkFooterOneText : 'Cookie Policy'}
                                </CookiesModalFooterLeftLink>
                                <CookiesModalFooterLeftLink href={dataAxios?.length ? dataAxios[2].linkFooterTwoLink : '/privacy'}>
                                    {dataAxios?.length ? dataAxios[2].linkFooterTwoText : 'Privacy Policy'}
                                </CookiesModalFooterLeftLink>
                            </CookiesModalFooterLeftLinks>
                        </CookiesModalFooterLeft>
                        <CookiesModalFooterRight>
                            <CookiesModalFooterRightReject onClick={(e) => swiperReject(e)}>
                                <ButtonLow type={'link'} to={'#'}>{dataAxios?.length ? dataAxios[2].buttonFooterOne : 'Reject All'}</ButtonLow>
                            </CookiesModalFooterRightReject>
                            
                            
                            <CookiesModalFooterRightConfirm onClick={(e) => cookiesClosed(e)}>
                                <ButtonLow type={'link'} to={'#'}>{dataAxios?.length ? dataAxios[2].buttonFooterTwo : 'Confirm my Choices'}</ButtonLow>
                            </CookiesModalFooterRightConfirm>
                        </CookiesModalFooterRight>
                    </ContainerCoolies>    
                </CookiesModalFooter>
            </CookiesModal>

            <CookiesPanel className='cookies__panel'>
                <Container>
                    <CookiesPanelLeft>
                        <CookiesPanelLeftImg>
                            <img src={dataAxios?.length ? dataAxios[0].image : CookiesPanelData[0].image} alt=""/>
                        </CookiesPanelLeftImg>
                        <CookiesPanelLeftText>
                            <CookiesPanelLeftTextTitle>
                                {dataAxios?.length ? dataAxios[0].title : CookiesPanelData[0].title}
                            </CookiesPanelLeftTextTitle>
                            <CookiesPanelLeftTextParag>
                                {dataAxios?.length ? dataAxios[0].text : CookiesPanelData[0].text}
                            </CookiesPanelLeftTextParag>
                        </CookiesPanelLeftText>
                    </CookiesPanelLeft>
                    <CookiesPanelRight>
                        <CookiesPanelRightText onClick={(e) => cookiesUse(e)}>
                            <ButtonLow type={'link'} to={'#'} >{dataAxios?.length ? dataAxios[0].linkUseCookies : CookiesPanelData[0].linkUseCookies}</ButtonLow>
                        </CookiesPanelRightText>
                        <CookiesPanelRightButton onClick={(e) => cookiesClosed(e)}>
                            <ButtonLow type={'link'} to={'#'} >{dataAxios?.length ? dataAxios[0].linkAccept : CookiesPanelData[0].linkAccept}</ButtonLow>
                        </CookiesPanelRightButton>  
                    </CookiesPanelRight>
                </Container>            
            </CookiesPanel>
        </CookiesWrapper>
    );
}

export default Cookies;

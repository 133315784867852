import React, {useEffect, useRef, useState} from 'react';
import {VideoBannerWrapper} from "./styles";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import axios from "axios"
import { token, version } from '../../global/config';

function VideoBanner({VideoBannerImage, isVideo, setVideo, setOpenPopup, hiddenCursor}) {

    const parralaxRef = useRef(null);
    const parralaxParentRef = useRef(null);

    useEffect(() => {

        ScrollTrigger.matchMedia({
            // large
            "(min-width: 1280px)": function () {
                if (parralaxRef.current && parralaxParentRef.current) {

                    gsap.fromTo(parralaxRef.current, {
                        y: () => parralaxRef.current?.offsetHeight - parralaxParentRef.current?.offsetHeight,
                    }, {
                        scrollTrigger: {
                            trigger: parralaxParentRef.current,
                            scrub: true,
                            start: '-30%',
                            invalidateOnRefresh: true,
                        },
                        y: 0,
                        ease: 'none',

                    })


                }
            },
        });
    }, [parralaxRef])


    const handlePlayVideo = (video) => {
        setVideo(video);
        setOpenPopup(true);
    }

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/home?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })


    },[])

    return (
        <FadeInAnimation delay={0.3}>
            <VideoBannerWrapper className={'v-view cursor-disable'} ref={parralaxParentRef} onClick={() => isVideo && handlePlayVideo(dataAxios[2].video)}>
                <img ref={parralaxRef} src={VideoBannerImage} alt="videoBanner"/>

                {hiddenCursor ? null :
                    <svg className={'video-button'} width="206" height="206"
                        viewBox="0 0 206 206" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g className={'rotator'}>
                            <path
                                d="M195.517 111.739C195.278 114.32 193.649 116.024 191.594 115.834C189.561 115.669 188.276 113.67 188.516 111.089L188.822 107.791L195.823 108.441L195.517 111.739ZM186.084 111.321C185.713 115.312 187.911 118.384 191.328 118.701C194.745 119.018 197.471 116.403 197.839 112.436L198.447 105.888L181.527 104.318L181.27 107.09L186.432 107.569L186.084 111.321Z"
                                fill="#FFFDFB"/>
                            <path d="M196.971 122.725L179.682 118.877L179.119 121.407L196.408 125.255L196.971 122.725Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M178.401 130.127C179.186 127.935 181.455 126.938 183.76 127.764C186.087 128.597 187.184 130.8 186.399 132.992C185.614 135.184 183.376 136.166 181.048 135.333C178.721 134.499 177.616 132.319 178.401 130.127ZM185.062 136.796C186.766 136.31 188.121 135.087 188.761 133.302C189.967 129.936 188.226 126.61 184.634 125.323C181.041 124.037 177.585 125.501 176.379 128.868C175.74 130.652 176.011 132.458 177.019 133.915L175.301 133.3L174.419 135.763L185.897 139.874L186.779 137.411L185.062 136.796Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M169.315 134.491C168.779 134.906 168.254 135.547 167.914 136.154C166.645 138.414 168.146 140.496 170.078 142.791L178.623 153.064L179.963 150.678L173.735 143.385L183.451 144.462L184.849 141.972L171.486 140.774C170.473 139.489 169.254 138.228 170.077 136.763C170.312 136.344 170.731 135.891 171.157 135.525L169.315 134.491Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M160.134 162.007C162.195 163.842 164.566 161.936 166.401 160.453C167.96 159.206 168.782 158.716 169.463 159.322C170.198 159.977 170.002 161.28 169.124 162.266C167.96 163.575 166.487 163.967 165.574 163.187L163.963 164.997C165.773 166.609 168.614 166.053 170.704 163.704C172.539 161.642 172.74 158.962 171.073 157.478C168.903 155.547 167.198 156.921 164.977 158.768C164.038 159.57 162.544 160.779 161.594 159.934C160.825 159.281 161.051 157.908 162.136 156.689C163.349 155.327 165.015 155.043 166.091 156L167.686 154.207C165.678 152.42 162.679 152.867 160.54 155.269C158.482 157.582 158.323 160.396 160.134 162.007Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M168.026 173.743L156.739 160.092L154.723 161.759L159.189 167.16C160.428 168.659 160.149 170.695 158.744 171.857C157.338 173.019 155.68 172.709 154.533 171.322L149.914 165.736L147.917 167.387L152.673 173.14C154.6 175.47 157.471 175.93 159.802 174.003C160.985 173.024 161.71 171.491 161.559 170.027L166.01 175.41L168.026 173.743Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M147.557 182.286C150.954 180.346 151.965 176.646 150.048 173.291C148.132 169.935 144.432 168.925 141.035 170.865C137.659 172.793 136.628 176.505 138.544 179.86C140.46 183.216 144.181 184.214 147.557 182.286ZM142.19 172.887C144.232 171.72 146.551 172.441 147.777 174.588C149.003 176.735 148.445 179.098 146.403 180.264C144.381 181.419 142.021 180.722 140.795 178.575C139.569 176.428 140.168 174.041 142.19 172.887Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M128.232 177.157L128.675 186.642L123.57 178.651L120.301 179.698L120.227 192.524L122.787 191.704L122.693 181.578L128.295 189.939L130.924 189.097L130.547 179.112L136.386 187.347L138.969 186.52L131.501 176.11L128.232 177.157Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M118.119 192.308L116.434 180.233L113.843 180.594L114.765 187.202C115.064 189.342 114.135 191.022 112.424 191.261C111.948 191.327 111.198 191.335 110.493 191.143L110.815 193.448C111.364 193.565 111.928 193.608 112.403 193.542C113.782 193.349 114.843 192.45 115.408 191.111L115.623 192.656L118.119 192.308Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M105.45 188.613C105.107 190.556 103.662 191.87 101.694 191.863C99.6777 191.855 98.1469 190.505 97.8664 188.584L105.45 188.613ZM108.166 187.64C108.181 183.728 105.456 180.909 101.64 180.894C98.8561 180.884 96.3294 182.602 95.6242 184.999L98.0708 185.369C98.532 184.027 100.048 183.072 101.68 183.079C103.672 183.086 105.178 184.532 105.482 186.693L95.2099 186.63C95.1848 186.918 95.1594 187.278 95.1583 187.566C95.1437 191.333 97.8213 194.032 101.613 194.046C105.405 194.061 108.152 191.384 108.166 187.64Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M91.1681 187.891C90.5092 189.751 88.8674 190.809 86.9275 190.478C84.9403 190.138 83.6528 188.554 83.6925 186.613L91.1681 187.891ZM94.0071 187.379C94.6664 183.522 92.4428 180.294 88.6814 179.65C85.9372 179.181 83.162 180.46 82.0715 182.708L84.4238 183.476C85.0998 182.228 86.752 181.536 88.3606 181.811C90.3241 182.147 91.5718 183.821 91.5153 186.003L81.3943 184.248C81.3221 184.528 81.2378 184.879 81.1892 185.163C80.5542 188.877 82.7507 191.979 86.4885 192.618C90.2262 193.257 93.3761 191.069 94.0071 187.379Z"
                                fill="#FFFDFB"/>
                            <path d="M76.3448 195.446L81.3649 178.46L78.8792 177.726L73.8591 194.711L76.3448 195.446Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M48.6193 178.726C46.5012 177.232 45.8348 174.97 47.0244 173.283C48.1806 171.603 50.5542 171.485 52.6723 172.979L55.3789 174.888L51.3259 180.634L48.6193 178.726ZM53.683 170.755C50.4077 168.445 46.649 168.819 44.6709 171.623C42.6928 174.428 43.6019 178.094 46.8576 180.391L52.2315 184.181L62.0251 170.295L59.75 168.69L56.7622 172.927L53.683 170.755Z"
                                fill="#FFFDFB"/>
                            <path d="M38.3126 174.43L50.2791 161.372L48.3681 159.621L36.4017 172.679L38.3126 174.43Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M41.2204 154.697C42.7297 156.469 42.4642 158.933 40.6004 160.521C38.7184 162.123 36.2615 161.978 34.7521 160.205C33.2427 158.433 33.5056 156.002 35.3876 154.4C37.2696 152.797 39.711 152.924 41.2204 154.697ZM32.1196 157.151C31.6924 158.87 32.0772 160.655 33.3065 162.098C35.625 164.821 39.3757 164.968 42.281 162.494C45.1862 160.02 45.6381 156.294 43.3195 153.571C42.0902 152.128 40.3899 151.464 38.6244 151.611L40.0131 150.429L38.317 148.437L29.0348 156.342L30.7309 158.334L32.1196 157.151Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M41.9897 144.683C41.8947 144.012 41.5979 143.239 41.2395 142.642C39.9048 140.42 37.353 140.693 34.4022 141.234L21.2447 143.566L22.6535 145.912L32.0741 144.116L26.3241 152.022L27.7948 154.47L35.457 143.456C37.0745 143.212 38.7741 142.779 39.6393 144.219C39.8864 144.631 40.072 145.219 40.1793 145.771L41.9897 144.683Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M22.6601 122.993C20.0414 123.865 20.5107 126.871 20.8817 129.201C21.1845 131.174 21.1993 132.131 20.334 132.419C19.4004 132.729 18.3685 131.909 17.9516 130.657C17.3982 128.995 17.7935 127.522 18.9244 127.121L18.1588 124.821C15.8589 125.586 14.9243 128.326 15.9174 131.309C16.7891 133.928 19.0123 135.439 21.13 134.734C23.8853 133.817 23.5447 131.653 23.0525 128.807C22.8252 127.593 22.5224 125.695 23.7293 125.294C24.6781 124.952 25.7555 125.833 26.2709 127.382C26.8471 129.112 26.2621 130.698 24.8958 131.153L25.6538 133.43C28.2042 132.581 29.3134 129.758 28.2976 126.707C27.3197 123.769 24.96 122.228 22.6601 122.993Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M8.5404 123.967L26.0103 121.049L25.5793 118.469L18.6671 119.624C16.7497 119.944 15.1276 118.682 14.8271 116.883C14.5265 115.084 15.6269 113.805 17.4023 113.508L24.5512 112.314L24.1242 109.758L16.7622 110.987C13.7795 111.486 11.9417 113.739 12.4399 116.722C12.693 118.237 13.6562 119.633 14.9979 120.236L8.10939 121.387L8.5404 123.967Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M11.3875 101.804C11.3573 105.716 14.0483 108.449 17.9122 108.479C21.7761 108.509 24.5089 105.818 24.5391 101.906C24.5691 98.0181 21.8783 95.2612 18.0145 95.2314C14.1506 95.2016 11.4175 97.9166 11.3875 101.804ZM22.2112 101.888C22.193 104.24 20.4043 105.882 17.9324 105.863C15.4604 105.844 13.6973 104.174 13.7154 101.822C13.7334 99.4945 15.5225 97.8042 17.9944 97.8233C20.4664 97.8424 22.2292 99.5601 22.2112 101.888Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M25.4509 87.811L17.0057 83.4705L26.4782 83.024L27.1983 79.6684L16.1135 73.215L15.5495 75.8432L24.3768 80.8057L14.336 81.4985L13.7569 84.197L22.6027 88.8444L12.5534 89.8054L11.9844 92.457L24.7309 91.1666L25.4509 87.811Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M17.3294 71.4857L28.628 76.0667L29.6109 73.6424L23.4278 71.1355C21.4261 70.3239 20.4355 68.6792 21.0848 67.0778C21.2651 66.633 21.6336 65.9796 22.153 65.465L19.9956 64.5903C19.6193 65.0075 19.3007 65.4739 19.1203 65.9188C18.5973 67.2088 18.8449 68.5781 19.7219 69.7365L18.2762 69.1504L17.3294 71.4857Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M26.7548 62.5005C25.2425 61.2335 24.8244 59.3253 25.8129 57.6236C26.8254 55.8803 28.7591 55.2272 30.564 55.9425L26.7548 62.5005ZM26.2435 65.3398C29.6263 67.3047 33.4286 66.3492 35.3452 63.0494C36.7436 60.6421 36.515 57.5951 34.7893 55.7878L33.2485 57.7238C34.1816 58.7932 34.2523 60.5828 33.4326 61.994C32.4321 63.7165 30.4275 64.3007 28.403 63.4856L33.583 54.6153C33.3461 54.4499 33.0468 54.2483 32.7978 54.1037C29.5395 52.2111 25.8651 53.1854 23.9605 56.4644C22.0559 59.7433 23.006 63.4593 26.2435 65.3398Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M34.3395 50.6616C33.0554 49.1639 32.9557 47.213 34.2097 45.6963C35.4943 44.1426 37.5089 43.8152 39.1721 44.8167L34.3395 50.6616ZM33.3698 53.3787C36.3847 55.8715 40.2922 55.5522 42.7238 52.6112C44.4978 50.4656 44.7718 47.4224 43.3657 45.3567L41.5283 47.0139C42.2736 48.2218 42.05 49.9988 41.01 51.2565C39.7407 52.7918 37.6675 53.0395 35.804 51.9035L42.3679 44.0023C42.1612 43.8003 41.8991 43.5524 41.6771 43.3689C38.7731 40.9679 34.9887 41.3267 32.5724 44.2491C30.1561 47.1716 30.4843 50.993 33.3698 53.3787Z"
                                fill="#FFFDFB"/>
                            <path d="M35.1542 34.0131L47.4024 46.8074L49.2748 45.015L37.0265 32.2207L35.1542 34.0131Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M63.7835 17.9689C66.1355 16.8797 68.4276 17.4316 69.295 19.3045C70.174 21.1456 69.0905 23.2607 66.7385 24.3499L63.7331 25.7417L60.7781 19.3607L63.7835 17.9689ZM68.1608 26.3361C71.7977 24.6518 73.3505 21.2085 71.9083 18.0943C70.4661 14.98 66.8358 13.937 63.2206 15.6112L57.2534 18.3745L64.3938 33.7934L66.9201 32.6236L64.7416 27.9195L68.1608 26.3361Z"
                                fill="#FFFDFB"/>
                            <path d="M72.4599 11.2245L77.8002 28.1123L80.2715 27.3308L74.9313 10.443L72.4599 11.2245Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M87.8238 23.6242C85.5353 24.0517 83.5298 22.5951 83.0804 20.1887C82.6265 17.7587 83.9755 15.7001 86.2639 15.2727C88.5523 14.8452 90.5298 16.2826 90.9837 18.7126C91.4376 21.1425 90.1122 23.1968 87.8238 23.6242ZM90.2229 14.5088C88.9441 13.2827 87.2048 12.7287 85.341 13.0768C81.8258 13.7333 79.8318 16.9135 80.5324 20.6646C81.2331 24.4158 84.2406 26.6617 87.7558 26.0052C89.6195 25.6571 91.0416 24.5125 91.7916 22.9075L92.1265 24.7005L94.6981 24.2202L92.4596 12.2355L89.888 12.7158L90.2229 14.5088Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M96.0884 29.2316C96.7174 29.4836 97.536 29.6118 98.2319 29.5983C100.823 29.5483 101.858 27.1999 102.86 24.3721L107.394 11.8022L104.659 11.855L101.521 20.9173L97.532 11.9926L94.6765 12.0477L100.408 24.1793C99.813 25.7031 99.3415 27.3925 97.6619 27.4249C97.1819 27.4342 96.5793 27.3018 96.0477 27.12L96.0884 29.2316Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M124.81 23.2223C125.358 20.5172 122.518 19.4275 120.312 18.5888C118.45 17.8686 117.614 17.4052 117.795 16.5114C117.99 15.547 119.215 15.0606 120.509 15.3227C122.226 15.6707 123.306 16.7466 123.091 17.9274L125.467 18.4089C125.948 16.0332 124.038 13.8585 120.957 13.2341C118.252 12.6859 115.834 13.8612 115.391 16.0487C114.814 18.8949 116.86 19.6767 119.573 20.6673C120.739 21.075 122.535 21.7574 122.283 23.004C122.106 23.9967 120.806 24.4923 119.206 24.1682C117.419 23.806 116.335 22.5088 116.621 21.0975L114.269 20.6209C113.735 23.2553 115.63 25.623 118.782 26.2617C121.816 26.8765 124.328 25.598 124.81 23.2223Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M131.467 10.6034L125.245 27.1864L127.694 28.1055L130.156 21.5441C130.839 19.7241 132.743 18.952 134.451 19.5927C136.159 20.2335 136.715 21.8263 136.082 23.5116L133.536 30.2976L135.963 31.2082L138.585 24.22C139.647 21.3887 138.617 18.6694 135.786 17.607C134.348 17.0674 132.657 17.2019 131.463 18.0613L133.916 11.5224L131.467 10.6034Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M148.78 23.8986C145.404 21.9213 141.693 22.8906 139.74 26.2247C137.787 29.5587 138.756 33.2696 142.132 35.2469C145.487 37.2121 149.218 36.2549 151.171 32.9209C153.124 29.5868 152.134 25.8638 148.78 23.8986ZM143.309 33.2382C141.279 32.0494 140.748 29.6799 141.997 27.5469C143.247 25.4139 145.573 24.7185 147.603 25.9073C149.612 27.084 150.184 29.4777 148.935 31.6107C147.685 33.7437 145.317 34.4149 143.309 33.2382Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M154.013 43.1264L161.992 37.9792L157.646 46.4079L160.193 48.7081L171.322 42.33L169.327 40.5285L160.618 45.6958L165.034 36.6518L162.986 34.802L154.541 40.1428L158.729 30.9575L156.716 29.1398L151.466 40.8262L154.013 43.1264Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M172.559 44.6192L162.94 52.111L164.548 54.1748L169.811 50.075C171.515 48.7477 173.435 48.7127 174.497 50.076C174.792 50.4547 175.173 51.1006 175.359 51.8078L177.196 50.3772C177.023 49.8427 176.778 49.3335 176.483 48.9548C175.628 47.8566 174.318 47.386 172.876 47.5659L174.107 46.6073L172.559 44.6192Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M175.317 56.7951C177.167 56.1093 179.032 56.6915 180.02 58.3932C181.033 60.1365 180.642 62.1397 179.126 63.3531L175.317 56.7951ZM173.104 54.9443C169.721 56.9091 168.667 60.6854 170.584 63.9851C171.982 66.3925 174.742 67.7036 177.167 67.1L176.249 64.8024C174.858 65.0831 173.268 64.2579 172.449 62.8466C171.448 61.1241 171.934 59.0934 173.645 57.7387L178.783 66.6331C179.045 66.5092 179.368 66.3491 179.617 66.2045C182.875 64.3119 183.849 60.6375 181.945 57.3585C180.04 54.0795 176.342 53.0638 173.104 54.9443Z"
                                fill="#FFFDFB"/>
                            <path
                                d="M181.95 69.4943C183.888 69.1259 185.63 70.0104 186.321 71.853C187.03 73.7405 186.311 75.6507 184.614 76.5949L181.95 69.4943ZM180.076 67.3008C176.413 68.6751 174.746 72.2232 176.086 75.796C177.064 78.4025 179.567 80.1548 182.059 79.9633L181.536 77.5449C180.117 77.5901 178.687 76.5118 178.114 74.9838C177.414 73.1187 178.231 71.1972 180.144 70.1463L183.73 79.772C184.008 79.6933 184.354 79.5893 184.623 79.4881C188.151 78.1644 189.724 74.7034 188.391 71.1531C187.059 67.6028 183.581 65.9855 180.076 67.3008Z"
                                fill="#FFFDFB"/>
                            <path d="M195.961 78.457L178.765 82.699L179.385 85.2156L196.582 80.9736L195.961 78.457Z"
                                fill="#FFFDFB"/>

                        </g>
                        <path
                            d="M117.5 101.402C119.5 102.557 119.5 105.443 117.5 106.598L96.5 118.722C94.5 119.877 92 118.434 92 116.124L92 91.8756C92 89.5662 94.5 88.1229 96.5 89.2776L117.5 101.402Z"
                            fill="#FFFDFB"/>
                    </svg>
                }
                
            </VideoBannerWrapper>
        </FadeInAnimation>
    );
}

export default VideoBanner;

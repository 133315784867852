import Container from "../Container/Container";
import React from "react";
import {TellInner, TellTitle, TellWrapper} from "./styled";
import Button from "../Button/Button";

const Tell = ({tellData}) => {
    return (
        <TellWrapper >
            <Container>
                    <TellInner>
                        <TellTitle>
                            {tellData ? tellData.title : 'Excited about your own project?'}
                        </TellTitle>
                        <Button type={'link'} to={'/contacts'}>{tellData ? tellData.button : 'Tell us about your project'}</Button>
                    </TellInner>
            </Container>
        </TellWrapper>
    )
}

export default Tell;

import React from 'react';
import {
    ScreenLoader,
    ScreenLoaderOpen
} from "./styles";


function ScreenLoad({props}) {


    return (
       <ScreenLoader className="screenLoad hidden">
        <ScreenLoaderOpen className="screenLoad--open"></ScreenLoaderOpen>
       </ScreenLoader>
    );
}

export default ScreenLoad;

import styled from "styled-components";
import {COLORS, TRANSITIONS} from "./Theme";
import sharedProps from "./sharedProps";
import {NavLink} from "react-router-dom";
import {device} from "./responsive";


const MediumButton = styled(NavLink)`
  position: relative;
  z-index: 1;
  padding: 20px 34px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid ${COLORS.SECONDARYBLACK};
  width: max-content;
  text-decoration: none;
  color: ${COLORS.SECONDARYBLACK};
  display: block;
  ${sharedProps};
  transition: border-color .35s;
  span{
    display: inline-block;
  }
  ${props => !props.color}{
    border-color: ${COLORS.WHITE};
    span{
      color: ${COLORS.WHITE};
    }
  }
  &:hover{
    border-color: ${COLORS.GREEN};
    .ripple{
      &:after{
        transform: translateY(0);
        transition: transform 350ms 355ms cubic-bezier(.67,0,.285,1);
      }
      &:before{
        transform: translateY(0);
        transition: transform 350ms cubic-bezier(.67,0,.285,1);
      }
    }
  }
  &:active{
    border-color: ${COLORS.GREEN3};
    &:after , &:before{
      background: ${COLORS.GREEN3};
    }
  }

  span.ripple{
    position: absolute;
    left: 0px;
    top: 0;
    ${sharedProps};
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;
    &::after, &::before{
      content: " ";
      display: block;
      position: absolute;
      width: 135%;
      height: 150%;
      top: -20%;
      left: -20%;
      background: ${COLORS.GREEN};
      z-index: -1;
      border-radius: 100%;
      pointer-events: none;
    }    
    &:before{
      transform: translateY(-100%);
      z-index: 1;
    }
    &:after{
      z-index: 0;
      transform: translateY(100%);
      transition: none;
      transition: transform 350ms cubic-bezier(.67,0,.285,1);
      
    }
  }
  .ripple-inner{
    transform-origin: top;
    border-radius: 100px;
  }
  
 
`;

const MediumButtonNL = styled.div`
  position: relative;
  z-index: 1;
  padding: 20px 34px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid ${COLORS.SECONDARYBLACK};
  width: max-content;
  text-decoration: none;
  color: ${COLORS.SECONDARYBLACK};
  display: block;
  transition: background ${TRANSITIONS.FAST} , color ${TRANSITIONS.FAST} , border-color ${TRANSITIONS.FAST};
  ${sharedProps};
  span{
    display: inline-block;
  }

  &:hover{
    border-color: ${COLORS.GREEN};
    .ripple{
      &:after{
        transform: translateY(0);
        transition: transform 350ms 355ms cubic-bezier(.67,0,.285,1);
      }
      &:before{
        transform: translateY(0);
        transition: transform 350ms cubic-bezier(.67,0,.285,1);
      }
    }
  }
  &:active{
    border-color: ${COLORS.GREEN3};
    &:after , &:before{
      background: ${COLORS.GREEN3};
    }
  }

  span.ripple{
    position: absolute;
    left: 0px;
    top: 0;
    ${sharedProps};
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;
    &::after, &::before{
      content: " ";
      display: block;
      position: absolute;
      width: 135%;
      height: 150%;
      top: -20%;
      left: -20%;
      background: ${COLORS.GREEN};
      z-index: -1;
      border-radius: 100%;
      pointer-events: none;
    }
    &:before{
      transform: translateY(-100%);
      z-index: 1;
    }
    &:after{
      z-index: 0;
      transform: translateY(100%);
      transition: none;
      transition: transform 350ms cubic-bezier(.67,0,.285,1);

    }
  }
  .ripple-inner{
    transform-origin: top;
    border-radius: 100px;
  }
`;

const MediumButtonSubmit = styled.button`
  position: relative;
  z-index: 1;
  padding: 20px 34px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 50px;
  border: 1px solid ${COLORS.SECONDARYBLACK};
  background: none;
  width: max-content;
  text-decoration: none;
  color: ${COLORS.SECONDARYBLACK};
  display: block;
  transition: background ${TRANSITIONS.FAST} , color ${TRANSITIONS.FAST} , border-color ${TRANSITIONS.FAST};
  span{
    display: inline-block;
  }

  ${sharedProps};
  &:hover{
    border-color: ${COLORS.GREEN};
    .ripple{
      &:after{
        transform: translateY(0);
        transition: transform 350ms 355ms cubic-bezier(.67,0,.285,1);
      }
      &:before{
        transform: translateY(0);
        transition: transform 350ms cubic-bezier(.67,0,.285,1);
      }
    }
  }
  &:active{
    border-color: ${COLORS.GREEN3};
    &:after , &:before{
      background: ${COLORS.GREEN3};
    }
  }

  span.ripple{
    position: absolute;
    left: 0px;
    top: 0;
    ${sharedProps};
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;
    &::after, &::before{
      content: " ";
      display: block;
      position: absolute;
      width: 135%;
      height: 150%;
      top: -20%;
      left: -20%;
      background: ${COLORS.GREEN};
      z-index: -1;
      border-radius: 100%;
      pointer-events: none;
    }
    &:before{
      transform: translateY(-100%);
      z-index: 1;
    }
    &:after{
      z-index: 0;
      transform: translateY(100%);
      transition: none;
      transition: transform 350ms cubic-bezier(.67,0,.285,1);

    }
  }
  .ripple-inner{
    transform-origin: top;
    border-radius: 100px;
  }
  @media ${device.mobile} {
    width: 100%
  }

`;




// eslint-disable-next-line
export default {
    Medium: MediumButton,
    MediumNL: MediumButtonNL,
    MediumButtonSubmit: MediumButtonSubmit,
};

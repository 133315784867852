import styled from "styled-components";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const MarqueeWrapper = styled.div`
  overflow: hidden;
`;

export const MarqueeInner = styled.div`
  -webkit-font-smoothing: antialiased;
  width: fit-content;
  display: flex;
  flex: auto;
  flex-direction: row;
`;

export const MarqueeWord = styled.div`

  font-size: 100px;
  line-height: 1;
  letter-spacing: -0.02em;
  color: ${COLORS.BLACK};
  ${sharedProps};
  font-smooth: always;
  white-space: nowrap;
  padding-left: 100px;
  padding-right: 100px;
  @media ${device.tabletL}{
    font-size: 80px;
    line-height: 92px;
  }
  @media ${device.mobile} {
    font-size: 56px;
    line-height: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

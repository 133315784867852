import React, {useEffect, useState} from 'react';
import TestimonialsIntro from "../../Components/TestimonialsIntro/TestimonialsIntro";
import TestimonialsBlock from "../../Components/TestimonialsBlock/TestimonialsBlock";
import Tell from "../../Components/Tell/Tell";
import axios from "axios";
import { token, version } from '../../global/config';

const Testimonials = (props) => {

    const testimonialsData = [
        {
            name: 'Dan Nolan',
            year: '2022 - Present',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Branding',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Lizzie',
            year: '2021 - 2022',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression. Designs that creates a immersive, also it was a great success.',
            category: 'App',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Amro Shouad',
            year: '2020 - 2021',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Logo',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Dan Nolan',
            year: '2022 - Present',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Web Design',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Dan Nolan',
            year: '2022 - Present',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Branding',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Lizzie',
            year: '2021 - 2022',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression. Designs that creates a immersive, also it was a great success.',
            category: 'App',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Amro Shouad',
            year: '2020 - 2021',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Website',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Amro Shouad',
            year: '2020 - 2021',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Development',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Amro Shouad',
            year: '2020 - 2021',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Graphics',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
        {
            name: 'Amro Shouad',
            year: '2020 - 2021',
            text: 'We help translate your ideas into unique digital designs that creates a immersive impression.',
            category: 'Illustration',
            img: require('../../assets/img/testimonials/hover_cursore.png')
        },
    ]

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/testimonials?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <>
            <TestimonialsIntro/>
            <TestimonialsBlock testimonialsData={dataAxios?.length ? dataAxios?.[1].items : testimonialsData}/>
            <Tell tellData={dataAxios?.length && dataAxios[2]}/>
        </>
    );
}

export default Testimonials;

import styled from "styled-components";
import Typography from "../../styles/Typography";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const ProduceWrapper = styled.div`
  padding-top: 160px;
  padding-bottom: 80px;
  @media ${device.tabletL} {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  @media ${device.mobile} {
    padding-top: 60px;
    padding-bottom: 20px;
  }
`;

export const ProduceInner = styled.div``;

export const ProduceTitle = styled(Typography.H2)`
  max-width: 75%;
  margin-bottom: 50px;
  @media ${device.tabletL} {
    max-width: 100%;
    margin-bottom: 40px;
  }
  @media ${device.mobile} {
    margin-bottom: 30px;
  }
`;

export const ProduceText = styled(Typography.H5)`
  width: 75%;
  color: ${COLORS.BLACK3};
  ${sharedProps};
  @media ${device.tabletL} {
    width: 100%;
    font-size: 28px
  }
  @media ${device.mobile} {
    font-size: 20px
  }
`;

export const ProduceList = styled.div`
  margin-top: 160px;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 0.65fr 1fr;
  @media ${device.tabletL} {
    margin-top: 100px;
    grid-template-columns: 1fr 1fr;
    >div{
      &:last-child{
        display: none;
      }
    }
  }
  @media ${device.mobile} {
    margin-top: 90px;
    grid-template-columns: 1fr ;
    gap: 20px;
  }
`;

export const ProduceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    }
`;


import styled from "styled-components";
import {device} from "../../styles/responsive";
import Typography from "../../styles/Typography";
import Buttons from "../../styles/Buttons";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";

export const TellWrapper = styled.div`
  display: flex;

  >div{
    padding-top: 120px;
    margin-top: 160px;
    padding-bottom: 100px;
    ${sharedProps};
    border-top: 1px solid ${COLORS.BLACK5};
    @media ${device.tabletL} {
      margin-top: 70px;
      padding-top: 70px;
      padding-bottom: 70px;
    }
    @media ${device.mobile} {
      margin-top: 50px;
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

`;

export const TellInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
`;

export const TellTitle = styled(Typography.H2)`
  margin-bottom: 58px;
  text-align: center;
  white-space: pre-line;
  @media ${device.tabletL} {
    margin-bottom: 40px;
  }
  @media ${device.mobile} {
    font-size: 34px;
    margin-bottom: 20px;
  }
`;

export const TellButton = styled(Buttons.Medium)``;

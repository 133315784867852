import React, {useEffect, useState} from 'react';
import {IntroTitle} from "../Intro/styles";
import {TestimonialsIntroWrapper} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import axios from "axios";
import { token, version } from '../../global/config';

const TestimonialsIntro = (props) => {
    
    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/testimonials?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <TestimonialsIntroWrapper>
            <Container>
                <FadeInAnimation>
                    <IntroTitle color={'black'}>
                        {dataAxios?.length ? dataAxios?.[0].title : 'The response'}
                    </IntroTitle>
                </FadeInAnimation>
            </Container>
        </TestimonialsIntroWrapper>
    );
}

export default TestimonialsIntro;

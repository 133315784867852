import React, {useRef, useEffect} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {delayForGsap} from "../../functions/constants";

const StaggerAnimation = ({
                              children,
                              wrapperElement = "div",
                              direction = null,
                              delay = 0,
                              ...props
                          }) => {
    const Component = wrapperElement;
    let compRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        initStaggers()
    }, [compRef, delay]);


    const initStaggers = () => {
        gsap.set(compRef.current, {
            y: 20,
            autoAlpha: 0
        })
        setTimeout(() => {
            gsap.to(compRef.current, 1, {
                scrollTrigger: {
                    trigger: compRef.current,
                    start: 'top 85%',
                    // clearProps: true
                },
                stagger: {
                    delay: 0.15,
                    duration: 0.15
                },
                y: 0,
                autoAlpha: 1,
                delay: 0.3,
            });
        }, delayForGsap)
    }

    return (
        <Component ref={compRef} {...props}>
            {children}
        </Component>
    );
};

export default StaggerAnimation;

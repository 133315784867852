import React from 'react';
import {
    ServicesButton, ServicesButtonMob,
    ServicesInner,
    ServicesItem,
    ServicesLeft,
    ServicesList,
    ServicesNew,
    ServicesTitle,
    ServicesWrapper
} from "./styles";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import StaggerAnimation from "../../styles/Animations/StaggerAnimation";
import Button from "../Button/Button";

const Services = ({servicesList}) => {

    return (
        <ServicesWrapper >
            <Container>
                <ServicesInner>
                    <ServicesLeft>
                        <FadeInAnimation>
                            <ServicesTitle><b>{servicesList.titleBold}</b> {servicesList.titleLineFirst} <br/> {servicesList.titleLineSecond}</ServicesTitle>
                            <ServicesButton>
                                <Button type={'link'} to={'services'}>
                                    {servicesList.button}
                                </Button>
                            </ServicesButton>
                        </FadeInAnimation>

                    </ServicesLeft>
                    <ServicesList>
                        {servicesList.servicesList?.content ? servicesList.servicesList.content?.map((service, idx) => {
                            return (
                                <StaggerAnimation key={idx}><ServicesItem>
                                    {service.attrs.body[0].name}
                                    {service.attrs.body[0].isNew && <ServicesNew>New</ServicesNew>}
                                </ServicesItem></StaggerAnimation>
                            )
                        }) :
                            servicesList?.map((service, idx) => {
                                return (
                                    <StaggerAnimation key={idx}><ServicesItem>
                                        {service.name}
                                        {service.isNew && <ServicesNew>New</ServicesNew>}
                                    </ServicesItem></StaggerAnimation>
                                )
                            })
                        }

                    </ServicesList>
                    <FadeInAnimation>
                        <ServicesButtonMob>
                            <Button type={'link'} to={'services'}>{servicesList.button}</Button>
                        </ServicesButtonMob>
                    </FadeInAnimation>
                </ServicesInner>
            </Container>
        </ServicesWrapper>
    );
}


export default Services;

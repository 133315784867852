import styled from "styled-components";
import {device} from "../../styles/responsive";

export const ContainerWrapper = styled.div`
  max-width: 1560px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
  @media ${device.tabletL} {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media ${device.tablet} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${device.mobile} {
    padding: ${props => !props.mobileOff ? "0 20px" : "0 0"};
  }
`;


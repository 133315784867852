import React, {useEffect, useRef, useState} from 'react';
import {
    PrinciplesInner,
    PrinciplesItem,
    PrinciplesLeft, PrinciplesList, PrinciplesListItem, PrinciplesName, PrinciplesRight,
    PrinciplesSlider, PrinciplesTitle, PrinciplesVideo, PrinciplesViewport,
    PrinciplesWrapper
} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import axios from "axios"
import { token, version } from '../../global/config';
import {delayForGsap} from "../../functions/constants";

const videos = [
    {
        source: require('../../assets/img/A01.webm'),
        source2: require('../../assets/img/A01.mp4')
    },
    {
        source: require('../../assets/img/A02.webm'),
        source2: require('../../assets/img/A02.mp4'),
    },
]
const Principles = (props) => {
    const PrinciplesViewportRef = useRef();


    useEffect(() => {

        window.addEventListener('resize', function () {
            ScrollTrigger.update()
        })

        setTimeout(() => {
            initGsap()
            ScrollTrigger.refresh();

            return () => {
                ScrollTrigger.clearMatchMedia()
                ScrollTrigger.getAll('pinClients1').forEach(f => f.kill())
            }
        }, delayForGsap);

        return () => gsap.globalTimeline.clear();

    }, []);


    const initGsap = () =>{
        ScrollTrigger.matchMedia({
            // large
            "(min-width: 1850px)": function () {
                if (PrinciplesViewportRef.current !== null) {
                    const PrinciplesLength = PrinciplesViewportRef.current.children.length;
                    const PrinciplesAllWidth = PrinciplesViewportRef.current.offsetWidth * PrinciplesLength;
                    const PrinciplesMarginPartMinus = (PrinciplesViewportRef.current.offsetWidth * 0.35) * (PrinciplesLength - 1);
                    const paddingLeftPlusRight = 160 * (PrinciplesLength - 1);
                    gsap.to(PrinciplesViewportRef.current, {
                        scrollTrigger: {
                            pin: true,
                            start: 'center center',
                            pinSpacing: true,
                            scrub: 0.3,
                            trigger: PrinciplesViewportRef.current,
                            end: function () {
                                if (PrinciplesViewportRef.current != null) {
                                    return "+=" + (PrinciplesViewportRef.current.offsetWidth * PrinciplesLength);
                                }
                            },
                        },
                        x: function () {
                            return -(PrinciplesAllWidth - document.documentElement.clientWidth) + PrinciplesMarginPartMinus - paddingLeftPlusRight + "px";
                        },

                    })
                }
            },

            "(min-width: 1680px) and (max-width: 1850px)": function () {
                if (PrinciplesViewportRef.current !== null) {
                    const PrinciplesLength = PrinciplesViewportRef.current.children.length;
                    const PrinciplesAllWidth = PrinciplesViewportRef.current.offsetWidth * PrinciplesLength;
                    const PrinciplesMarginPartMinus = (PrinciplesViewportRef.current.offsetWidth * 0.25) * (PrinciplesLength - 1);
                    const paddingLeftPlusRight = 160 * (PrinciplesLength - 1);
                    gsap.to(PrinciplesViewportRef.current, {
                        scrollTrigger: {
                            pin: true,
                            start: 'center center',
                            pinSpacing: true,
                            scrub: 0.3,
                            trigger: PrinciplesViewportRef.current,
                            end: function () {
                                if (PrinciplesViewportRef.current != null) {
                                    return "+=" + (PrinciplesViewportRef.current.offsetWidth * PrinciplesLength);
                                }
                            },
                        },
                        x: function () {
                            return -(PrinciplesAllWidth - document.documentElement.clientWidth) + PrinciplesMarginPartMinus - paddingLeftPlusRight + "px";
                        },

                    })
                }
            },

            "(min-width: 1280px) and (max-width: 1679px)": function () {
                if (PrinciplesViewportRef.current !== null) {
                    const PrinciplesLength = PrinciplesViewportRef.current.children.length;
                    const PrinciplesAllWidth = PrinciplesViewportRef.current.offsetWidth * PrinciplesLength;
                    const paddingLeftPlusRight = 160 * (PrinciplesLength - 1);
                    gsap.to(PrinciplesViewportRef.current, {
                        scrollTrigger: {
                            pin: true,
                            start: 'center center',
                            pinSpacing: true,
                            scrub: 0.3,
                            trigger: PrinciplesViewportRef.current,
                            end: function () {
                                if (PrinciplesViewportRef.current != null) {
                                    return "+=" + (PrinciplesViewportRef.current.offsetWidth * PrinciplesLength);
                                }
                            },
                        },
                        x: function () {
                            return -(PrinciplesAllWidth - document.documentElement.clientWidth) - paddingLeftPlusRight + "px";
                        },

                    })
                }
            },

        });

    }

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/about?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <PrinciplesWrapper>
            <PrinciplesInner>
                <Container>
                    <FadeInAnimation>
                        <PrinciplesTitle>
                           {dataAxios?.length ? dataAxios?.[3].titleColorDefault : 'We approach your project with an aim to build a successful brand through'} 
                             <b className='orange'> {dataAxios?.length ? dataAxios?.[3].titleColorOrange : 'strategic thinking'} </b>, 
                             <b className='red'> {dataAxios?.length ? dataAxios?.[3].titleColorRed : 'creativity'} </b>, 
                             <b className='green'> {dataAxios?.length ? dataAxios?.[3].titleColorGreen : 'transparency'} </b> and 
                             <b className='yellow'> {dataAxios?.length ? dataAxios?.[3].titleColorYellow : 'proficiency'}</b>.
                        </PrinciplesTitle>
                    </FadeInAnimation>
                </Container>
                <PrinciplesSlider>
                    <PrinciplesViewport ref={PrinciplesViewportRef}>
                        <PrinciplesItem>
                            <FadeInAnimation>
                                <PrinciplesLeft>
                                    <PrinciplesVideo autoPlay loop muted playsInline >
                                        {dataAxios?.length ? videos[0].source && <source src={dataAxios[3]?.sliderItem1[0].videoWebm}/>  : videos[0].source && <source src={videos[0]?.source}/> }
                                        {dataAxios?.length ? videos[0].source && <source src={dataAxios[3]?.sliderItem1[0].videoMp4}/>  : videos[0].source && <source src={videos[0]?.source2}/> }
                                        {/* {videos[0].source && <source src={videos[0]?.source}/> }
                                        {videos[0].source2 && <source src={videos[0]?.source2}/> } */}
                                    </PrinciplesVideo>
                                </PrinciplesLeft>
                                <PrinciplesRight>
                                    <PrinciplesName>{dataAxios?.length ? dataAxios?.[3].sliderItem1[0].title : 'Principles that makes us unique.'}</PrinciplesName>
                                    <PrinciplesList>
                                        <PrinciplesListItem>
                                           {dataAxios?.length ? dataAxios?.[3].sliderItem1[0].text1 : 'Always available, with quick response time.'} 
                                        </PrinciplesListItem>
                                        <PrinciplesListItem>
                                            {dataAxios?.length ? dataAxios?.[3].sliderItem1[0].text2 : 'Keep you informed of the process and progress.'}    
                                        </PrinciplesListItem>
                                        <PrinciplesListItem>
                                            {dataAxios?.length ? dataAxios?.[3].sliderItem1[0].text3 : 'Personalised attention to your project.'} 
                                        </PrinciplesListItem>
                                        <PrinciplesListItem>
                                            {dataAxios?.length ? dataAxios?.[3].sliderItem1[0].text4 : 'We respect deadlines and your time.'} 
                                        </PrinciplesListItem>
                                    </PrinciplesList>
                                </PrinciplesRight>
                            </FadeInAnimation>
                        </PrinciplesItem>
                        <PrinciplesItem>
                            <FadeInAnimation>
                                <PrinciplesLeft>
                                    <PrinciplesVideo autoPlay loop muted playsInline >
                                        {dataAxios?.length ? videos[0].source && <source src={dataAxios[3]?.sliderItem2[0].videoWebm}/>  : videos[1].source && <source src={videos[1]?.source}/> }
                                        {dataAxios?.length ? videos[0].source && <source src={dataAxios[3]?.sliderItem2[0].videoMp4}/>  : videos[1].source && <source src={videos[1]?.source2}/> }
                                        {/* {videos[1].source && <source src={videos[1]?.source}/> }
                                        {videos[1].source2 && <source src={videos[1]?.source2}/> } */}
                                    </PrinciplesVideo>

                                </PrinciplesLeft>
                                <PrinciplesRight>
                                    <PrinciplesName> {dataAxios?.length ? dataAxios?.[3].sliderItem2[0].title : 'Helping is in our nature.'}</PrinciplesName>
                                    <PrinciplesList>
                                        <PrinciplesListItem>
                                            {dataAxios?.length ? dataAxios?.[3].sliderItem2[0].text1 : 'Understanding your idea via business analysis, concepts and incorporate human-centered expertise'}  
                                        </PrinciplesListItem>
                                        <PrinciplesListItem>
                                            {dataAxios?.length ? dataAxios?.[3].sliderItem2[0].text2 : 'Creating high-fidelity visual designs and interactive prototypes.'}
                                        </PrinciplesListItem>
                                        <PrinciplesListItem>
                                            {dataAxios?.length ? dataAxios?.[3].sliderItem2[0].text3 : 'Create user journey pathways and wireframes.'}  
                                        </PrinciplesListItem>
                                    </PrinciplesList>
                                </PrinciplesRight>
                            </FadeInAnimation>
                        </PrinciplesItem>
                    </PrinciplesViewport>
                </PrinciplesSlider>
            </PrinciplesInner>
        </PrinciplesWrapper>
    );
}

export default Principles;

import React, {useEffect, useRef} from 'react';
import {ResultsItem, ResultsList, ResultsOverflow, ResultsTitle, ResultsWrapper} from "./styled";
import {gsap} from "gsap";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {scrollTriggerUpdate} from "../../functions/functions";
import {delayForGsap} from "../../functions/constants";


const resultsImgs = [
    {
        image: require('../../assets/img/service/1.png')
    },
    {
        image: require('../../assets/img/service/2.png')
    },
    {
        image: require('../../assets/img/service/3.png')
    },
    {
        image: require('../../assets/img/service/4.png')
    },
    {
        image: require('../../assets/img/service/5.png')
    },
    {
        image: require('../../assets/img/service/6.png')
    },
    {
        image: require('../../assets/img/service/7.png')
    },
    {
        image: require('../../assets/img/service/8.png')
    },
    {
        image: require('../../assets/img/service/9.png')
    },
    {
        image: require('../../assets/img/service/10.png')
    },
    {
        image: require('../../assets/img/service/11.png')
    },
    {
        image: require('../../assets/img/service/12.png')
    },
    {
        image: require('../../assets/img/service/6.png')
    },
    {
        image: require('../../assets/img/service/7.png')
    },
    {
        image: require('../../assets/img/service/8.png')
    },
    {
        image: require('../../assets/img/service/9.png')
    },
    {
        image: require('../../assets/img/service/10.png')
    },
    {
        image: require('../../assets/img/service/11.png')
    },
    {
        image: require('../../assets/img/service/12.png')
    },
    {
        image: require('../../assets/img/service/6.png')
    },
    {
        image: require('../../assets/img/service/7.png')
    },
    {
        image: require('../../assets/img/service/8.png')
    },
    {
        image: require('../../assets/img/service/9.png')
    },
    {
        image: require('../../assets/img/service/10.png')
    },
    {
        image: require('../../assets/img/service/11.png')
    },
    {
        image: require('../../assets/img/service/12.png')
    },

];

const Results = ({resultsData}) => {

    const resultsRef = useRef(null);
    const onDrag = useRef(null);
    const refTitle = useRef(null);
    const resultsItemRef = useRef([]);
    const resultsItemRefImg = useRef([]);
    let slider = useRef(null);
    const sliderWrapper = useRef(null)
    var scrollTimer = -1;
    let scrollStart = false;

    useEffect(() => {

        let isDown = false;
        let startX;
        let scrollLeft;
        slider.current?.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - slider.current.offsetLeft;
            scrollLeft = slider.current.scrollLeft;
            cancelMomentumTracking();
            if (!scrollStart) {
                onDrag.current?.play()
            }

        });

        slider.current?.addEventListener('mouseleave', () => {
            isDown = false;
        });


        slider.current?.addEventListener('mouseup', () => {
            isDown = false;
            beginMomentumTracking();
            if (!scrollStart) {
                onDrag.current?.reverse()
            }
        });


        slider.current?.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.current.offsetLeft;
            const walk = (x - startX) * 0.5; //scroll-fast
            var prevScrollLeft = slider.current.scrollLeft;
            slider.current.scrollLeft = scrollLeft - walk;
            velX = slider.current.scrollLeft - prevScrollLeft;
        });

        slider.current?.addEventListener('wheel', (e) => {
            cancelMomentumTracking();
        });

        slider.current?.addEventListener('scroll', (e) => {
            //eslint-disable-next-line
            scrollStart = true;
            if (scrollTimer !== -1)
                clearTimeout(scrollTimer);
            //eslint-disable-next-line
            scrollTimer = window.setTimeout(function () {
                TouchEndHandler(true)
                scrollStart = false
            }, 100);
        });

        // Momentum

        var velX = 0;
        var momentumID;

        function beginMomentumTracking() {
            cancelMomentumTracking();
            momentumID = requestAnimationFrame(momentumLoop);
        }

        function cancelMomentumTracking() {
            cancelAnimationFrame(momentumID);
        }

        function momentumLoop() {
            slider.current.scrollLeft += velX;
            velX *= 0.95;
            if (Math.abs(velX) > 0.5) {
                momentumID = requestAnimationFrame(momentumLoop);
            }
        }

    }, [])

    useEffect(() => {
        initGsapResults();
        window.addEventListener('resize', scrollTriggerUpdate)
        return () => {
            window.removeEventListener('resize', scrollTriggerUpdate);
            ScrollTrigger.clearMatchMedia()
        }
        // eslint-disable-next-line
    }, []);

    const TouchEndHandler = () => {
        onDrag.current?.reverse()
    }

    const initGsapResults = () => {
        gsap.set(resultsItemRef.current , {
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%, 0 83%)'
        })

        setTimeout(() => {
            ScrollTrigger.matchMedia({
                // large
                "(min-width: 1280px)": function () {

                    let tl = new gsap.timeline({
                        scrollTrigger: {
                            trigger: resultsRef.current,
                            // pin: true,
                            scrub: 1,
                            start: '-250',
                            end: "+=500",
                        },

                    })
                        .to(sliderWrapper.current , {
                            pointerEvents: 'none'
                        })
                        .to(refTitle.current, {
                            yPercent: 72,
                            opacity: 0,
                            duration: .5,
                        },'-0.25')
                        .to(sliderWrapper.current, {
                            yPercent: -72,
                            duration: 1,
                        }, '-=0.4')
                        .to(sliderWrapper.current , {
                            pointerEvents: 'all'
                        },'-=0.6')


                    if(!tl.isActive() ) {
                        onDrag.current = new gsap.timeline({paused: true ,
                        })
                            .to(refTitle.current, {
                                opacity: 1,
                                duration: 0.25
                            },)
                            .to(resultsItemRef.current, 0.4, {
                                clipPath: 'polygon(10% 5%, 90% 5%, 90% 95%, 43% 95%, 10% 80%)'
                            }, '-=0.2')

                    }
                },

                "(max-width: 1279px)": function () {
                    new gsap.timeline({
                        scrollTrigger: {
                            invalidateOnRefresh: true,
                        }
                    })
                        .set(sliderWrapper.current, {
                            opacity: 1,
                            yPercent: 0,
                        })
                        .set(sliderWrapper.current, {
                            yPercent: 0,
                            opacity: 1
                        })
                }

            })
        }, delayForGsap)
    }

    return (
        <ResultsWrapper ref={resultsRef}>
            <ResultsTitle ref={refTitle}>
                <FadeInAnimation>
                    {resultsData.titleThin} <b> {resultsData.titleBold}</b>
                </FadeInAnimation>
            </ResultsTitle>
            <FadeInAnimation>
                <ResultsOverflow ref={sliderWrapper} className={'drag-cursor'}>
                    <ResultsList ref={slider}>
                        {resultsData.images?.content ? resultsData.images.content?.map((result, i) => {
                            return (
                                <ResultsItem ref={el => (resultsItemRef.current = [...resultsItemRef.current, el])}
                                             key={i}>
                                    <img ref={el => (resultsItemRefImg.current = [...resultsItemRefImg.current, el])}
                                         src={result.attrs.body[0].image} alt={i}/>
                                </ResultsItem>
                            )
                        }) :
                            resultsImgs?.map((result, i) => {
                                return (
                                    <ResultsItem ref={el => (resultsItemRef.current = [...resultsItemRef.current, el])}
                                                key={i}>
                                        <img ref={el => (resultsItemRefImg.current = [...resultsItemRefImg.current, el])}
                                            src={result.image} alt={i}/>
                                    </ResultsItem>
                                )
                            })
                        }
                    </ResultsList>
                </ResultsOverflow>
            </FadeInAnimation>
        </ResultsWrapper>
    );
}

export default Results;

import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const CookiesWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 998;
    display: flex;
    align-items: flex-end;
    pointer-events: none;
    //display:none;

    &.cookies--hidden{
        display: none;
    }
    &.cookies--use{
        pointer-events: initial;
        align-items: center;
        justify-content: center;
        padding: 109px 0;
        background: rgba(74, 43, 147, 0.3);
        @media ${device.tabletL} {
            padding: 50px 0;
        }
        @media ${device.tablet} {
            align-items: flex-end;
            padding: 94px 0 0 0;
        }
        .cookies__panel{
            display: none;
        }
        .cookies__modal{
            display: flex;
        }
    }
`;

export const CookiesPanel = styled.div`
  width: 100%;
  padding: 50px 0;
  background: ${COLORS.PURPLE};
  pointer-events: initial;
  //display:none;
  transition: 1s; 
  transform: translateY(101%);

  &.show{
    transform: translateY(0%);
  }

    @media ${device.tabletM} {
        padding: 30px 0;
    }

  > div{
    display: flex;
    column-gap: 75px;
    align-items: flex-end;
    justify-content: space-between;

    @media ${device.tabletM} {
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
    }
  }
`;

export const CookiesPanelLeft = styled.div`
    display: flex;
    column-gap: 30px;
    align-items: center;
    row-gap: 30px;
    @media ${device.tabletL} {
        flex-direction: column;
    }
`;

export const CookiesPanelLeftImg = styled.div`
    flex-shrink: 0;
    @media ${device.tabletL} {
        //display: none;
    }
    img{
        width: 120px;
        height: 120px;
        border-radius: 50% 50%;
        @media ${device.tabletM} {
            width: 90px;
            height: 90px;
        }

        @media ${device.tabletL} {
            width: 120px;
            height: 120px;
        }
    }
`;

export const CookiesPanelLeftText = styled(Typography.TextL)`
    @media ${device.tabletL} {
        
    }
`;

export const CookiesPanelLeftTextTitle = styled(Typography.H5)`
    color: ${COLORS.WHITE5};
    font-weight: 500;
    @media ${device.tabletL} {
        text-align: center;
        font-size: 24px;
        line-height: 38px;
    }
    @media ${device.mobile} {
        font-size: 20px;
        line-height: 32px;
    }
`;

export const CookiesPanelLeftTextParag = styled(Typography.TextL)`
    letter-spacing: -0.02em;
    color: ${COLORS.WHITE5};
    max-width: 690px;
    width: 100%;
    @media ${device.tabletM}{
        max-width: 100%;
    }
    @media ${device.tabletL} {
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
    @media ${device.mobile} {
        font-size: 14px;
        line-height: 26px;
    }
`;


export const CookiesPanelRight = styled.div`
    display: flex;
    column-gap: 20px;
    align-items: center;
    flex-shrink: 0;
    @media ${device.tabletM}{
        margin-left: auto;
    }

    @media ${device.tabletL} {
        max-width: 100%;
        width: 100%;

        > div{
            max-width: 50%;
            width: 100%;
        }
    }

    a{
        border: 1px solid ${COLORS.WHITE5};
        color: ${COLORS.WHITE5};
        letter-spacing: -0.02em;

        @media ${device.tabletL} {
            width: 100%;
            text-align: center;
        }

        @media ${device.mobile} {
            font-size: 16px;
            line-height: 30px;
            padding: 12px 20px;
        }
    }
`;

export const CookiesPanelRightText = styled(Typography.TextL)`
    color: ${COLORS.WHITE5};
`;
export const CookiesPanelRightButton = styled(Typography.TextL)`
    
`;


export const CookiesModal = styled.div`
    pointer-events: initial;
    width: 100%;
    height: 100%;
    background: ${COLORS.WHITE};
    flex-direction: column;
    //display: flex;
    display:none;
    max-width: 1430px;
    width: calc(100% - 120px);

    @media ${device.tablet} {
        width: 100%;
    }
`

export const ContainerCoolies = styled.div`
    max-width: 100%;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    @media ${device.tabletL} {
        padding-left: 20px;
        padding-right: 20px;
    }
`


export const CookiesModalHeader = styled.div`
    padding: 30px 0;
    border-bottom: 1px solid ${COLORS.BLACK5};
    > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media ${device.mobile} {
        padding: 10px 0;
    }
    
`
export const CookiesModalHeaderTitle = styled(Typography.H6)`
    font-weight: 500;
`

export const CookiesModalHeaderClose = styled.div`
    cursor: pointer;    

    svg{
        width: 30px;
        height: 30px;
    }
`

export const CookiesModalContent = styled.div`
    flex-grow: 1;
    padding: 20px 0 0 0;
    overflow: scroll;
    &::-webkit-scrollbar{
        height: 0;
        opacity: 0;
        width: 0;
    }
    &::-webkit-scrollbar-thumb{
        height: 0;
        opacity: 0 !important;
        display: none;
    }
`

export const CookiesModalContentDesc = styled(Typography.TextR)`
    margin-bottom: 40px;
    color: ${COLORS.SECONDARYBLACK};
    @media ${device.mobile} {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 26px;
    }
`

export const CookiesModalContentTitle = styled(Typography.H6)`
    color: ${COLORS.SECONDARYBLACK};
    font-weight: 500;
    margin-bottom: 25px;
`

export const CookiesModalContentItems = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 0 0 30px 0;
    @media ${device.tablet} {
        row-gap: 10px;
    }
}    
`

export const CookiesModalContentItem = styled.div`
    display: flex;
    align-items: flex-start;
    column-gap: 30px;
    justify-content: space-between;

    &.opened{
        .modal__item--name svg > g > path:nth-of-type(2){
            opacity: 0
        }
    }
    @media ${device.tablet} {
        column-gap: 20px;
    }

    .modal__item--name svg * {
        transition: .3s;
    }
`

export const CookiesModalContentItemLeft = styled.div`
    
`

export const CookiesModalContentItemLeftName = styled(Typography.TextXXL)`
    font-weight: 500;
    color: ${COLORS.SECONDARYBLACK};
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;

    @media ${device.tabletL} {
        font-size: 20px;
        line-height: 32px;
    }

    @media ${device.mobile} {
        font-size: 18px;
        line-height: 32px;

        svg{
            width: 20px;
            height: 20px;
        }
    }
`

export const CookiesModalContentItemLeftText = styled(Typography.TextR)`
    color: ${COLORS.SECONDARYBLACK};
    height: 0;
    overflow: hidden;
    transition: 0.5s;
    @media ${device.tablet} {
        font-size: 14px;
        line-height: 26px;
    }
`

export const CookiesModalContentItemRight = styled.div`
    flex-shrink: 0;  
    position: relative;
    display: flex;

    #checkboxSwitch{
        position: absolute;
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        border-radius: 50px;
        
        &.switch--activeAlwais{
            pointer-events: none;
        }
    }

    #checkboxSwitch+.switchSlider, #checkboxSwitch+.switchSlider::before, #checkboxSwitch+.switchSlider::after {
        transition: all .3s;
    }

    #checkboxSwitch+.switchSlider {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 42px;
        border-radius: 50px;
        cursor: pointer;
        pointer-events: none;
        @media ${device.tablet} {
            width: 60px;
            height: 32px;
        }
    }
    
    #checkboxSwitch+.switchSlider::after {
        border-radius: 50%;
        content: '';
        position: absolute;
        top: 3px;
        left: 6px;
        width: 30px;
        height: 30px;
        background-color: ${COLORS.SECONDARYBLACK};   
        top: 50%;
        transform: translateY(-50%);  
        @media ${device.tablet} {
            width: 20px;
            height: 20px;
            left: 4px;
        }     
    }

    #checkboxSwitch:checked+.switchSlider::after {
        left: 44px;
        background-color: ${COLORS.PURPLE};
        @media ${device.tablet} {
            left: 36px;
        }  
    }

    #checkboxSwitch+.switchSlider {
        border: 1px solid ${COLORS.SECONDARYBLACK};
    }
    #checkboxSwitch:checked+.switchSlider {
        background-color: ${COLORS.GREEN}; 
        border: 1px solid ${COLORS.GREEN};
    }
`
export const CookiesModalFooter = styled(Typography.TextR)`
    padding: 40px 0;
    border-top: 1px solid ${COLORS.BLACK5};
    @media ${device.tabletL} {
        padding: 40px 0;
    }
    @media ${device.mobile} {
        padding: 14px 0;
    }
    > div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media ${device.tabletL} {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 10px;
        }    
    }
`

export const CookiesModalFooterLeft = styled(Typography.TextR)`
    color: ${COLORS.SECONDARYBLACK};
    letter-spacing: -0.02em;
    @media ${device.mobile} {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 30px;
    }
`

export const CookiesModalFooterLeftLinks = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    @media ${device.mobile} {
        justify-content: center;
    }
`

export const CookiesModalFooterLeftLink = styled.a`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.88;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: ${COLORS.SECONDARYBLACK};
    text-underline-offset: 3px;
    transition: .3s;

    &:hover{
        color: ${COLORS.GREEN};
    }
`
export const CookiesModalFooterRight = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
    @media ${device.tabletL} {
        flex-wrap: nowrap;
        width: 100%;
        > div{
            width: 50%;

            > a{
                width: 100%;
                text-align: center;
            }
        }
    }

    @media ${device.mobile} {
        flex-direction: column;
        > div{
            width: 100%;
            > a{
                font-size: 16px;
                line-height: 30px;
                padding: 13px 20px 11px;
            }
        }
    }
`
export const CookiesModalFooterRightConfirm = styled.div`
    
`

export const CookiesModalFooterRightReject = styled.div`
    
`



import React, {useEffect, useState} from 'react';
import {StrivingImage, StrivingInner, StrivingLeft, StrivingList, StrivingRight, StrivingWrapper} from "./styled";
import Container from "../Container/Container";
import StrivingImage1 from '../../assets/img/striving.png'
import axios from "axios";
import { token, version } from '../../global/config';

function Striving(props) {

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/culture?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <StrivingWrapper>
            <Container>
                <StrivingInner>
                    <StrivingImage>
                        <img src={dataAxios?.length ? dataAxios?.[3].image : StrivingImage1} alt="Striving"/>
                    </StrivingImage>
                    <StrivingList>
                        <StrivingLeft>
                            {dataAxios?.length ? dataAxios?.[3].textLeft : 'Striving for excellence is out-dated'}
                        </StrivingLeft>
                        <StrivingRight>
                            {dataAxios?.length ? dataAxios?.[3].textRight : 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim.'}          
                        </StrivingRight>
                    </StrivingList>
                </StrivingInner>
            </Container>
        </StrivingWrapper>
    );
}

export default Striving;

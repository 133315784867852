import React, {useEffect, useRef} from 'react';
import {SplitText} from "arha-split-text";
import {gsap} from "gsap";

function SplittedAnimation({title}) {


    let compRef = useRef([]);

    useEffect(() => {

        setTimeout(() => {
            initSplitted();
        })


    }, [compRef]);

    const Word = ({children, i}) => (
        <span style={{display: 'inline-block', overflow: 'hidden' , lineHeight: 1}}
              className={`word word-${i}`}>
    {children}
            

  </span>
    )
    const Space = () => <span>&nbsp;</span>



    const Char = ({children, i}) => (
        <span  style={{display: 'inline-block'}}
               className={`word word-${i}`} ref={el => (compRef.current = [...compRef.current, el])}>
            {children}
        </span>
    )

    const initSplitted = () => {
        gsap.set(compRef.current, {
            y: 60,
            rotate: 5,
            opacity: 0
        })
        setTimeout(() => {
            gsap.to(compRef.current, 1, {
                scrollTrigger: {
                    trigger: compRef.current,
                    start: 'top 86%',
                    clearProps: true,
                    // ease: 'ease'
                },
                delay: 0.1,
                y: 0,
                rotate: 0,
                stagger: 0.02,
                opacity: 1,
            });
        }, 100)
    }

    return (
        <SplitText
            WordElement={Word}
            CharElement={Char}
            SpaceElement={Space}
        >
            {title}
        </SplitText>
    );
}


export default SplittedAnimation;

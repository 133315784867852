import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import {device} from "../../styles/responsive";

export const WorkingBlockWrapper = styled.div`
  padding-top: 160px;
  padding-bottom: 160px;
  @media ${device.tabletL} {
    padding-bottom: 100px;
  }
  @media ${device.mobile} {
    padding-top: 80px;
    padding-bottom: 40px;
  }
`;

export const WorkingBlockInner = styled.div``;

export const WorkingBlockTitle = styled(Typography.H2)`
  margin-bottom: 80px;
  max-width: 640px;
  @media ${device.mobile} {
    margin-bottom: 70px;
  }

`;

export const WorkingBlockList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media ${device.tabletL} {
    grid-template-columns: repeat(2, 1fr);
    gap: 80px;
  }
  @media ${device.mobile} {
    gap: 40px;
    grid-template-columns: 1fr;
    place-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const WorkingBlockItem = styled.div``;

export const WorkingBlockLogo = styled.div`
  width: 125px;
  height: 125px;
  margin-bottom: 21px;
  img{
    width: 100%;
    height: 100%;
  }
  @media ${device.mobile} {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6px;
    width: 70px;
    height: 70px;
  }
`;

export const WorkingBlockName = styled(Typography.H5)`
  color: ${COLORS.BLACK2};
  ${sharedProps};
  white-space: pre-line;
  @media ${device.mobile} {
    font-size: 24px;
    line-height: 32px;
  }
`;

import styled from "styled-components";
import {NavLink} from "react-router-dom";
import Typography from "../../styles/Typography";
import {device} from "../../styles/responsive";
import sharedProps from "../../styles/sharedProps";
import {COLORS, TRANSITIONS} from "../../styles/Theme";

export const ArticlesItemWrapper = styled(NavLink)`
  text-decoration: none;
`;

export const ArticlesItemImage = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 30px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  transition: clip-path ${TRANSITIONS.NORMAL};
  overflow: hidden;
  ${sharedProps};
  height: 392px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all ${TRANSITIONS.NORMAL};
    
  };

  &:hover {
    //clip-path: polygon(5% 10%, 95% 0, 95% 90%, 5% 95%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 84%);
    img {
      transform: translateZ(0) scale(1.1);
    }
  };

    @media ${device.tabletL} {
      height: ${props => props.fullImage ? "452px" : "452px"} 
    };
    
    @media ${device.tablet} {
        height: 350px;
        margin-bottom: 20px;
      }
  
    @media ${device.mobile} {
      height: 280px;
    }

}`;

export const ArticlesItemThumbnail = styled.div`
  height: 110%;
  width: 100%;
  @media ${device.tabletL} {
    height: 100%;
  }
`;

export const ArticlesItemName = styled(Typography.H6)`
  text-align: left;
  @media ${device.tabletL} {
    font-size: 28px;
    line-height: 1.5;
  }
  @media ${device.tablet} {
    font-size: 24px;
  }
  @media ${device.mobile} {
    font-size: 20px;
  }
`;

export const ArticlesItemCategory = styled(Typography.TextL)`
  text-align: left;
  @media ${device.tabletL} {
    font-size: 16px;
    color: ${COLORS.BLACK4};
    ${sharedProps};
  }
`;


import styled from "styled-components";
import Typography from "../../styles/Typography";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const ResultsItem = styled.div`
  user-select:none;
  pointer-events: none;
  width: 156px;
  margin-right: 10px;
  margin-left: 10px;
  flex-shrink: 0;
  padding-bottom: 40px;
  img{
    //clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%, 0 63%);
    transform: translateZ(0);
  }
  &:nth-child(3n) {
    margin-top: 60px;
    @media ${device.tabletL} {
      margin-top: 40px;
    }
  }

  &:nth-child(3n-1) {
    margin-top: 40px;
    @media ${device.tabletL} {
      margin-top: 20px;
    }
  }

  @media ${device.tabletL} {
    width: 80px;
    height: 311px;
  }
  @media ${device.mobile} {
    margin-right: 5px;
    margin-left: 5px;
  }

  ${props =>
    props && !props.draggable} {
    background: red;
  }
`;
export const ResultsWrapper = styled.div`
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  //overflow: hidden;
  height: 800px;
  margin-bottom: 160px;
  //padding-bottom: 160px;
  transform: translateZ(0);
  @media ${device.laptopSmall}{
    height: auto;
  }
  @media ${device.tabletL} {
    margin-bottom: 0;
    padding-top: 110px;
    padding-bottom: 120px;
  }
  @media ${device.mobile} {
    padding: 100px 0;
  }
  
  &:active{
    ${ResultsItem//}{
      // img{
      //   transform: scale(0.8);
      //}
    //}
  }
`;

export const ResultsList = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: fit-content;
  overflow: scroll;
  //transition: overflow 5s;
  backface-visibility: hidden;
  transform: translateZ(0);
  user-select:none;
  position: relative;
  &::-webkit-scrollbar{
    height: 0;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb{
    height: 0;
    opacity: 0 !important;
    display: none;
  }
`;


export const ResultsTitle = styled(Typography.H1)`
  position: relative;
  //z-index: 251;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 160px;
  pointer-events: none;
  z-index: 1;

  b, span {
    font-weight: normal;
    color: ${COLORS.PURPLE};
    ${sharedProps};
  }

  @media ${device.tabletL} {
    max-width: 470px;
    margin-bottom: 100px;
    b, span {
      display: block;
    }
  }
  @media ${device.mobile} {
    margin-bottom: 60px;
  }

`;

export const ResultsOverflow = styled.div`
  position: relative;
  //z-index: 2;
  display: flex;
  align-items: center;
`;


import React, {useEffect, useState} from 'react';
import {HelpImages, HelpInner, HelpRight, HelpSubText, HelpText, HelpWrapper} from "./styled";
import Container from "../Container/Container";
import Help1Image from '../../assets/img/Help/1.png'
import Help2Image from '../../assets/img/Help/2.png'
import Help3Image from '../../assets/img/Help/3.png'
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import axios from "axios";
import { token, version } from '../../global/config';


const Help = (props) => {

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/service?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <HelpWrapper>
            <Container>
                <FadeInAnimation>
                    <HelpInner>
                        <HelpImages>
                            <img src={dataAxios?.length ? dataAxios?.[2].image1 : Help1Image} alt="Help1"/>
                            <img src={dataAxios?.length ? dataAxios?.[2].image2 : Help2Image} alt="Help2"/>
                            <img src={dataAxios?.length ? dataAxios?.[2].image3 : Help3Image} alt="Help3"/>
                        </HelpImages>
                        <HelpRight>
                            <HelpText>
                                {dataAxios?.length ? dataAxios?.[2].title : 'We help translate your ideas into unique digital designs that creates a immersive impression.'}
                            </HelpText>
                            <HelpSubText>
                                {dataAxios?.length ? dataAxios?.[2].text : 'We provide value to your business in multitude of ways.'}   
                            </HelpSubText>
                        </HelpRight>
                    </HelpInner>
                </FadeInAnimation>
            </Container>
        </HelpWrapper>
    );
}

export default Help;

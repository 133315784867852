import Intro from "../../Components/Intro/Intro";
import ArticlesSearsh from "../../Components/ArticlesSearsh/ArticlesSearsh";
import ArticlesSearshResult from "../../Components/ArticlesSearshResult/ArticlesSearshResult";
import ArticlesFilter from "../../Components/ArticlesFilter/ArticlesFilter";
import styled from "styled-components";
import ArticlesItem from "../../Components/ArticlesItem/ArticlesItem";
import Container from "../../Components/Container/Container";
import {useEffect, useRef, useState} from "react";
import useWindowSize from "../../hooks/useWindowSize";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import {device} from "../../styles/responsive";
import axios from "axios";
import { token, version } from "../../global/config";
import Button from "../../Components/Button/Button";

const Articles = ({ArticlesItemsData}) => {

    const data = {
        ease: 0.1,
        current: 0,
        previous: 0,
        rounded: 0
    };

    const size = useWindowSize();

    const scrollContainer = useRef();

    let request;

    const skewScrolling = () => {
        //Set Current to the scroll position amount
        data.current = window.scrollY
        // Set Previous to the scroll previous position
        data.previous += (data.current - data.previous) * data.ease;
        // Set rounded to
        data.rounded = Math.round(data.previous * 100) / 100;

        // Difference between
        const difference = data.current - data.rounded;
        const acceleration = difference / size.width;
        const velocity = +acceleration;
        const skew = velocity * 15;

        //Assign skew and smooth scrolling to the scroll container

        if (scrollContainer.current) {
            scrollContainer.current.style.transform = `skewY(${skew}deg)`;
            request = requestAnimationFrame(() => skewScrolling());
        } else {
            cancelAnimationFrame(request);
        }

    };

    useEffect(() => {
        if (size.width >= 1279) {
            requestAnimationFrame(() => {
                skewScrolling();
            });

        } else {
            cancelAnimationFrame(request);
        }
        return () => cancelAnimationFrame(request);
        // eslint-disable-next-line
    }, []);

    const defaultTabName = 'All';

    const [activeTab, setActiveTab] = useState(defaultTabName);

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/articles?cv=1672239933&token=${token}&version=${version}`).then(res =>{
            const data = res.data.story.content.HomePage;
           
            setDataAxios(data)
        })

        //eslint-disable-next-line
    },[])


    const [dataAxiosPosts , setdataAxiosPosts] = useState([]);
    
    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v1/cdn/stories/?version=${version}&token=${token}&starts_with=posts/`).then(res =>{
            const data = res.data.stories;
            setdataAxiosPosts(data)
        })

        //eslint-disable-next-line
    },[]);


    return (
        <>
            <Intro title={dataAxios?.length ? dataAxios[0].title : 'Knowledge Bank'} text={dataAxios?.length ? dataAxios[0].label : 'Blog'}></Intro>
            <ArticlesSearsh SearshData={dataAxios?.length ? dataAxios[1] : 'Search articles'} setdataAxiosPosts={setdataAxiosPosts}></ArticlesSearsh>
            <ArticlesSearshResult ArticlesSearshResult={dataAxios?.length && dataAxios[3]}></ArticlesSearshResult>
            <ArticlesFilter ArticlesItemsData={dataAxiosPosts?.length ? dataAxiosPosts : ArticlesItemsData} activeTab={activeTab} setActiveTab={setActiveTab} defaultTabName={defaultTabName} setdataAxiosPosts={setdataAxiosPosts}/>
            <ArticlesWrapper className="articles__items">
                <Container>
                    <ArticlesList>
                        
                        {dataAxiosPosts?.length && dataAxiosPosts.map((work, idx) => {
                            
                                    const {image, name, subname, category} = work.content.test[5];
                                    const link = '/blog/' + work.slug;
                                    return (
                                        <FadeInAnimation key={idx}>
                                            <ArticlesItem image={image} name={name} subname={subname} link={link} category={category}></ArticlesItem>
                                        </FadeInAnimation>
                                    )
                            
                            })
                        }
                    </ArticlesList>
                    <ArticlesLoadMore className="articles--more">
                        <Button type={'link'} to={'#'}>{dataAxios?.length ? dataAxios[2].text : 'Load more'}</Button>
                    </ArticlesLoadMore>
                </Container>
            </ArticlesWrapper>
        </>
    );
}

const ArticlesWrapper = styled.div`
  margin-bottom: 250px;
  &.hidden{
    display: none;
  }
  @media ${device.tabletL} {
    margin-bottom: 180px;
  }
  @media ${device.mobile} {
    margin-bottom: 80px;
  }
`;

const ArticlesList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 60px;
  
  @media ${device.tabletL}{
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${device.tablet}{
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ArticlesLoadMore = styled.div`
    padding-top: 120px;
    display: flex;
    justify-content: center;

    @media ${device.tabletL} {
        padding-top: 90px;
    }
    @media ${device.mobile} {
        padding-top: 60px;
    }
`;

export default Articles


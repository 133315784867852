import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import styled from "styled-components";
import {device} from "../../styles/responsive";

export const RecentWrapper = styled.div`
  position: relative;
  padding-bottom: 30px;
  padding-top: 40px;
  margin-top: 30px;
  @media ${device.mobile} {
    padding-top: 20px;
    margin-top: 0px;
  }
  &:after{
    position: absolute;
    //content: '';
    left: 0;
    top: 0;
    width: 75%;
    height: 1px;
    ${sharedProps};
    background: ${COLORS.BLACK5};
  }
`;

export const Recentmarquee = styled.div`
  margin-bottom: 100px; 

  @media ${device.tabletL} {
    margin-bottom: 80px;
  }

  @media ${device.mobile} {
    margin-bottom: 50px;
  }
`;

export const RecentList = styled.div`
  display: flex;
  width: fit-content;
  transition: .05s;
  max-width: 100%;
  width: 100%;
  @media ${device.mobile} {
    flex-direction: column;
    row-gap: 50px;
  }

  > a{
    max-width: 50%;
    width: 100%;
    @media ${device.mobile} {
      max-width: 100%;
    }

    &:not(:last-child) {
        margin-right: 30px;

        @media ${device.tabletL} {
          margin-right: 40px;
        }

        @media ${device.mobile} {
          margin-right: 0px;
        }
    }

    &:last-child {
      padding-right: 0px;
    }

    > div:nth-of-type(1){
      max-width: 100%;
      width: 100%;
      @media ${device.tabletL} {
        height: 197px;
      }
    }
  }
`;

export const RecentOverflow = styled.div`
  display: flex;
  transition: .22s;

  &::-webkit-scrollbar {
    height: 0;
    display: none;
  }

`;


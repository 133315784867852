import styled from "styled-components";
import {device} from "../../styles/responsive";

export const VideoBannerWrapper = styled.div`
  display: flex;
  //align-items: center;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
  cursor: pointer;
  @media ${device.laptopL} {
    height: auto;
  }

  img, video {
    width: 100%;
    height: 125vh;
    object-fit: cover;
    @media ${device.laptopL} {
      height: 100vh;
    }

    @media ${device.tabletL} {
      height: 40vh;
      min-height: 470px;
    }
    @media ${device.mobile} {
      height: 314px;
      min-height: unset;
    }
  }

  svg.video-button {
    display: none;
    position: absolute;
    top: unset;
    left: unset;
    z-index: 3;
    right: 12%;
    bottom: 20%;
    @media ${device.tabletM}{
      display: flex;
    }
    @media ${device.tabletL} {
      width: 110px;
      height: auto;
      bottom: 10%;
      right: 10%;
    }
  }

  .rotator {
    transform-origin: 50% 50%;
    animation: moving 30s infinite linear;
  }

  @keyframes moving {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

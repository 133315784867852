import React from 'react';
import {
    TestimonialsFilterCount,
    TestimonialsFilterItem,
    TestimonialsFilterList,
    TestimonialsFilterWrapper
} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";

function TestimonialsFilter({testimonialsData}) {
    const defaultTypeCategory = 'All'
    const countType = (category) => {
        const countTypes = testimonialsData.filter(work => work.category === category);

        if (category === defaultTypeCategory) {
            
            if(testimonialsData.length < 10){
                return '0' + testimonialsData.length;
            }else{
                return testimonialsData.length;
            }
        } else {
            
            if(countTypes.length < 10){
                return '0' + countTypes.length;
            }else{
                return countTypes.length;
            }
        }
    }

    const getUniqueArray = () => {
        return [...new Set(testimonialsData.map(q => q.category))];
    }

    return (
        <TestimonialsFilterWrapper>
            <Container>
                <FadeInAnimation>
                    <TestimonialsFilterList>
                        <TestimonialsFilterItem active={true}>
                            <span data-text={defaultTypeCategory}>{defaultTypeCategory}</span>
                            <TestimonialsFilterCount>
                                <span data-text={countType(defaultTypeCategory)}>{countType(defaultTypeCategory)}</span>
                            </TestimonialsFilterCount>
                        </TestimonialsFilterItem>
                        {getUniqueArray()?.map((category, idx) => {
                            return (
                                <TestimonialsFilterItem key={idx}>
                                    <span data-text={category}>{category}</span>
                                    <TestimonialsFilterCount> <span
                                        data-text={countType(category)}>{countType(category)}</span>
                                    </TestimonialsFilterCount>
                                </TestimonialsFilterItem>
                            )
                        })}
                    </TestimonialsFilterList>
                </FadeInAnimation>
            </Container>
        </TestimonialsFilterWrapper>
    );
}

export default TestimonialsFilter;

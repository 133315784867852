import styled from "styled-components";
import Intro from "../../Components/Intro/Intro";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import Container from "../../Components/Container/Container";
import {useEffect, useState} from "react";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import {device} from "../../styles/responsive";
import axios from "axios";
import { token, version } from "../../global/config";



const Privacy = () => {

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        
        axios.get(`https://api.storyblok.com/v2/cdn/stories/privacy?cv=1673367623&token=${token}&version=${version}`).then(res =>{
            const data = res.data.story.content.HomePage;
           
            setDataAxios(data)
        })

        //eslint-disable-next-line
    },[])

    return (
        <>
            <PrivacyIntro>
                <Intro title={dataAxios?.length ? dataAxios[0].title : 'The Gods love your Privacy.'} text={dataAxios?.length ? dataAxios[0].label : 'Privacy Policy'}></Intro>
            </PrivacyIntro>
            <PrivacyWrapper>
                <Container>

                {dataAxios?.length && dataAxios[1].content?.map((part, i) => {
                        return (

                            <PrivacyContentPart key={i}>
                                {part.partIntro.map((single, idx) => {
                                    
                        
                                    if(single.component === "ContentBlockPartTitlePrivacy"){
                                        return (
                                            <FadeInAnimation key={idx}>
                                                <PrivacyContentPartTitle>{single?.text ? single.text : 'Things to keep in mind'}</PrivacyContentPartTitle>
                                            </FadeInAnimation>
                                        )
                                    }else  if(single.component === "ContentBlockPartParagsPrivacy"){
                                        return (
                                            <FadeInAnimation key={idx}>
                                                <PrivacyContentPartParags>
                                                    {single?.parags && single.parags.map((text, i) => {
                                                        return (
                                                            <p key={i}>
                                                                {text.text}
                                                            </p>
                                                        )
                                                    })}
                                                </PrivacyContentPartParags>
                                            </FadeInAnimation>
                                        )     
                                    }else{
                                        return null
                                    }
                                                    
                                })}
                            </PrivacyContentPart>
                        )
                    })}

                </Container>
            </PrivacyWrapper>
        </>
    );
}

const PrivacyIntro = styled.div`
    h1{
        max-width: 870px;
        width: 100%;
    }
`;

const PrivacyWrapper = styled.div`
    padding-bottom: 180px;

    @media ${device.tabletL}{
        padding-bottom: 120px;
    }

    @media ${device.mobile}{
        padding-bottom: 80px;
    }

    > div > div:not(:last-child){
        padding-bottom: 100px;

        @media ${device.tabletL}{
            padding-bottom: 68px;
        }

        @media ${device.mobile}{
            padding-bottom: 44px;
        }
    }
`;

const PrivacyContentPart = styled.div`
    > div:not(:last-child){
        padding-bottom: 20px;

        @media ${device.mobile}{
            padding-bottom: 16px;
        }
    }
`;

const PrivacyContentPartTitle = styled(Typography.H3)`
    margin: 10px 0;
    font-weight: 500;
    color: ${COLORS.SECONDARYBLACK};
`;

const PrivacyContentPartParags = styled.div`
    > p:not(:last-child){
        margin-bottom: 32px;

        @media ${device.mobile}{
            margin-bottom: 18px;
        }
    }

    p{
        font-weight: 400;
        font-size: 28px;
        line-height: 42px;
        letter-spacing: -0.02em;
        color: ${COLORS.SECONDARYBLACK};

        @media ${device.tabletL}{
            font-size: 24px;
            line-height: 32px;
        }

        @media ${device.mobile}{
            font-size: 18px;
            line-height: 24px;
        }
    }
`;

export default Privacy


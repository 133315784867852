import styled from "styled-components";
import Typography from "../../styles/Typography";
import sharedProps from "../../styles/sharedProps";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";

export const WhoWrapper = styled.div`
  padding-top: 140px;
  @media ${device.tabletL} {
    padding-top: 120px;
  }
  @media ${device.mobile} {
    padding-top: 100px;
  }
`;

export const WhoTitle = styled(Typography.H2)`
  margin-bottom: 60px;
  b{
    font-weight: normal;
    color: ${COLORS.ORANGE};
    ${sharedProps};
  }
  @media ${device.mobile} {
    margin-bottom: 50px;
  }
`;

export const WhoInner = styled.div``;

export const WhoList = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 80px 30px;
  @media ${device.tabletL} {
    gap: 80px 40px;
  }
  @media ${device.mobile} {
    grid-template-columns: 1fr;
    gap: 70px;
  }
`;

export const WhoItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const WhoImage = styled.div`
  width: 100%;
  margin-bottom: 40px;
  @media ${device.tabletL} {
    margin-bottom: 20px;
  }
`;

export const WhoName = styled(Typography.H6)`
  font-weight: 500;
  color: ${COLORS.PURPLE};
  ${sharedProps};
`;

export const WhoPosition = styled(Typography.TextXXL)`
  color: ${COLORS.BLACK2};
  margin-bottom: 30px;
  ${sharedProps};
  @media ${device.tabletL} {
    font-size: 20px;
  }
  @media ${device.mobile} {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

export const WhoText = styled(Typography.H4)`
  width: 80%;
  @media ${device.tabletL} {
    width: 100%;
    font-size: 28px;
  }
  @media ${device.mobile} {
    font-size: 24px;
  }
`;


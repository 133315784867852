import styled from "styled-components";
import {device} from "../../styles/responsive";
import Typography from "../../styles/Typography";
import {COLORS, TRANSITIONS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";

export const TestimonialsFilterWrapper = styled.div`
  margin-bottom: 15px;

  @media ${device.tabletL} {
    margin-top: -75px;
    margin-bottom: 120px;
  }
  @media ${device.mobile} {
    margin-top: -20px;
    margin-bottom: 40px;

  }
`;

export const TestimonialsFilterList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  @media ${device.tabletL} {
    margin-left: -20px;
  }
  @media ${device.mobile} {
    flex-wrap: nowrap;
    align-items: center;
    margin-left: -45px;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 0;
      display: none;
    }

    padding-left: 20px;
    padding-right: 20px;
  }

`;

export const TestimonialsFilterCount = styled(Typography.TextS500)`
  overflow: hidden;
  margin-left: 6px;
  border-radius: 50px;
  color: ${COLORS.BLACK3};
  ${sharedProps};
  padding: 5px 12px 3px;
  min-width: 42px;
  text-align: center;
  border: 1px solid ${COLORS.BLACK};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${TRANSITIONS.FAST};
  @media ${device.tabletL} {
    position: relative;
    top: -2px;
  
  }
`;

export const TestimonialsFilterItem = styled(Typography.TextXL)`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  color: ${COLORS.SECONDARYBLACK};
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px;

  ${sharedProps};

  @media ${device.tabletL} {
    margin-bottom: 16px;
    margin-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    //font-size: 28px;
  }

  @media ${device.mobile} {
    margin-top: 0;
    margin-bottom: 0;
    min-width: fit-content;
  }

  &:not(:last-child) {
    &:after {
      content: '';
      ${sharedProps};
      background: ${COLORS.SECONDARYBLACK};
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      transform: translateY(-50%);
      top: 50%;
      right: 0px;
    }

  }

  &:hover {
    span {
      &:after, &:before {
        color: ${COLORS.SECONDARYBLACK};
      }

      &:before {
        top: -135%;
      }

      &:after {
        top: 0;
      }
    }
    >span{
      &:after, &:before{
        color: ${COLORS.BLACK};
      }
    }
    >div{
      border: 1px solid ${COLORS.BLACK};
    }
  }

  span {
    position: relative;
    display: block;
    color: transparent;

    &:after, &:before {
      ${sharedProps};
      color: ${COLORS.BLACK4};
      display: block;
      position: absolute;
      content: attr(data-text);
      transition: ${TRANSITIONS.FAST};
    }

    &:before {
      top: 0%;
    }

    &:after {
      top: 135%;
    }
  }

  > span {
    &:after, &:before {
      color: ${COLORS.BLACK4};
    }
  }

  >div{
    border: 1px solid ${COLORS.BLACK4};
  }

  ${props => props && !props.active} {
    color: ${COLORS.PURPLE};

    ${TestimonialsFilterCount} {
      background: ${COLORS.PURPLE};
      border-color: ${COLORS.PURPLE};
      color: ${COLORS.WHITE5};
    }

    span {
      &:after, &:before {
        color: ${COLORS.WHITE};
      }
    }

    > span {
      &:after, &:before {
        color: ${COLORS.PURPLE} !important;
      }
    }

    &:hover {
      span {
        &:after, &:before {
          color: ${COLORS.WHITE};
        }
      }
    }
  }

`;


import React from 'react'
import {SearshResult, SearshResultEmpty, SearshResultWrapp} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import ArticlesImgEmpty from '../../assets/img/articles/searsh_img_empty.svg'

function ArticlesSearshResult({ArticlesSearshResult}) {

    return (
        <>
            <SearshResultWrapp className='searsh__result--wrapp'>
                <Container>
                    <FadeInAnimation>
                        <SearshResult className='searsh__result--num'>
                            <div>{ArticlesSearshResult ? ArticlesSearshResult.resultTitle : 'Search results'}</div>
                            <p><span>{'0 '}</span>{ArticlesSearshResult ? ArticlesSearshResult.resultCountText : ' articles found'}</p>
                        </SearshResult>
                        <SearshResultEmpty className='searsh__result--empty'>
                            <p>{ArticlesSearshResult ? ArticlesSearshResult.resultEmptyText : 'Sorry. We haven’t made a article with that keyword yet. Working on it!'}</p>
                            <img src={ArticlesSearshResult ? ArticlesSearshResult.resultEmptyImage : ArticlesImgEmpty} alt={'IMG'}/>
                        </SearshResultEmpty>
                    </FadeInAnimation>
                </Container>
            </SearshResultWrapp>
            
        </>
        
    );
}

export default ArticlesSearshResult;

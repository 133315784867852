import React, {useState} from 'react';

import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";

import {
    ErrorMessageSpan,
    FormAttachment, FormAttachmentItem, FormAttachmentList, FormAttachmentWrapper, FormAttachmentIcon,
    FormCheckbox,
    FormCheckboxes,
    FormCheckboxField,
    FormInput,
    FormInputs,
    FormLine,
    FormInputWrapper,
    FormTag,
    FormTitle,
    FormCheckboxError, FormCheckboxesWrapper
} from "./styled";
import InputLineAnimation from "../../styles/Animations/InputLineAnimation";
import {ErrorMessage} from "@hookform/error-message";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import Button from "../Button/Button";
import TagContact from "../TagContact/TagContact";
import {useLocation} from "react-router-dom";


const schema = yup.object().shape({
    checkbox: yup.array().min(1),
    checkbox2: yup.array().min(1),
    firstName: yup.string().required(),
    Email: yup.string().email('Invalid email format').required('Invalid email format'),
    Project: yup.string().required(),

});


function Form(scrollerContainer) {
    const {register, reset, handleSubmit, formState: {errors}} = useForm({resolver: yupResolver(schema)});
    const [files, setFiles] = useState([]);
    const location = useLocation();


    const onFormSubmit = (data) => {
        alert('success');
        reset();
        setFiles([])
    };

    const onHandleChange = (e) => {
        const filesList = e.target.files;

        setFiles([...files, ...filesList]);
    };

    const checkKeyDown = (e) => {
        if(e.target.value.length > 0){
            e.target.classList.add('entered--text')
        }else{
            e.target.classList.remove('entered--text')
        }
    }

    const handleDeleteFile = (id) => {

        const filesFilter = files.filter((f) => f.lastModified !== id);

        setFiles(filesFilter);

    }

    const handleTriggerSubmit = () => {
        console.log(1);
    }
    

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <FadeInAnimation>
                <FormTitle> {location?.pathname === "/culture" ? 'Interested in joining us?' : null}</FormTitle>
                <FormTag> {location?.pathname === "/culture" ? 'Role preference' : 'Project'}</FormTag>
                <FormCheckboxesWrapper>
                    <FormCheckboxes>
                        <TagContact className="formCheckBox"  type={'nolink'}>
                            <FormCheckbox htmlFor={location?.pathname === "/culture" ? 'UI/UX designer' : 'UI/UX'}>
                                <input id={location?.pathname === "/culture" ? 'UI/UX designer' : 'UI/UX'} type="checkbox" value={location?.pathname === "/culture" ? 'UI/UX designer' : 'UI/UX'} {...register("checkbox", {})} />
                                <FormCheckboxField><span>{location?.pathname === "/culture" ? 'UI/UX designer' : 'UI/UX'}</span></FormCheckboxField>
                            </FormCheckbox>
                        </TagContact>
                        <TagContact className="formCheckBox"  type={'nolink'}>
                            <FormCheckbox htmlFor={location?.pathname === "/culture" ? 'Illustrator' : 'Illustrations'}>
                                <input id={location?.pathname === "/culture" ? 'Illustrator' : 'Illustrations'} type="checkbox"
                                    value={location?.pathname === "/culture" ? 'Illustrator' : 'Illustrations'} {...register("checkbox", {})} />
                                <FormCheckboxField><span>{location?.pathname === "/culture" ? 'Illustrator' : 'Illustrations'}</span></FormCheckboxField>
                            </FormCheckbox>
                        </TagContact>
                        
                        <TagContact className="formCheckBox"  type={'nolink'}>
                            <FormCheckbox htmlFor={location?.pathname === "/culture" ? 'Graphic Designer' : 'Logo'}>
                                <input id={location?.pathname === "/culture" ? 'Graphic Designer' : 'Logo'} type="checkbox" value={location?.pathname === "/culture" ? 'Graphic Designer' : 'Logo'} {...register("checkbox", {})} />
                                <FormCheckboxField><span>{location?.pathname === "/culture" ? 'Graphic Designer' : 'Logo'}</span></FormCheckboxField>
                            </FormCheckbox>
                        </TagContact>
                        
                        <TagContact className="formCheckBox"  type={'nolink'}>
                            <FormCheckbox htmlFor={location?.pathname === "/culture" ? 'Brand strategist' : 'website'}>
                                <input id={location?.pathname === "/culture" ? 'Brand strategist' : 'website'} type="checkbox" value={location?.pathname === "/culture" ? 'Brand strategist' : 'website'} {...register("checkbox", {})} />
                                <FormCheckboxField><span>{location?.pathname === "/culture" ? 'Brand strategist' : 'website'}</span></FormCheckboxField>
                            </FormCheckbox>
                        </TagContact>
                        
                        <TagContact className="formCheckBox"  type={'nolink'}>
                            <FormCheckbox htmlFor={location?.pathname === "/culture" ? 'Developer' : 'Branding'}>
                                <input id={location?.pathname === "/culture" ? 'Developer' : 'Branding'} type="checkbox" value={location?.pathname === "/culture" ? 'Developer' : 'Branding'} {...register("checkbox", {})} />
                                <FormCheckboxField><span>{location?.pathname === "/culture" ? 'Developer' : 'Branding'}</span></FormCheckboxField>
                            </FormCheckbox>
                        </TagContact>
                        
                        {location?.pathname === "/culture" ? null : 
                            <TagContact className="formCheckBox"  type={'nolink'}>
                                <FormCheckbox htmlFor={'App design'}>
                                    <input id={'App design'} type="checkbox"
                                        value={'App design'} {...register("checkbox", {})} />
                                    <FormCheckboxField><span>App design</span></FormCheckboxField>
                                </FormCheckbox>
                            </TagContact>
                        }
                        
                    </FormCheckboxes>
                    <FormCheckboxError>
                        {errors.checkbox && 'At least one checkobox must be selected'}
                    </FormCheckboxError>
                </FormCheckboxesWrapper>
                <FormInputs>
                    <FormInputWrapper >
                        <FormInput error={errors.firstName && true}
                                   placeholder={'Your Name'} {...register("firstName", {required: true})}
                                   isError={errors.firstName}
                                   onKeyUp={(e) => checkKeyDown(e)}
                                   />
                        <InputLineAnimation>
                            <FormLine className='input__underline'> </FormLine>
                        </InputLineAnimation>
                        <ErrorMessage errors={errors} name="firstName" message="Enter first name"
                                      render={({message}) => <ErrorMessageSpan>{message}</ErrorMessageSpan>}/>

                    </FormInputWrapper>
                    <FormInputWrapper>
                        <FormInput error={errors.Email && true}
                                   placeholder={'Your email'} {...register("Email", {required: true})} 
                                   onKeyUp={(e) => checkKeyDown(e)}/>
                        <InputLineAnimation>
                            <FormLine className='input__underline'> </FormLine>
                        </InputLineAnimation>
                        <ErrorMessage errors={errors} name="Email" message="Enter Email"
                                      render={({message}) => <ErrorMessageSpan>{message}</ErrorMessageSpan>}/>

                    </FormInputWrapper>
                    <FormInputWrapper>
                        <FormInput error={errors.Project && true}
                                   placeholder={location?.pathname === "/culture" ? 'Tell us about yourself' : 'Project brief'} {...register("Project", {required: true})} onKeyUp={(e) => checkKeyDown(e)}/>
                        <InputLineAnimation>
                            <FormLine className='input__underline'> </FormLine>
                        </InputLineAnimation>
                        <ErrorMessage errors={errors} name="Project" message="Enter Project"
                                      render={({message}) => <ErrorMessageSpan>{message}</ErrorMessageSpan>}/>
                    </FormInputWrapper>
                </FormInputs>
            </FadeInAnimation>
            {location?.pathname === "/culture" || location?.pathname === "/contacts" ? null : 
                <FadeInAnimation>
                    <FormTag>How’d you find about us?</FormTag>
                    <FormCheckboxesWrapper>
                        <FormCheckboxes>
                            <TagContact className="formCheckBox"  type={'nolink'}>
                                <FormCheckbox htmlFor={'Social'}>
                                    <input id={'Social'} value={'Social'} type="checkbox" {...register("checkbox2", {})} />
                                    <FormCheckboxField><span>Social</span></FormCheckboxField>
                                </FormCheckbox>
                            </TagContact>
                            
                            <TagContact className="formCheckBox"  type={'nolink'}>
                                <FormCheckbox htmlFor={'behance'}>
                                    <input id={'behance'} value={'behance'} type="checkbox" {...register("checkbox2", {})} />
                                    <FormCheckboxField><span>behance</span></FormCheckboxField>
                                </FormCheckbox>
                            </TagContact>
                            
                            <TagContact className="formCheckBox"  type={'nolink'}>
                                <FormCheckbox htmlFor={'Direct talk'}>
                                    <input id={'Direct talk'} value={'Direct talk'}
                                        type="checkbox" {...register("checkbox2", {})} />
                                    <FormCheckboxField><span>Direct talk</span></FormCheckboxField>
                                </FormCheckbox>
                            </TagContact>
                            
                            <TagContact className="formCheckBox"  type={'nolink'}>
                                <FormCheckbox htmlFor={'Website'}>
                                    <input id={'Website'} value={'Website'} type="checkbox" {...register("checkbox2", {})} />
                                    <FormCheckboxField><span>Website</span></FormCheckboxField>
                                </FormCheckbox>
                            </TagContact>
                            
                            <TagContact className="formCheckBox"  type={'nolink'}>
                                <FormCheckbox htmlFor={'Friends'}>
                                    <input id={'Friends'} value={'Friends'} type="checkbox" {...register("checkbox2", {})} />
                                    <FormCheckboxField><span>Friends</span></FormCheckboxField>
                                </FormCheckbox>
                            </TagContact>
                            
                        </FormCheckboxes>
                        <FormCheckboxError>
                            {errors.checkbox2 && 'At least one checkobox must be selected'}
                        </FormCheckboxError>
                    </FormCheckboxesWrapper>
                </FadeInAnimation>
            }

            <FadeInAnimation>
                <FormAttachmentWrapper>
                    <FormAttachment htmlForm={'attachment'}>
                        <input multiple id={'attachment'} type="file" {...register("File", {})}
                               onChange={(e) => onHandleChange(e)}/>
                                
                        <FormTag>
                            <FormAttachmentIcon>
                                <svg width="30" height="38" viewBox="0 0 30 38" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.251 20.625L22.4776 9.82036C23.0929 9.20497 23.4387 8.37033 23.4387 7.50005C23.4387 6.62976 23.0929 5.79512 22.4776 5.17973C21.8622 4.56435 21.0275 4.21863 20.1572 4.21863C19.287 4.21863 18.4523 4.56435 17.8369 5.17973L6.34084 16.6524C5.28723 17.706 4.69531 19.135 4.69531 20.625C4.69531 22.1151 5.28723 23.5441 6.34084 24.5977C7.39445 25.6513 8.82346 26.2432 10.3135 26.2432C11.8035 26.2432 13.2325 25.6513 14.2862 24.5977L23.9072 15"
                                        stroke="#A19E9A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    {/* <line x1="5" y1="37.5" x2="24" y2="37.5" stroke="#A19E9A"/> */}
                                </svg>
                            </FormAttachmentIcon>
                            Attachment
                        </FormTag>
                    </FormAttachment>
                    <FormAttachmentList>
                        {files?.map((m, idx) => {
                            return (
                                <FormAttachmentItem key={idx}>
                                    <span>{m.name}</span>
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                        handleDeleteFile(m.lastModified);
                                    }}>
                                        <path d="M23.4375 6.5625L6.5625 23.4375" stroke="#130C03" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M23.4375 23.4375L6.5625 6.5625" stroke="#130C03" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </FormAttachmentItem>
                            )
                        })}
                    </FormAttachmentList>
                </FormAttachmentWrapper>
                <Button type='submit' onClick={() => handleTriggerSubmit()}>Send details</Button>
                <button type="submit" style={{display: 'none'}}/>
            </FadeInAnimation>
        </form>
    );
}

export default Form;

import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Intro from "../../Components/Intro/Intro";
import VideoBanner from "../../Components/videoBanner/videoBanner";
import VideoBannerImage from '../../assets/img/bannerService.png'
import Help from "../../Components/Help/Help";
import ServicesBlock from "../../Components/ServicesBlock/servicesBlock";
import Tell from "../../Components/Tell/Tell";
import Technologies from "../../Components/Technologies/Technologies";
import axios from "axios";
import { token, version } from '../../global/config';

const Service = (props) => {
    const serviceData = [
        {
            marquee: 'UI/UX',
            marqueeSpeed: 3.5,
            title: 'To improve is to impress. All that one can do.',
            text: 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim. \n \n Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim egestas dignissim lobortis.',
            list: [
                {
                    name: 'Ux research',
                },
                {
                    name: 'User journey',
                },
                {
                    name: 'Wireframing',
                },
                {
                    name: 'UI designs',
                },
                {
                    name: 'Websites',
                },
                {
                    name: 'Apps',
                }

            ],
            projects: [
                {
                    link: '/work',
                    image: require('../../assets/img/works/1.png'),
                    name: 'Cultures Calendar'
                },
                {
                    link: '/work',
                    image: require('../../assets/img/works/4.png'),
                    name: 'BoostAPI'
                },

            ],
            latestText: 'Our latest UI/UX projects',

        },
        {
            marquee: 'Logo design',
            marqueeSpeed: 5.5,
            title: 'Logo’s job isn’t to sell, but to help identify.',
            text: 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim. \n \n Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim egestas dignissim lobortis.',
            list: [
                {
                    name: 'Research',
                },
                {
                    name: 'Moodboards',
                },
                {
                    name: 'Rough sketches',
                },
                {
                    name: 'Explorations',
                },
                {
                    name: 'Presentation',
                },
                {
                    name: 'Deliverables',
                }

            ],
            projects: [
                {
                    link: '/work',
                    image: require('../../assets/img/works/3.png'),
                    name: 'Shopexa'
                },
                {
                    link: '/work',
                    image: require('../../assets/img/works/5.png'),
                    name: 'Brio'
                },

            ],
            latestText: 'Our latest UI/UX projects',


        },
        {
            marquee: 'Branding',
            marqueeSpeed: 4,
            title: 'Your brand is a story that unfolds to your customers.',
            text: 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim. \n \n Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim egestas dignissim lobortis.',
            list: [
                {
                    name: 'Strategy',
                },
                {
                    name: 'Moonboards',
                },
                {
                    name: 'Brand book',
                },
                {
                    name: 'Identity',
                },
                {
                    name: 'Logo workshop',
                },
                {
                    name: 'Positioning',
                }

            ],
            projects: [
                {
                    link: '/work',
                    image: require('../../assets/img/works/7.png'),
                    name: 'Speco'
                },
            ],
            latestText: 'Our latest UI/UX projects',


        },
        {
            marquee: 'Graphic design',
            marqueeSpeed: 5.5,
            title: 'Tech is the best when it bring people together.',
            text: 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim. \n \n Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim egestas dignissim lobortis.',
            list: [
                {
                    name: 'Social Posts',
                },
                {
                    name: 'Posters',
                },
                {
                    name: 'Billboards',
                },
                {
                    name: 'Banners',
                },
                {
                    name: 'Pick decks',
                },
                {
                    name: 'More...',
                }

            ],
            projects: [
                {
                    link: '/work',
                    image: require('../../assets/img/works/7.png'),
                    name: 'Speco'
                },
            ],
            latestText: 'Our latest UI/UX projects',

        },
        {
            marquee: 'Development',
            marqueeSpeed: 5.5,
            title: 'Tech is the best when it brings people together.',
            text: 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim. \n \n Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim egestas dignissim lobortis.',
            list: [
                {
                    name: 'HTML5',
                },
                {
                    name: 'Sass',
                },
                {
                    name: 'Javascript',
                },
                {
                    name: 'React framework',
                },
                {
                    name: 'Jquery',
                },
                {
                    name: 'More...',
                }

            ],
            latestText: 'Our latest technologies we use.',
            projects: [
            ]

        },
    ]

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/service?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <>  
            <IntroService>
                <Intro title={dataAxios?.length ? dataAxios?.[0].title : 'Where we \n come in.'} text={dataAxios?.length ? dataAxios?.[0].label : 'Services'}/>
            </IntroService>
            <VideoBanner VideoBannerImage={dataAxios?.length ? dataAxios?.[1].image : VideoBannerImage} hiddenCursor={true}/>
            <Help/>
            <ServicesBlock  serviceData={dataAxios?.length ? dataAxios?.[3].items : serviceData}/>
            <Technologies/>
            <Tell tellData={dataAxios?.length && dataAxios[6]}/>
        </>
    );
}

const IntroService = styled.div`
    h1{
        max-width: 680px;
        width: 100%
    }

`;

export default Service;

import React, {useState} from 'react';

import {
    TestimonialsItemInner,
    TestimonialsItemLeft, TestimonialsItemList, TestimonialsItemName,
    TestimonialsItemRight,
    TestimonialsItemText,
    TestimonialsItemWrapper, TestimonialsItemYear
} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
const TestimonialsItem = ({testimonialsData}) => {

    const [index, setIndex] = useState(null);
    const location = useLocation();

    

    function MouseMoveLeftBlock(event, index) {
        var targetElem = event.target.closest('.testimonials__left');

        var pointerElem = targetElem.querySelector('.testimonials__left--pic');

        var posImgX = event.pageX - targetElem.getBoundingClientRect().left
        var posImgY = event.pageY - targetElem.getBoundingClientRect().top

        var crd = targetElem.getBoundingClientRect();
        var activePointer = crd.left <= event.pageX && event.pageX <= crd.right && crd.top <= event.pageY && event.pageY <= crd.bottom;

        setIndex(index)

        requestAnimationFrame(function movePointer() {
            if (activePointer) {
                setIndex(index)
                pointerElem.style.left = Math.floor(posImgX) + 'px';
                pointerElem.style.top = Math.floor(posImgY) + 'px';
            } else {
                
                setIndex(null)
            }
        });

    }

    function MouseLeaveLeftBlock(event) {
        requestAnimationFrame(function hidePointer() {
            setIndex(null)
        });
    }


    return (
        
        <TestimonialsItemWrapper>
            <Container>
                <TestimonialsItemList>
                    {testimonialsData.map((testimonials, idx) => {
                        const {name, category, text, year,image, id} = testimonials;
                        // const id = 'testimonial' + (idx + 1)
                        return (
                            
                            <FadeInAnimation className={location.search.split("?")[1] === id ? 'testimonial--order1' : 'testimonial--order2'} id={id} key={idx}>
                                <TestimonialsItemInner data-cat={category}>
                                    <TestimonialsItemLeftWrapper onMouseLeave={(event)=>{MouseLeaveLeftBlock(event, idx)}} onMouseMove={(event)=>{MouseMoveLeftBlock(event, idx)}} className='testimonials__left'>
                                        <TestimonialsItemLeft>
                                            {name && <TestimonialsItemName>{name}</TestimonialsItemName>}
                                            {year && <TestimonialsItemYear>2022 - Present</TestimonialsItemYear>}
                                        </TestimonialsItemLeft>
                                        <TestimonialsItemLeftHover showPic={index===idx} className='testimonials__left--pic'>
                                            <img src={image} alt="img"/>
                                        </TestimonialsItemLeftHover>
                                    </TestimonialsItemLeftWrapper>
                                    <TestimonialsItemRight>
                                        {text && <TestimonialsItemText>{text}</TestimonialsItemText>}
                                    </TestimonialsItemRight>
                                </TestimonialsItemInner>
                            </FadeInAnimation>
                        )
                    })}
                </TestimonialsItemList>
            </Container>
        </TestimonialsItemWrapper>
    );
}

export default TestimonialsItem;

const TestimonialsItemLeftWrapper = styled.div`
  width: 50%;
`;
const TestimonialsItemLeftHover = styled.div`
    opacity: 0;
    visibility: hidden;
    ${props => !props.showPic}{
        opacity: 1;
        visibility: visible;
    }
`;

import React, {useRef, useEffect} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {delayForGsap} from "../../functions/constants";

const InputLineAnimation = ({
                             children,
                             wrapperElement = "div",
                             direction = null,
                             delay = 0,
                             ...props
                         }) => {
    let lineRef = useRef();
    gsap.registerPlugin(ScrollTrigger);

    const {pathname} = useLocation();


    useEffect(() => {
        initGsapFades();

        //eslint-disable-next-line
    }, [lineRef, delay, pathname]);

    const initGsapFades = () => {
        gsap.set(lineRef.current, {
            width: '0%'
        })
        setTimeout(() => {
            gsap.to(lineRef.current, 0.5, {
                scrollTrigger: {
                    trigger: lineRef.current,
                    // start: 'top 90%',
                },
                width: '100%',
                delay: delay,
                ease: 'none'
            });
        }, delayForGsap)

    }


    return (
        <LineWrapp ref={lineRef} {...props}>
            {children}
        </LineWrapp>
    );


};
const LineWrapp = styled.div`
  //will-change: opacity;
  backface-visibility: hidden;
  transition: none;
  position: relative;
`;

export default InputLineAnimation;

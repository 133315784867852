import React from 'react';
import {WorkFilterCount, WorkFilterItem, WorkFilterList, WorkFilterWrapper} from "./styled";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import Container from "../Container/Container";

const WorkFilter = ({WorksItemsData, activeTab, setActiveTab, defaultTabName}) => {

    const countType = (category) => {
        const countTypes = WorksItemsData.filter(work => work.category === category);

        if (category === defaultTabName) {
            
            if(WorksItemsData.length < 10){
                return '0' + WorksItemsData.length;
            }else{
                return WorksItemsData.length;
            }
        } else {
            if(countTypes.length < 10){
                return '0' + countTypes.length;
            }else{
                return countTypes.length;
            }
            
        }
    }

    const getUniqueArray = () => {
        return [...new Set(WorksItemsData.map(q => q.category))];
    }

    const handleClick = (tab) => {
        setActiveTab(tab);
    }

    return (
        <WorkFilterWrapper>
            <Container mobileOff={true}>
                <FadeInAnimation>
                    <WorkFilterList>
                        <WorkFilterItem onClick={() => {
                            handleClick(defaultTabName)
                        }}
                                        active={activeTab === defaultTabName}>
                            <span data-text={defaultTabName}>{defaultTabName}</span>

                            <WorkFilterCount>{countType('All')}</WorkFilterCount>
                        </WorkFilterItem>
                        {getUniqueArray()?.map((work, idx) => {
                            return (
                                <WorkFilterItem onClick={() => {
                                    handleClick(work)
                                }} active={activeTab === work} key={idx}>
                                    <span data-text={work}>{work}</span>
                                    <WorkFilterCount>
                                        <span data-text={countType(work)}>{countType(work)}</span>
                                    </WorkFilterCount>
                                </WorkFilterItem>
                            )
                        })}
                    </WorkFilterList>
                </FadeInAnimation>
            </Container>
        </WorkFilterWrapper>
    );
}

export default WorkFilter;

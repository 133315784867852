import Header from "./Components/Header/Header";
import Homepage from "./Pages/Homepage/Homepage";
import Footer from "./Components/Footer/Footer";
import {Routes, Route, useLocation} from "react-router-dom";
import Work from "./Pages/Work/Work";
import React, {useEffect, useRef, useState} from "react";
import About from "./Pages/About/About";
import Testimonials from "./Pages/Testimonials/Testimonials";
import Service from "./Pages/Service/Service";
import Blog from "./Pages/Blog/Blog";
import Culture from "./Pages/Culture/Culture";
import Contacts from "./Pages/Contacts/Contacts";
import Articles from "./Pages/Articles/Articles";
import Error from "./Pages/Error/Error";
import Privacy from "./Pages/Privacy/Privacy";
import {gsap} from "gsap";
import {useNavigate} from "react-router-dom";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import CursorC from "./Components/Cursor/Cursor";
import ScrollToTop from "./functions/scrollToTop";
import {PageWrapper} from "./Components/Page/styles";
import Scrollbar from 'smooth-scrollbar';
import VideoPopup from "./Components/VideoPopup/VideoPopup";
import Preloader from "./Components/Preloader/Preloader";
import Cookies from "./Components/Cookies/Cookies";
import {delayForGsap} from "./functions/constants";
import ScreenLoad from "./Components/ScreenLoad/ScreenLoad";


const BlogItemsData = [
    {
        image: require('./assets/img/blog/1.png'),
        name: 'How to stay motivated to create everyday.',
        category: 'Motivation. Creativity.',
        link: '/blog/How_to_stay_motivated_to_create_everyday'
    },
    {
        image: require('./assets/img/blog/2.png'),
        name: 'Be humble in life and kind to people around you.',
        category: 'Life lessons. Teach.',
        link: '/blog/Be_humble_in_life_and_kind_to_people_around_you'

    },
    {
        image: require('./assets/img/blog/3.png'),
        name: 'How to start with your very first design portfolio.',
        category: 'Technical. Design.',
        link: '/blog/How_to_start_with_your_very_first_design_portfolio'

    },
    {
        image: require('./assets/img/blog/4.png'),
        name: 'How to start with your very first design portfolio.',
        category: 'Technical. Design.',
        link: '/blog/How_to_start_with_your_very_first_design_portfolio_a'

    },
    {
        image: require('./assets/img/blog/4.png'),
        name: 'How to start with your very first design portfolio.',
        category: 'Technical. Design.',
        link: '/blog/How_to_start_with_your_very_first_design_portfolio_aa'

    },
    {
        image: require('./assets/img/blog/4.png'),
        name: 'How to start with your very first design portfolio.',
        category: 'Technical. Design.',
        link: '/blog/How_to_start_with_your_very_first_design_portfolio_aaa'

    },
]



const App = () => {

    const navigate = useNavigate();
    const scrollerContainer = useRef();
    const [loaded, setLoaded] = useState(false);
    const [closePreloader , setClosePreloader] = useState(false)
    const [closePreloaderAnim , setclosePreloaderAnim] = useState(false)
    const [video, setVideo] = useState(null);
    const [openPopup, setOpenPopup] = useState(false);
    const headerRef = useRef(null);
    const scroller = useRef();
    const {pathname} = useLocation();
    gsap.registerPlugin(ScrollTrigger)

    useEffect(() => {
        initHeaderGsap()

        
        return () => {
            ScrollTrigger.getAll('header').forEach(f => f.kill())
        }
        
        // eslint-disable-next-line
    },[pathname]);

    const initHeaderGsap = () =>{
        setTimeout(()=>{
            const showAnim = gsap.fromTo(headerRef.current, {
                yPercent: -100,
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                paused: true,
                yPercent: 0,
                duration: 0.5,
            }).progress(1);

            ScrollTrigger.create({
                start: 0,
                end: 99999,
                id: 'header',
                onUpdate: (self) => {
                    self.direction === -1 ? showAnim.play() : showAnim.reverse()
                },
            });
        },delayForGsap)

    }

    const allLinks = document.querySelectorAll('a[href]:not(.cookies--wrapp a, .menu__social a, .footer__social a, .footer__mail, .articles--more a');

    allLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            const linkLocation = e.target.closest('a').getAttribute('href');

            document.querySelector('.screenLoad').classList.remove('hidden');

            setTimeout(() => {
                document.querySelector('.screenLoad--open').classList.add('hidden');
            }, "0");
            
            setTimeout(() => {
                navigate(linkLocation, {replace: true});
                document.querySelector('.screenLoad').classList.add('opened');
            }, "1400");

            setTimeout(() => {
                document.querySelector('.screenLoad').classList.add('hidden');
                document.querySelector('.screenLoad--open').classList.remove('hidden');
                document.querySelector('.screenLoad').classList.remove('opened');
            }, "2800")
            
        })
    })

    useEffect(() => {

        // if (!isSafari) {
        var scrollbar

        if(window.screen.width > 769){
            scrollbar = Scrollbar.init(scroller.current);
        }else{
            scrollbar = Scrollbar.init(scroller.current, {
                damping: 0.1,
            });
        }
        

        ScrollTrigger.scrollerProxy(scroller.current, {
            scrollTop(value) {
                if (arguments.length) {
                    scrollbar.scrollTop = value;
                    
                }
                
                return scrollbar.scrollTop;
            }
        });

        scrollbar.addListener(ScrollTrigger.update)
        ScrollTrigger.refresh()
        ScrollTrigger.defaults({scroller: scroller.current})

        return () => ScrollTrigger.refresh()

        // }
        //eslint-disable-next-line
    }, []);
    

    const WorksItemsData = [
        {
            image: require('./assets/img/works/1.png'),
            name: 'Cultures Calendar',
            subname: 'App UI/UX.',
            link: '/works/1',
            category: 'Branding'
        },
        {
            image: require('./assets/img/works/2.png'),
            name: 'Herbybox',
            subname: 'Website design',
            link: '/works/2',
            category: 'Branding'
    
        },
        {
            image: require('./assets/img/works/3.png'),
            name: 'Shopexa',
            subname: 'Logo design. Brand Research',
            link: '/works/3',
            category: 'App'
    
        },
        {
            image: require('./assets/img/works/4.png'),
            name: 'BoostAPI',
            subname: 'Website design',
            link: '/works/4',
            category: 'Logo'
    
        },
        {
            image: require('./assets/img/works/5.png'),
            name: 'Brio',
            subname: 'Logo design, Branding',
            link: '/works/5',
            category: 'App'
    
        },
        {
            image: require('./assets/img/works/6.png'),
            name: 'Vgood.news',
            subname: 'App design. Branding',
            link: '/works/6',
            category: 'App'
    
        },
    ]


    const ArticlesItemsData = [
        {
            image: require('./assets/img/articles/1.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Motivation. Creativity.',
            link: '/articles/1',
            category: 'Branding'
        },
        {
            image: require('./assets/img/articles/2.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Website design',
            link: '/articles/2',
            category: 'Branding'
    
        },
        {
            image: require('./assets/img/articles/5.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Logo design, Branding',
            link: '/articles/5',
            category: 'App'
    
        },
        {
            image: require('./assets/img/articles/1.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'App design. Branding',
            link: '/articles/6',
            category: 'App'
    
        },
        {
            image: require('./assets/img/articles/3.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Logo design. Brand Research',
            link: '/articles/3',
            category: 'Logo'
    
        },
        {
            image: require('./assets/img/articles/4.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Website design',
            link: '/articles/4',
            category: 'Logo'
    
        },
        {
            image: require('./assets/img/articles/1.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Motivation. Creativity.',
            link: '/articles/1',
            category: 'Web Design'
        },
        {
            image: require('./assets/img/articles/2.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Website design',
            link: '/articles/2',
            category: 'Web Design'
    
        },
        {
            image: require('./assets/img/articles/1.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Motivation. Creativity.',
            link: '/articles/1',
            category: 'Website'
        },
        {
            image: require('./assets/img/articles/2.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Website design',
            link: '/articles/2',
            category: 'Website'
    
        },
        {
            image: require('./assets/img/articles/3.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Logo design. Brand Research',
            link: '/articles/3',
            category: 'Development'
    
        },
        {
            image: require('./assets/img/articles/4.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Website design',
            link: '/articles/4',
            category: 'Development'
    
        },
        {
            image: require('./assets/img/articles/5.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Logo design, Branding',
            link: '/articles/5',
            category: 'Graphics'
    
        },
        {
            image: require('./assets/img/articles/1.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'App design. Branding',
            link: '/articles/6',
            category: 'Graphics'
    
        },
        {
            image: require('./assets/img/articles/5.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'Logo design, Branding',
            link: '/articles/5',
            category: 'Illustration'
    
        },
        {
            image: require('./assets/img/articles/1.png'),
            name: 'How to stay motivated to create everyday.',
            subname: 'App design. Branding',
            link: '/articles/6',
            category: 'Illustration'
    
        },
    ]

    const CookiesPanelData = [
        {
            image: require('./assets/img/cookies/icon.png'),
            title: 'The Gods Value Your Privacy',
            text: 'We use cookies to ensure that we give you the best experience on our website. at dictum quam. Quisque rhoncus tincidunt lorem, accumsan lacinia ',
            linkUseCookies: 'Cookie Usage',
            linkAccept: 'I Accept'
        },
        {
            desc: 'When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preference or you device and is mostly used to make the site work as you expect it to. The information doesn’t usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.',
            title: 'Manage Cookies',
            items: [
                {
                    name: "Necessary / Essential",
                    text: "Fusce vulputate nibh nulla, eu blandit est ornare at. Quisque dignissim, elit sit amet consequat tempus, mauris mi sagittis nibh, ut dignissim mauris",
                    checked: true,
                    activeAlwais: true
                },
                {
                    name: "Preferences",
                    text: "Fusce vulputate nibh nulla, eu blandit est ornare at. Quisque dignissim, elit sit amet consequat tempus, mauris mi sagittis nibh, ut dignissim mauris",
                    checked: true,
                    activeAlwais: false
                },
                {
                    name: "Stats",
                    text: "Fusce vulputate nibh nulla, eu blandit est ornare at. Quisque dignissim, elit sit amet consequat tempus, mauris mi sagittis nibh, ut dignissim mauris",
                    checked: true,
                    activeAlwais: false
                },
                {
                    name: "Marketing",
                    text: "Fusce vulputate nibh nulla, eu blandit est ornare at. Quisque dignissim, elit sit amet consequat tempus, mauris mi sagittis nibh, ut dignissim mauris",
                    checked: false,
                    activeAlwais: false
                },

            ]
        }
    ]
    
    localStorage.setItem('cookiesConfirmed', false);

    return (
        <>
            {!loaded && pathname === '/'   && <Preloader closePreloaderAnim={closePreloaderAnim} setclosePreloaderAnim={setclosePreloaderAnim} closePreloader={closePreloader} setClosePreloader={setClosePreloader} setLoaded={setLoaded}/>}
            {localStorage.getItem('cookiesConfirmed') !== 'true' && <Cookies CookiesPanelData={CookiesPanelData}/>}
            <ScreenLoad/>
            <ScrollToTop/>
            <Header WorksItemsData={WorksItemsData} headerRef={headerRef}/>
            <PageWrapper ref={scroller} className='scroller'>
                <div ref={scrollerContainer} className={'scroller-container'}>
                    <Routes>
                        <Route path='*' element={<Error/>}/>
                        <Route path='/'
                               element={<Homepage
                                   closePreloaderAnim={closePreloaderAnim}
                                   setclosePreloaderAnim={setclosePreloaderAnim}
                                   closePreloader={closePreloader}
                                   setClosePreloader={setClosePreloader}
                                   setLoaded={setLoaded}
                                   loaded={loaded}
                                   WorksItemsData={WorksItemsData}
                                   setVideo={setVideo}
                                   setOpenPopup={setOpenPopup}
                                   BlogItemsData={BlogItemsData}/>}></Route>
                        <Route path='/works'
                               element={<Work WorksItemsData={WorksItemsData}/>}></Route>
                        <Route path='/blog/:slug'
                               element={<Blog BlogItemsData={BlogItemsData}/>}></Route>
                        <Route path='/about'
                               element={<About/>}></Route>
                        <Route path='/testimonials'
                               element={<Testimonials/>}></Route>
                        <Route path='/services'
                               element={<Service/>}></Route>
                        <Route path='/culture'
                               element={<Culture/>}></Route>
                        <Route path='/contacts'
                               element={<Contacts/>}></Route>
                        <Route path='/articles'
                               element={<Articles ArticlesItemsData={ArticlesItemsData}/>}></Route>
                        <Route path='/error'
                               element={<Error/>}></Route>
                        <Route path='/privacy'
                               element={<Privacy/>}></Route>
                    </Routes>
                </div>
                <Footer scrollerContainer={scrollerContainer}/>
            </PageWrapper>
            <VideoPopup video={video} openPopup={openPopup} setOpenPopup={setOpenPopup}/>
            <CursorC/>
        </>


    );
}

export default App;

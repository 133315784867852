import styled from "styled-components";
import {COLORS} from "../../styles/Theme";
import {device} from "../../styles/responsive";


export const SearshResultWrapp = styled.div`
  display: none;
  &.active{
    display: block;
  }
`;

export const SearshResult = styled.div`
    padding-bottom: 60px;
    @media ${device.tabletL} {
        padding-bottom: 40px;
    }
    > div{
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        letter-spacing: -0.02em;
        margin-bottom: 6px;
        color: ${COLORS.SECONDARYBLACK};
        @media ${device.tabletL} {
            font-size: 32px;
            line-height: 40px;
        }
    }

    p{
        font-weight: 400;
        font-size: 24px;
        line-height: 38px;
        letter-spacing: -0.02em;
        color: ${COLORS.BLACK3};
        @media ${device.tabletL} {
            font-size: 20px;
            line-height: 30px;
        }
    }
`;

export const SearshResultEmpty = styled.div`
    display: flex;
    column-gap: 60px;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 260px;
    justify-content: space-between;
    display: none;
    @media ${device.tabletL} {
        padding-top: 30px;
        padding-bottom: 180px;
    }

    @media ${device.tablet} {
        column-gap: 40px;
    }

    @media ${device.mobile} {
        flex-direction: column;
        row-gap: 30px;
        padding-top: 20px;
        padding-bottom: 100px;
    }

    &.active{
        display: flex;
    }

    p{
        font-weight: 400;
        font-size: 56px;
        line-height: 70px;
        letter-spacing: -0.02em;
        max-width: 706px;
        width: 100%;
        color: ${COLORS.BLACK};
        @media ${device.tabletL} {
            font-size: 42px;
            line-height: 50px;
        }

        @media ${device.tablet} {
            font-size: 32px;
            line-height: 40px;
        }
    }

    img{
        max-width: 290px;
        width: 100%; 
        height: auto;
        @media ${device.tabletL} {
            max-width: 200px;
        }
        @media ${device.tablet} {
            max-width: 120px;
        }
    }
`;

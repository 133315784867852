import React, {useEffect, useState} from 'react';
import {
    PreloaderBottom,
    PreloaderCenter,
    PreloaderLogo, PreloaderPercent, PreloaderPercentText,
    PreloaderTitle,
    PreloaderWrapper
} from "./styled";
import SplittedAnimation from "../../styles/Animations/SplittedAnimation";
import Container from "../Container/Container";
import useWindowSize from "../../hooks/useWindowSize";

function Preloader({setLoaded, setClosePreloader, closePreloader, setclosePreloaderAnim, closePreloaderAnim}) {
    const [percent, setPercent] = useState(0);
    const size = useWindowSize();

    useEffect(() => {
        if(size.width <= 1600) {
            setLoaded(true);
            setClosePreloader(true);
            setclosePreloaderAnim(true);
        }
        percent < 100 && setTimeout(() =>
            setPercent(percent + 1), 16);
        if (percent === 100) {
            setClosePreloader(true);
            setTimeout(() => {
                setclosePreloaderAnim(true)
                setTimeout(()=>{
                    setLoaded(true)
                },500)
            }, 500)
        }

        //eslint-disable-next-line
    }, [percent])

    return (
        <PreloaderWrapper closed={closePreloader} closePreloaderAnim={closePreloaderAnim} className="Preloader--screen">
            <PreloaderCenter>
                <PreloaderLogo>
                    <svg width="120" height="28" viewBox="0 0 120 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.2293 0.00537109V13.6742L20.4219 6.86684V0.00537109H27.2293Z" fill="#A3CA34"/>
                        <path
                            d="M27.2296 22.2875V22.3064L22.439 27.1106L20.6195 25.2911L20.433 25.4128C18.3645 26.6105 16.0158 27.2392 13.6256 27.2349H13.5905C9.98337 27.2299 6.52581 25.7931 3.97749 23.2402C1.42918 20.6873 -0.0014355 17.2272 1.08088e-06 13.6201V4.68237L1.9357 6.61807L6.80199 11.4844V13.6147C6.80124 15.412 7.51153 17.1365 8.77781 18.4119C10.0441 19.6873 11.7635 20.41 13.5607 20.4221H13.5959C15.4011 20.4205 17.1323 19.7041 18.4108 18.4296C19.2817 17.5581 19.9002 16.4669 20.2005 15.272L25.3939 20.4518L27.2296 22.2875Z"
                            fill="#4A2B93"/>
                        <path
                            d="M89.9479 0.00537109V27.235H85.2709L85.1465 27.1106L83.3352 25.2993L83.1405 25.1046L78.2013 20.1653L69.5258 11.4898V27.235H62.7184V0.00537109H67.6684L69.5041 1.84104L69.5258 1.86267L76.3007 8.63762L82.9378 15.2747L83.1405 15.4775V0.00537109H89.9479Z"
                            fill="#4A2B93"/>
                        <path
                            d="M58.5874 13.6202C58.5884 15.4082 58.2371 17.1789 57.5534 18.831C56.8698 20.4831 55.8672 21.9843 54.603 23.2488C53.3389 24.5132 51.8379 25.5161 50.1859 26.2C48.5339 26.884 46.7633 27.2357 44.9753 27.235H44.9401C42.2487 27.2297 39.6192 26.4268 37.3839 24.9277C35.1486 23.4286 33.4078 21.3006 32.3813 18.8126C31.3549 16.3246 31.0889 13.5882 31.6169 10.949C32.145 8.3099 33.4434 5.88649 35.3482 3.98497C36.19 3.14448 37.1379 2.41741 38.1679 1.82218C40.2217 0.631871 42.5528 0.00341318 44.9266 2.17303e-05H44.9618C47.352 -0.0042605 49.7007 0.624429 51.7692 1.82218L46.6055 7.01018C46.0748 6.87903 45.5301 6.81276 44.9834 6.81282H44.9483C43.1428 6.81282 41.4113 7.53003 40.1347 8.80666C38.8581 10.0833 38.1409 11.8148 38.1409 13.6202C38.1409 15.4257 38.8581 17.1571 40.1347 18.4338C41.4113 19.7104 43.1428 20.4276 44.9483 20.4276H44.9834C45.8776 20.4284 46.7631 20.2528 47.5893 19.9109C48.4155 19.569 49.1662 19.0675 49.7983 18.4351C50.0533 18.1842 50.2875 17.913 50.4985 17.6241H43.7479V10.8032H58.2981C58.4921 11.7296 58.5891 12.6737 58.5874 13.6202Z"
                            fill="#4A2B93"/>
                        <path
                            d="M118.181 21.317L118.07 20.9493L116.091 14.5123L113.406 5.78274L111.971 1.1084L111.63 0.00537109H101.592L101.257 1.08677L99.7914 5.85303L97.1312 14.5042L95.1441 20.971L95.0387 21.3143L93.2219 27.2241H100.343L100.748 25.9048L102.162 21.3089H111.06L112.471 25.9048L112.879 27.235H120L118.181 21.317ZM106.612 6.84521L108.967 14.5096H104.255"
                            fill="#4A2B93"/>
                    </svg>

                </PreloaderLogo>
                <Container>
                    <PreloaderTitle>
                        <SplittedAnimation title={'Where ideas are seeded, grown and shown.'}/>

                    </PreloaderTitle>
                    <PreloaderBottom>
                        <PreloaderPercentText>Loading</PreloaderPercentText>
                        <PreloaderPercent>{percent}%</PreloaderPercent>
                    </PreloaderBottom>
                </Container>

            </PreloaderCenter>
        </PreloaderWrapper>
    );
}

export default Preloader;

import React, {useEffect, useState} from 'react'
import {ClientsLogosInner, ClientsLogosTitle, ClientsLogosWrapper} from "./styled";
import Container from "../Container/Container";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import ClientsLogosImg from "../../Components/ClientsLogosImg/ClientsLogosImg";
import axios from "axios";
import { token, version } from '../../global/config';

    

const ClientLogos = () => {
    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/about?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <ClientsLogosWrapper>
            <Container>
                <ClientsLogosInner>
                    <FadeInAnimation>
                        <ClientsLogosTitle>
                            {dataAxios?.length ? dataAxios?.[4].titleThin : 'Our prestigious'}
                            <b> {dataAxios?.length ? dataAxios?.[4].titleBold : 'clients'} </b>.
                        </ClientsLogosTitle>
                    </FadeInAnimation>
                </ClientsLogosInner>
                
            </Container>
            <ClientsLogosImg/>
        </ClientsLogosWrapper>
    );
}

export default ClientLogos;

import React from 'react';
import styled from 'styled-components'
import TestimonialsFilter from "../TestimonialsFilter/TestimonialsFilter";
import TestimonialsItem from "../TestimonialsItem/TestimonialsItem";

const TestimonialsBlock = ({testimonialsData}) => {
    return (
        <>
            <TestimonialsFilter testimonialsData={testimonialsData}/>
            <TestimonialsList>
                <TestimonialsItem testimonialsData={testimonialsData}/>
            </TestimonialsList>
        </>
    );
}


const TestimonialsList = styled.div``;


export default TestimonialsBlock;

import React, {useEffect, useRef, useState} from 'react';
import {
    ValueBlockImage,
    ValueBlockInner, ValueBlockItem, ValueBlockLeft,
    ValueBlockList, ValueBlockRight,
    ValueBlockText,
    ValueBlockTitle,
    ValueBlockWrapper
} from "./styled";
import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import gsap from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Container from "../Container/Container";
import CulturePhoto from '../../assets/img/CulturePhoto.png';
import axios from "axios";
import { token, version } from '../../global/config';


function ValueBlock(props) {

    const parralaxRef = useRef(null);
    const parralaxParentRef = useRef(null);

    useEffect(() => {

        ScrollTrigger.matchMedia({
            // large
            "(min-width: 1280px)": function () {
                if (parralaxRef.current && parralaxParentRef.current) {

                    gsap.fromTo(parralaxRef.current, {
                        y: () => parralaxRef.current?.offsetHeight - parralaxParentRef.current?.offsetHeight,
                    }, {
                        scrollTrigger: {
                            trigger: parralaxParentRef.current,
                            scrub: true,
                            start: '-30%',
                            invalidateOnRefresh: true,
                        },
                        y: 0,
                        ease: 'none',

                    })


                }
            },
        });
    }, [parralaxRef])

    const valueRightData = [
        {
            item: 'Flexible working hours',
        },
        {
            item: 'Exciting projects',
        },
        {
            item: 'Challenge'
        },
        {
            item: 'Quirky fun',
        },
        {
            item: 'Right exposure',
        },
        {
            item: 'Social environment',
        },
        {
            item: 'Competition',
        },
        {
            item: 'Career',
        },

    ];

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/culture?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <ValueBlockWrapper>
                <ValueBlockInner>
                    <Container>
                    <ValueBlockTitle>
                        {dataAxios?.length ? dataAxios?.[6].titleFirst : 'We pay attention to the'}
                         
                        <b> {dataAxios?.length ? dataAxios?.[6].titleColor: 'value'} </b> 

                        {dataAxios?.length ? dataAxios?.[6].titleSecond: 'we provide.'}
                        
                    </ValueBlockTitle>
                    <ValueBlockText>
                        {dataAxios?.length ? dataAxios?.[6].text1 : 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim.'}   
                        <br/><br/>
                        {dataAxios?.length ? dataAxios?.[6].text2 : 'Striving for excellence is out-dated. Ut porta libero egestas dignissim lobortis. Praesent et nisl urna. Praesent neque massa, maximus id laoreet ut, lacinia vitae massa. Nullam ullamcorper elit dignissim.'}
                    </ValueBlockText>
                    </Container>
                    <FadeInAnimation delay={0.3}>
                        <ValueBlockImage ref={parralaxParentRef}>
                            <img ref={parralaxRef} src={dataAxios?.length ? dataAxios?.[6].image : CulturePhoto} alt="Culture"/>
                        </ValueBlockImage>
                    </FadeInAnimation>
                    <Container>
                        <ValueBlockList>
                            <ValueBlockLeft>
                                {dataAxios?.length ? dataAxios?.[6].textTitleLeft : 'What’s it like to work with us.'}
                            </ValueBlockLeft>
                            <ValueBlockRight>
                                {dataAxios?.length ? dataAxios?.[6].values.map((val, key) => {
                                        return <ValueBlockItem key={key}>{val.item}</ValueBlockItem>
                                    }) : 
                                    valueRightData?.map((val, key) => {
                                        return <ValueBlockItem key={key}>{val.item}</ValueBlockItem>
                                    })
                                }
                            </ValueBlockRight>
                        </ValueBlockList>
                    </Container>
                </ValueBlockInner>
        </ValueBlockWrapper>
    );
}

export default ValueBlock;

import styled from "styled-components";
import {device} from "../../styles/responsive";

export const TestimonialsIntroWrapper = styled.div`
  padding-top: 303px;
  padding-bottom: 60px;
  @media ${device.tabletL}{
    padding-top: 290px;
    padding-bottom: 120px;
  }
  @media ${device.mobile} {
    padding-top: 206px;
    padding-bottom: 60px;
  }
`;

export const TestimonialsIntroTitle = styled.div``;

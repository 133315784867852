import {useEffect, useState} from "react";
import Intro from "../../Components/Intro/Intro";
import VideoBanner from "../../Components/videoBanner/videoBanner";
import BlogBanner from '../../assets/img/blogBanner.png'
import BlogContent from "../../Components/BlogContent/BlogContent";
import axios from "axios";
import { useParams } from "react-router-dom";
import { token, version } from "../../global/config";


function Blog({BlogItemsData}) {
    const [dataAxios , setDataAxios] = useState([]);
    const { slug } = useParams();

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v1/cdn/stories/posts/${slug}?version=${version}&token=${token}&cv=1671028857`).then(res =>{
            const data = res.data.story.content;
            setDataAxios(data)
        })

        //eslint-disable-next-line
    }, [slug])

    const defaultTabName = dataAxios?.test && dataAxios.test[2].activeLink;

    const [activeTab, setActiveTab] = useState(defaultTabName);

    useEffect(()=>{
        setActiveTab(defaultTabName);
    },[dataAxios, defaultTabName])


    return (
        <>
            <Intro title={dataAxios?.test ? dataAxios.test[0].title : 'How you can learn UX designer’s skill sets.'} text={dataAxios?.test ? dataAxios.test[0].label : 'Blog'}></Intro>
            <VideoBanner VideoBannerImage={dataAxios?.test ? dataAxios.test[1].image : BlogBanner}  />
            <BlogContent BlogItemsData={BlogItemsData} PostData={dataAxios?.test && dataAxios} activeTab={activeTab} setActiveTab={setActiveTab} defaultTabName={defaultTabName}/>
        </>
    );
}

export default Blog;

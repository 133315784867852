import React, {useLayoutEffect, useRef} from 'react';

import {
    ClientsButton, ClientsButtonMob, ClientsImage, ClientsItem, ClientsName, ClientsTestimonials, ClientsText, ClientsItemLink,
    ClientsTitle,
    ClientsWrapper
} from "./styles";

import FadeInAnimation from "../../styles/Animations/FadeInAnimation";
import Button from "../Button/Button";
import Container from "../Container/Container";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {delayForGsap} from "../../functions/constants";

const clientsData = [
    {
        id: 1,
        image: require('../../assets/img/clients/1.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.  help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 2,
        image: require('../../assets/img/clients/2.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 3,
        image: require('../../assets/img/clients/3.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.  help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 4,
        image: require('../../assets/img/clients/4.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 5,
        image: require('../../assets/img/clients/5.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.  help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 6,
        image: require('../../assets/img/clients/6.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 7,
        image: require('../../assets/img/clients/7.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.  help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 8,
        image: require('../../assets/img/clients/6.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.',
    },
    {
        id: 9,
        image: require('../../assets/img/clients/7.png'),
        name: 'Emily Doe',
        text: 'We help translate your ideas into unique digital designs that creates.  help translate your ideas into unique digital designs that creates.',
    },

]

const slicedData = clientsData.slice(0, 7);

gsap.registerPlugin(ScrollTrigger);


function Clients({clientsData}) {
    const clientsWrapperRef = useRef();
    const clientsTestimonialsRef = useRef(null);
    const clientsItemRef = useRef([]);
    
    useLayoutEffect(() => {

        initGsapAnimation()
        return () => {
                ScrollTrigger.getAll('pinClients').forEach(f => f.kill())
        }

    }, [])

    const initGsapAnimation = () => {
        setTimeout(() => {


            ScrollTrigger.matchMedia({
                // large
                "(min-width: 1201px)": function () {
                    const clientsMove = gsap.timeline({paused: true})
                        .to(clientsTestimonialsRef.current, 2, {
                            yPercent: -130,
                        })
                        .fromTo(clientsItemRef.current, {
                            y: 300,


                        }, {
                            y: 0,
                            delay: 0.3,
                            stagger: 0.15
                        }, '=-2.2')


                    ScrollTrigger.create({
                        trigger: clientsWrapperRef.current,
                        start: 'top 25%',
                        end: 'bottom 50%',
                        pin: true,
                        pinSpacing: false,
                        animation: clientsMove,
                        id: 'pinClients',
                        scrub: true,
                    });


                },
                "(min-width: 768px) and (max-width: 1200px)": function () {
                    const clientsMove = gsap.timeline({paused: true})
                        .to(clientsTestimonialsRef.current, 2, {
                            yPercent: -130,
                        })
                        .fromTo(clientsItemRef.current, {
                            y: 300,


                        }, {
                            y: 0,
                            delay: 0.3,
                            stagger: 0.15
                        }, '=-2.2')


                    ScrollTrigger.create({
                        trigger: clientsWrapperRef.current,
                        start: 'top 20%',
                        end: 'bottom 60%',
                        pin: true,
                        pinSpacing: false,
                        id: 'pinClients',
                        animation: clientsMove,
                        scrub: true,
                    });


                },
                "(max-width: 767px)": function () {
                    new gsap.timeline()
                        .set(clientsItemRef.current, {
                            y: 0,
                        })
                        .set(clientsTestimonialsRef.current, {
                            yPercent: 0,
                        })

                },

            });

            window.addEventListener('resize', function () {
                ScrollTrigger.refresh()
            })
        }, delayForGsap)
    }

    return (
        <>
            <ClientsWrapper ref={clientsWrapperRef}>
                <FadeInAnimation>
                    <ClientsTitle>{clientsData.titleThinFirst} <b> {clientsData.titleBold} </b> {clientsData.titleThinSecond}</ClientsTitle>
                    <ClientsButton>
                        <Button type={'link'} to={'/testimonials'}>{clientsData.button}</Button>
                    </ClientsButton>
                    <Container>
                        <ClientsTestimonials ref={clientsTestimonialsRef}>

                            {clientsData.clientsItems?.content ? clientsData.clientsItems.content.slice(0, 7).map((client, idx) => {
                                    const url = '/testimonials?testimonial' + (idx + 1);
                                    return (
                                        
                                            <ClientsItem
                                                className={'image-view'}
                                                ref={el => (clientsItemRef.current = [...clientsItemRef.current, el])}
                                                key={idx}>
                                                <ClientsItemLink href={url}>
                                                        {client.attrs.body[0].image &&
                                                            <ClientsImage><img src={client.attrs.body[0].image} alt=""/></ClientsImage>}
                                                        {client.attrs.body[0].name && <ClientsName>{client.attrs.body[0].name}</ClientsName>}
                                                        {client.attrs.body[0].text && <ClientsText>{client.attrs.body[0].text}</ClientsText>}
                                                </ClientsItemLink>
                                            </ClientsItem>
                                        
                                    )
                                })
                                
                                :
                                slicedData?.map((client, idx) => {
                                    return (
                                        <ClientsItem
                                            className={'image-view'}
                                            ref={el => (clientsItemRef.current = [...clientsItemRef.current, el])}
                                            key={idx}>
                                            {client.image &&
                                                <ClientsImage><img src={client.image} alt=""/></ClientsImage>}
                                            {client.name && <ClientsName>{client.name}</ClientsName>}
                                            {client.text && <ClientsText>{client.text}</ClientsText>}
                                        </ClientsItem>
                                    )
                                })
                            }
                        </ClientsTestimonials>
                        <ClientsButtonMob>
                            <Button type={'link'} to={'/testimonials'}>{clientsData.button}</Button>
                        </ClientsButtonMob>

                    </Container>
                </FadeInAnimation>
            </ClientsWrapper>
        </>

    );
}

export default Clients;

import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import Intro from "../../Components/Intro/Intro";
import VideoBanner from "../../Components/videoBanner/videoBanner";
import VideoBannerImage from '../../assets/img/cultureBanner.png'
import WorkingBlock from "../../Components/WorkingBlock/WorkingBlock";
import Striving from "../../Components/Striving/Striving";
import Marquee from "../../Components/Marquee/Marquee";
import ValuesSlider from "../../Components/ValuesSlider/ValuesSlider";
import ValueBlock from "../../Components/ValueBlock/ValueBlock";
import MarqueeImages from "../../Components/MarqueeImages/MarqueeImages";
import Container from "../../Components/Container/Container";
import Form from "../../Components/Form/Form";
import Contacts1 from "../../assets/img/contacts/1.png";
import Contacts2 from "../../assets/img/contacts/2.png";
import Contacts3 from "../../assets/img/contacts/3.png";
import {ContactsInner, ContactsLeft, ContactsRight, ContactsWrapper} from "../Contacts/Contacts";
import axios from "axios";
import { token, version } from '../../global/config';


function Culture(props) {
    

    const arrayMarque= [
        {
            text: 'Communication',
            img: require('../../assets/img/maruqee/1.png')
        },
        {
            text: 'Value',
            img: require('../../assets/img/maruqee/2.png')

        },
        {
            text: 'Eco-friendly',
            img: require('../../assets/img/maruqee/3.png')

        },
        {
            text: 'Teamwork',
            img: require('../../assets/img/maruqee/4.png')

        },
        {
            text: 'Responsibility',
            img: require('../../assets/img/maruqee/5.png')

        },
        {
            text: 'Passion',
            img: require('../../assets/img/maruqee/6.png')

        },
        {
            text: 'Fun',
            img: require('../../assets/img/maruqee/7.png')

        }
    ];

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/culture?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    return (
        <>
            <IntroCulture>
                <Intro text={dataAxios?.length ? dataAxios?.[0].label : 'Culture'} title={dataAxios?.length ? dataAxios?.[0].title : 'Culture. Values. \n Perfection'}/>
            </IntroCulture>
            <VideoBanner VideoBannerImage={dataAxios?.length ? dataAxios?.[1].image : VideoBannerImage} hiddenCursor={true}/>
            <WorkingBlock/>
            <Striving/>
            <Marquee word={dataAxios?.length ? dataAxios?.[4].word : 'Values'} speed={3.5}/>
            <ValuesSlider/>
            <ValueBlock/>
            <MarqueeImages speed={100} arrayMarque={dataAxios?.length ? dataAxios?.[7].items : arrayMarque} cssUnique={dataAxios?.length ? dataAxios?.[7].cssUnique : 'culture-page'}/>
            <ContactsWrapper>
                <Container>
                    <ContactsInner>
                        <ContactsLeft>
                            <Form/>
                        </ContactsLeft>
                        <ContactsRight>
                            <img src={dataAxios?.length ? dataAxios?.[8].image1 : Contacts1} alt="1"/>
                            <img src={dataAxios?.length ? dataAxios?.[8].image2 : Contacts2} alt="2"/>
                            <img src={dataAxios?.length ? dataAxios?.[8].image3 : Contacts3} alt="3"/>
                        </ContactsRight>
                    </ContactsInner>
                </Container>
            </ContactsWrapper>

        </>
    );
}

const IntroCulture = styled.div`
    h1{
        max-width: 700px;
        width: 100%
    }

`;

export default Culture;

import React, {useEffect, useRef, useState} from 'react';
import {
    ValuesSliderInner,
    ValuesSliderItem,
    ValuesSliderLeft, ValuesSliderName, ValuesSliderRight, ValuesSliderText,
    ValuesSliderViewport,
    ValuesSliderWrapper
} from "./styled";

import {ContainerMiddle} from "../ContainerMiddle/styles";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import axios from "axios";
import { token, version } from '../../global/config';
import {delayForGsap} from "../../functions/constants";

function ValuesSlider(props) {
    const valuesData = [
        {
            image: require('../../assets/img/values1.png'),
            name: 'Teamwork',
            text: 'We want to provide our clients with the best solutions, a professional brand and digital product designs at an affordable price.'
        },
        {
            image: require('../../assets/img/values2.png'),
            name: 'Eco-Friendly',
            text: 'We want to provide our clients with the best solutions, a professional brand and digital product designs at an affordable price.'
        },
        {
            image: require('../../assets/img/values3.png'),
            name: 'Communication',
            text: 'We want to provide our clients with the best solutions, a professional brand and digital product designs at an affordable price.'
        },
        {
            image: require('../../assets/img/values4.png'),
            name: 'Value',
            text: 'We want to provide our clients with the best solutions, a professional brand and digital product designs at an affordable price.'
        },
        {
            image: require('../../assets/img/values5.png'),
            name: 'Responsibility',
            text: 'We want to provide our clients with the best solutions, a professional brand and digital product designs at an affordable price.'
        },
        {
            image: require('../../assets/img/values6.png'),
            name: 'Passion',
            text: 'We want to provide our clients with the best solutions, a professional brand and digital product designs at an affordable price.'
        },
        {
            image: require('../../assets/img/values7.png'),
            name: 'Fun',
            text: 'We want to provide our clients with the best solutions, a professional brand and digital product designs at an affordable price.'
        },
    ]

    const [dataAxios , setDataAxios] = useState([]);

    useEffect(()=>{
        axios.get(`https://api.storyblok.com/v2/cdn/stories/culture?version=${version}&token=${token}`).then(res =>{
            const data = res.data.story.content.HomePage;
            setDataAxios(data)
        })
    },[])

    const PrinciplesViewportRef = useRef();
    const refSlide = useRef();

    useEffect(()=>{

        setTimeout(()=>{
            initGsap()
            ScrollTrigger.refresh();

            return () => {
                ScrollTrigger.clearMatchMedia()
                ScrollTrigger.getAll('pinClients1').forEach(f => f.kill())
            }
        },delayForGsap)

        // eslint-disable-next-line
    },[dataAxios])

    const initGsap = () =>{
        ScrollTrigger.matchMedia({
            // large
            "(max-width: 767px)": function () {
                if (PrinciplesViewportRef.current !== null) {
                    const PrinciplesLength = PrinciplesViewportRef.current.children.length;
                    const PrinciplesAllWidth = refSlide.current.offsetWidth * PrinciplesLength;

                    gsap.to(PrinciplesViewportRef.current, {
                        scrollTrigger: {
                            pin: true,
                            start: 'center center',
                            pinSpacing: true,
                            scrub: 0.3,
                            trigger: PrinciplesViewportRef.current,
                            end: function () {
                                if (PrinciplesViewportRef.current != null) {
                                    return "+=" + (PrinciplesViewportRef.current.offsetWidth * PrinciplesLength);
                                }
                            },
                        },
                        x: function () {
                            return -PrinciplesAllWidth + document.documentElement.clientWidth - 30 +  "px";
                        },

                    })
                }
            },
            "(min-width: 768px) and (max-width: 1024px)": function () {
                if (PrinciplesViewportRef.current !== null) {
                    const PrinciplesLength = PrinciplesViewportRef.current.children.length;
                    const PrinciplesAllWidth = refSlide.current.offsetWidth * PrinciplesLength;
                    gsap.to(PrinciplesViewportRef.current, {
                        scrollTrigger: {
                            pin: true,
                            start: 'center center',
                            pinSpacing: true,
                            scrub: 0.3,
                            trigger: PrinciplesViewportRef.current,
                            end: function () {
                                if (PrinciplesViewportRef.current != null) {
                                    return "+=" + (PrinciplesViewportRef.current.offsetWidth * PrinciplesLength);
                                }
                            },
                        },
                        x: function () {
                            return -PrinciplesAllWidth + document.documentElement.clientWidth - 20 +  "px";
                        },

                    })
                }
            },
            "(min-width: 1025px)": function () {
                if (PrinciplesViewportRef.current !== null) {
                    const PrinciplesLength = PrinciplesViewportRef.current.children.length;
                    const PrinciplesAllWidth = refSlide.current.offsetWidth * PrinciplesLength;
                    gsap.to(PrinciplesViewportRef.current, {
                        scrollTrigger: {
                            pin: true,
                            start: 'center center',
                            pinSpacing: true,
                            scrub: 0.3,
                            trigger: PrinciplesViewportRef.current,
                            end: function () {
                                if (PrinciplesViewportRef.current != null) {
                                    return "+=" + (PrinciplesViewportRef.current.offsetWidth * PrinciplesLength);
                                }
                            },
                        },
                        x: function () {
                            return -PrinciplesAllWidth + document.documentElement.clientWidth - 80 +  "px";
                        },

                    })
                }
            },

        });

    }

    

    return (
        <ValuesSliderWrapper>
            <ContainerMiddle>
                <ValuesSliderInner>
                    <ValuesSliderViewport ref={PrinciplesViewportRef}>

                        {dataAxios?.length ? dataAxios?.[5].items.map((value, idx) => {
                                return (
                                    <ValuesSliderItem key={idx} ref={refSlide}>
                                        <ValuesSliderLeft>
                                            {value.image && <img src={value.image} alt="values"/>}
                                        </ValuesSliderLeft>
                                        <ValuesSliderRight>
                                            {value.name && <ValuesSliderName>{value.name}</ValuesSliderName>}
                                            {value.text && <ValuesSliderText>{value.text}</ValuesSliderText>}
                                        </ValuesSliderRight>
                                    </ValuesSliderItem>
                                )
                            }) : 
                            valuesData?.map((value, idx) => {
                                return (
                                    <ValuesSliderItem key={idx} ref={refSlide}>
                                        <ValuesSliderLeft>
                                            {value.image && <img src={value.image} alt="values"/>}
                                        </ValuesSliderLeft>
                                        <ValuesSliderRight>
                                            {value.name && <ValuesSliderName>{value.name}</ValuesSliderName>}
                                            {value.text && <ValuesSliderText>{value.text}</ValuesSliderText>}
                                        </ValuesSliderRight>
                                    </ValuesSliderItem>
                                )
                            })
                        }
                    </ValuesSliderViewport>
                </ValuesSliderInner>
            </ContainerMiddle>
        </ValuesSliderWrapper>
    );
}

export default ValuesSlider;

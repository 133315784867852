const size = {
    desktop: 1920,
    laptop: 1681,
    laptopNormal: 1600,
    laptopSmall: 1279,
    tabletM: 1200,
    tabletL: 1024,
    tablet: 767,
    mobile: 600,
};

export const device = {
    mobile: `(max-width: ${size.mobile}px)`,
    tablet: `(max-width: ${size.tablet}px)`,
    laptopSmall: `(max-width: ${size.laptopSmall}px)`,
    laptopNormal: `(max-width: ${size.laptopNormal}px)`,
    tabletL: `(max-width: ${size.tabletL}px)`,
    tabletM: `(max-width: ${size.tabletM}px)`,
    laptopL: `(max-width: ${size.laptop}px)`,
    hd: `(min-width: ${size.laptop}px)`
};

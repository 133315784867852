import styled from "styled-components";
import Typography from "../../styles/Typography";
import {COLORS} from "../../styles/Theme";
import sharedProps from "../../styles/sharedProps";
import {device} from "../../styles/responsive";

export const ValueBlockWrapper = styled.div`
padding-bottom: 260px;
  @media ${device.tabletL}{
    padding-top: 150px;
  }
  @media ${device.mobile}{
    padding-bottom: 120px;
    padding-top: 90px;
  }
`;

export const ValueBlockInner = styled.div``;

export const ValueBlockTitle = styled(Typography.H2)`
  margin-bottom: 40px;
  width: 75%;
  b{
    font-weight: 400;
    color: ${COLORS.ORANGE};
    ${sharedProps};
  }
`;

export const ValueBlockText = styled(Typography.H6)`
  margin-bottom: 250px;
  @media ${device.tabletL}{
    margin-bottom: 100px;
  }
  @media ${device.mobile} {
    margin-bottom: 140px;
  }
`;

export const ValueBlockImage = styled.div`
  width: 100%;
  margin-bottom: 218px;
  height: 110vh;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  @media ${device.laptopL} {
    height: 90vh;
  }
  @media ${device.tabletL} {
    margin-bottom: 100px;
    height: 470px;
  }
  @media ${device.mobile} {
    margin-bottom: 75px;
    height: 314px;
  }
  
    img{
    width: 100%;
    object-fit: cover;
    height: 120vh;
    @media ${device.laptopL} {
      height: 105vh;
    }
    @media ${device.tabletL} {
      height: 520px;
    }
    @media ${device.mobile} {
      height: 340px;
    }
  }
`;

export const ValueBlockList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile}{
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const ValueBlockLeft = styled(Typography.H2)`
  width: 40%;
  max-width: 448px;
  @media ${device.mobile} {
    width: 100%;
    margin-right: auto;
  }
`;

export const ValueBlockRight = styled.div`
  width: 55%;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 10px 130px;
  @media ${device.tabletL}{
    grid-template-columns: 1fr;
    margin-left: 60px;
    width: 45%;
  }
  @media ${device.mobile} {
    width: 100%;
    margin-top: 40px;
    margin-left: 0;
    padding-left: 60px;
  }
`;

export const ValueBlockItem = styled(Typography.H6)`
  position: relative;
  &:before{
    position: absolute;
    content: '';
    width: 8px;
    top: 15px;
    left: -20px;
    height: 8px;
    border-radius: 50%;
    margin-right: 20px;
    ${sharedProps};
    background-color: ${COLORS.GREEN};
    @media ${device.mobile} {
      left: -35px;
    }
    }
  @media ${device.tabletL} {
    font-size: 24px;
    line-height: 32px;
    margin-right: 0;
  }
`;
